import {
    A_KEY_CODE as SOURCE_A_KEY_CODE,
    a_KEY_CODE as sourceA_KEY_CODE,
    Z_KEY_CODE as SOURCE_Z_KEY_CODE,
    z_KEY_CODE as sourceZ_KEY_CODE
} from 'SourceComponent/FieldSelect/FieldSelect.config';

// TODO: implement A_KEY_CODE
export const A_KEY_CODE = SOURCE_A_KEY_CODE;

// TODO: implement z_KEY_CODE
export const z_KEY_CODE = sourceZ_KEY_CODE;

// TODO: implement Z_KEY_CODE
export const Z_KEY_CODE = SOURCE_Z_KEY_CODE;

// TODO: implement a_KEY_CODE
export const a_KEY_CODE = sourceA_KEY_CODE;
