import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import FacebookConversionApi from '../component/FacebookConversionApi';

export const addFacebookConversionApiComponent = (member) => {
    const MAX_NUMBER_FILTERS = 1000;
    const MAX_NUMBER_POSITION = 10;
    const maxPosition = Math.max(
        member.map((route) => route.position).filter((num) => num <= MAX_NUMBER_FILTERS)
    );
    const key = Math.round(Math.random() * MAX_NUMBER_FILTERS);
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <FacebookConversionApi key={ key } />,
            position: maxPosition + MAX_NUMBER_POSITION
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addFacebookConversionApiComponent
        }
    }
};
