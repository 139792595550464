import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';

import { b2bCompanySingleUserType, CompanyType } from '../../type/B2bCompany';
import CompanyEditPopup from './CompanyEditPopup.component';
import { COMPANY_EDIT_POPUP } from './CompanyEditPopup.config';

/** @namespace Screenpages/b2bcompany/Component/CompanyEditPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    payload: _state.PopupReducer.popupPayload[COMPANY_EDIT_POPUP] || {}
});

/** @namespace Screenpages/b2bcompany/Component/CompanyEditPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    hideActivePopup: () => _dispatch(hideActivePopup()),
    showNotification: (type, message) => _dispatch(showNotification(type, message))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyEditPopup/Container/CompanyEditPopupContainer */
export class CompanyEditPopupContainer extends PureComponent {
    static propTypes = {
        hideActivePopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        CompanyData: CompanyType,
        reloadCompanyProfile: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            type: PropTypes.string,
            userData: b2bCompanySingleUserType
        })
    };

    static defaultProps = {
        CompanyData: false,
        payload: {
            type: 'unknown',
            userData: {}
        }
    };

    containerFunctions = {
        handleCloseAndReload: this._handleCloseAndReload.bind(this),
        getUserData: this._getUserData.bind(this),
        getRoleData: this._getRoleData.bind(this),
        handleUnknownType: this._handleUnkownType.bind(this)
    };

    _getUserData() {
        const { payload: { userData } = {} } = this.props;
        return userData;
    }

    _getRoleData() {
        const { payload: { roleData } = {} } = this.props;
        return roleData;
    }

    _handleCloseAndReload() {
        const { hideActivePopup, reloadCompanyProfile } = this.props;
        reloadCompanyProfile();
        hideActivePopup();
    }

    _handleUnkownType() {
        const { hideActivePopup, reloadCompanyProfile, showNotification } = this.props;
        reloadCompanyProfile();
        hideActivePopup();
        showNotification('error', __('A Unknown error has occured'));
    }

    containerProps = () => {
        const { CompanyData, payload: { type: EditType } = {} } = this.props;
        return {
            CompanyData,
            EditType
        };
    };

    render() {
        return (
            <CompanyEditPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditPopupContainer);
