import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { QuoteRestrictionsType, singleQuoteType } from '../../type/B2bQuote';
import QuoteViewActions from './QuoteViewActions.component';

/** @namespace Screenpages/b2bquote/Component/QuoteViewActions/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({ });

/** @namespace Screenpages/b2bquote/Component/QuoteViewActions/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    hideActivePopup: () => _dispatch(hideActiveOverlay()),
    showNotification: (type, message) => _dispatch(showNotification(type, message))
});

/** @namespace Screenpages/b2bquote/Component/QuoteViewActions/Container/QuoteViewActionsContainer */
export class QuoteViewActionsContainer extends PureComponent {
    static propTypes = {
        hideActivePopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        QuoteId: PropTypes.number.isRequired,
        handleCloseQuote: PropTypes.func.isRequired,
        handleDeleteQuote: PropTypes.func.isRequired,
        Restrictions: QuoteRestrictionsType.isRequired,
        QuoteData: singleQuoteType.isRequired
    };

    containerFunctions = {
        handleCheckoutWithQuote: this._handleCheckoutWithQuote.bind(this),
        handleDeleteQuote: this._handleDeleteQuote.bind(this),
        handleCloseQuote: this._handleCloseQuote.bind(this)
    };

    containerProps = () => {
        const { Restrictions } = this.props;
        const {
            QuoteData: {
                grand_total, subtotal, tax_amount, shipping_incl_tax,
                quote_currency_code, negotiated_quote: {
                    original_total_price
                } = {}
            } = {}
        } = this.props;

        return {
            Restrictions,
            CurrencyCode: quote_currency_code,
            original_total_price,
            subtotal,
            grand_total,
            shipping_incl_tax,
            tax_amount
        };
    };

    _handleCheckoutWithQuote() {
        this._convertToCheckout();
    }

    _handleCloseQuote() {
        const { handleCloseQuote } = this.props;
        handleCloseQuote();
    }

    _handleDeleteQuote() {
        const { handleDeleteQuote } = this.props;
        handleDeleteQuote();
    }

    async _convertToCheckout() {
        const { hideActivePopup } = this.props;
        await hideActivePopup();
        history.push({ pathname: appendWithStoreCode('/b2bcheckout/shipping') });
        showNotification('success', __('Complete your quote'));
    }

    render() {
        return (
            <QuoteViewActions
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteViewActionsContainer);
