import Event, { EVENT_GTM_ADD_PAYMENT_INFO } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/** @namespace Gtm/Component/GoogleTagManager/Events/AddPaymentInfo/Event/AddPaymentInfoEvent */
export class AddPaymentInfoEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_ADD_PAYMENT_INFO, ({ totals }) => {
            this.handle(
                totals
            );
        });
    }

    /**
     * Add add_payment_info event info to dataLayer
     */
    handler(
        totals
    ) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const couponCode = (totals.coupon_code && totals.coupon_code.length) ? totals.coupon_code : '';
        const cartItems = this.getCartItems(totals.items, couponCode);

        /* Note that payment_type should be included here (selected payment method) but at this point in time we have agreed to ommit it */
        this.pushEventData({
            ecommerce: {
                checkout: {
                    currency: totals.quote_currency_code,
                    value: totals.grand_total,
                    coupon: couponCode,
                    items: cartItems
                }
            }
        });
    }

    /**
     * Get product details, GA4 naming and
     *
     * @param {{items}[]}
     *
     * @return {{item_id: string, item_name: string, affiliation: string, coupon: string, discount: number, index: number, item_brand: string, item_variant: string, location_id: string, price: number, quantity: string}[]}
     */
    getCartItems(items, couponCode) {
        const result = items.map((item, i) => ({
            item_id: item.sku,
            item_name: item.product.name,
            affiliation: 'Rapelli store',
            coupon: couponCode,
            discount: item.discount_amount,
            index: i,
            item_brand: ProductHelper.getBrand(item.product),
            item_variant: ProductHelper.getWeightWithLabel(item.product),
            location_id: '',
            price: item.price,
            quantity: item.qty
        }));

        return result;
    }
}

export default AddPaymentInfoEvent;
