import { Field } from 'Util/Query';

/** @namespace Screenpages/FacebookConversionApi/Query/CustomerData/Query/CustomerDataQuery */
export class CustomerDataQuery {
    _getData = () => ([
        'customer_ip'
    ]);

    getCustomerData = () => new Field('getCustomerData')
        .setAlias('fbCustomerData')
        .addFieldList(this._getData());
}

export default new CustomerDataQuery();
