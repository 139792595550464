import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { CompanyUserGridUserType } from '../../type/B2bCompany';
import { COMPANY_EDIT_POPUP, COMPANY_FORM_EDIT_USER } from '../CompanyEditPopup/CompanyEditPopup.config';
import CompanyUsersRow from './CompanyUsersRow.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyUsersRow/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Screenpages/b2bcompany/Component/CompanyUsersRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showEditUsersForm: (payload) => _dispatch(showPopup(COMPANY_EDIT_POPUP, payload))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyUsersRow/Container/CompanyUsersRowContainer */
export class CompanyUsersRowContainer extends PureComponent {
    static propTypes = {
        CompanyUserData: CompanyUserGridUserType, // TODO check prop type
        showEditUsersForm: PropTypes.func.isRequired,
        isAllowedEditUsers: PropTypes.bool
    };

    static defaultProps = {
        CompanyUserData: {},
        isAllowedEditUsers: false
    };

    containerFunctions = {
        editCurrentUser: this._editCurrentUser.bind(this)
    };

    _editCurrentUser() {
        const {
            isAllowedEditUsers, showEditUsersForm, CompanyUserData,
            CompanyUserData: { entity_id, firstname, lastname } = {}
        } = this.props;
        const fullname = `${firstname } ${ lastname}`;
        if (!isAllowedEditUsers || !entity_id) {
            return;
        }
        showEditUsersForm({
            title: __('Edit Customer %s', fullname),
            type: COMPANY_FORM_EDIT_USER,
            userData: CompanyUserData
        });
    }

    containerProps = () => {
        const { CompanyUserData } = this.props;
        return {
            CompanyUserData
        };
    };

    render() {
        return (
            <CompanyUsersRow
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersRowContainer);
