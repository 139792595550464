import ClickOutside from 'Component/ClickOutside';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Rapelli/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside clickOutside={ false }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
