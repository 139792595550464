import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
} from 'SourceComponent/ProductPrice/ProductPrice.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    formatPrice
} from 'Util/Price';

export {
    mapDispatchToProps, mapStateToProps
};

/** @namespace Rapelli/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...SourceProductPriceContainer.propTypes,
        quantity: PropTypes.number,
        priceperunit: PropTypes.string,
        b2bOldPrice: PropTypes.number,
        b2bPrice: PropTypes.number
    };

    static defaultProps = {
        ...SourceProductPriceContainer.defaultProps,
        quantity: 1,
        priceperunit: '',
        b2bOldPrice: 0,
        brbPrice: 0
    };

    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            price_tiers,
            displayTaxInPrice = '',
            quantity,
            unitMeasure
        } = this.props;

        const pricePerWeightTxt = unitMeasure ? `/${ unitMeasure}` : '';
        if (price_tiers.length && quantity > 1) {
            const matchedTierPricees = price_tiers.filter((tier) => quantity >= tier.quantity);
            if (matchedTierPricees.length) {
                const singleTierPrice = matchedTierPricees[matchedTierPricees.length - 1];
                const {
                    final_price: {
                        value: tierPrieFinalPrice = 0
                    } = {}
                } = singleTierPrice;

                if (tierPrieFinalPrice > 0) {
                    return formatPrice(tierPrieFinalPrice, priceCurrency) + pricePerWeightTxt;
                }
            }
        }

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return formatPrice(minimalPriceExclTaxValue, priceCurrency) + pricePerWeightTxt;
        }

        return formatPrice(minimalPriceValue, priceCurrency) + pricePerWeightTxt;
    }

    containerProps() {
        const {
            priceperunit,
            b2bOldPrice,
            b2bPrice
        } = this.props;

        const isShowB2bSpecialPrice = b2bPrice < b2bOldPrice && b2bOldPrice > 0;

        return {
            ...super.containerProps(),
            priceperunit,
            b2bOldPrice,
            isShowB2bSpecialPrice
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
