/* eslint-disable no-mixed-operators */
import cssParser from 'css';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MPPSingleProductLabelType } from '../../type/MPProductLabel';
import ProductLabel from './ProductLabel.component';

/** @namespace Screenpages/Mpproductlabel/Component/ProductLabel/Container/ProductLabelContainer */
export class ProductLabelContainer extends PureComponent {
    static propTypes = {
        SingleProductLabel: MPPSingleProductLabelType,
        isProductPage: PropTypes.bool,
        lblContainerHeight: PropTypes.number,
        lblContainerWidth: PropTypes.number
    };

    static defaultProps = {
        SingleProductLabel: {},
        isProductPage: false,
        lblContainerHeight: 0,
        lblContainerWidth: 0
    };

    _getLabelStyleRawVals() {
        const {
            isProductPage,
            SingleProductLabel: {
                list_color = '',
                list_font_size = '',
                list_position = '',
                list_css = '',
                label_color = '',
                label_font_size = '',
                label_position = '',
                label_css = ''
            }
        } = this.props;

        return isProductPage ? {
            color: label_color,
            font_size: label_font_size,
            position: label_position,
            extracss: label_css
        } : {
            color: list_color,
            font_size: list_font_size,
            position: list_position,
            extracss: list_css
        };
    }

    _getLabelStyles() {
        const {
            color, font_size, position, extracss
        } = this._getLabelStyleRawVals();

        if (!position) {
            return null;
        }

        const additionalLblStyles = this._convertCssStringToObject(extracss, '#design-labels');

        const listPositionData = JSON.parse(position);
        const {
            label: {
                percentTop, width, height, percentLeft
            } = {}
        } = listPositionData;

        const { lblContainerHeight, lblContainerWidth } = this.props;
        const hundrenPercent = 100;
        const leftPosition = () => {
            const galleryWidth = lblContainerWidth;
            return (galleryWidth - width) * percentLeft / hundrenPercent / galleryWidth * hundrenPercent;
        };
        const topPosition = () => {
            const galleryHeight = lblContainerHeight;
            return (galleryHeight - height) * percentTop / hundrenPercent / galleryHeight * hundrenPercent;
        };

        const style = {
            color,
            fontSize: `${ font_size }px`,
            left: `${leftPosition()}%`,
            top: `${topPosition()}%`,
            width: `${ width }px`,
            height: `${ height }px`,
            position: 'absolute'
        };

        return { ...style, ...additionalLblStyles };
    }

    cleanPropertyName(name) {
        // turn things like 'align-items' into 'alignItems'
        return name.replace(/(-.)/g, (v) => v[1].toUpperCase());
    }

    _convertCssStringToObject(cssString, lbl) {
        const style = {};
        if (!cssString) {
            return style;
        }
        const { stylesheet: { rules } = [] } = cssParser.parse(cssString);
        if (rules && rules.length > 0) {
            const selectedRule = rules.find((rule) => rule.selectors.includes(lbl));
            if (selectedRule) {
                const { declarations } = selectedRule;
                declarations.forEach(({ property, value }) => {
                    const cleanProperty = this.cleanPropertyName(property);
                    style[cleanProperty] = value;
                });
            }
        }

        return style;
    }

    _getAdditionalTextStyle() {
        const { extracss } = this._getLabelStyleRawVals();
        return this._convertCssStringToObject(extracss, '#design-label-text');
    }

    _getLabelText() {
        const {
            isProductPage,
            SingleProductLabel: {
                label = '',
                list_label = ''
            }
        } = this.props;

        return isProductPage ? label : list_label;
    }

    _getTemplateImage() {
        const {
            isProductPage,
            SingleProductLabel: {
                list_template = null,
                label_template = null

            }
        } = this.props;

        return isProductPage ? label_template : list_template;
    }

    _getLabelIamePath() {
        const {
            isProductPage,
            SingleProductLabel: {
                list_image = null,
                label_image = null
            }
        } = this.props;

        return isProductPage ? label_image : list_image;
    }

    containerProps = () => ({
        LabelStyles: this._getLabelStyles(),
        LabelText: this._getLabelText(),
        TemplateImagePath: this._getTemplateImage(),
        LabelImagePath: this._getLabelIamePath(),
        AdditionalTextStyle: this._getAdditionalTextStyle()
    });

    render() {
        return (
            <ProductLabel
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductLabelContainer;
