import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';

import { QuoteCommentsType } from '../../type/B2bQuote';

import './QuoteComments.style';

/** @namespace Screenpages/b2bquote/Component/QuoteComments/Component/QuoteCommentsComponent */
export class QuoteCommentsComponent extends PureComponent {
    static propTypes = {
        QuoteComments: QuoteCommentsType,
        formatDate: PropTypes.func.isRequired,
        handleFormSubmit: PropTypes.func.isRequired,
        handleSubmitError: PropTypes.func.isRequired,
        handleSubmitSuccess: PropTypes.func.isRequired,
        isAbleToSubmit: PropTypes.bool.isRequired,
        isCommentFormSubmitting: PropTypes.bool
    };

    static defaultProps = {
        QuoteComments: false,
        isCommentFormSubmitting: false
    };

    renderAllComments() {
        const { QuoteComments, formatDate } = this.props;
        if (!QuoteComments) {
            return null;
        }

        return QuoteComments.map((SingleComment) => {
            const {
                id,
                comment,
                created_at,
                author,
                is_decline
            } = SingleComment;

            return (
                <div block="QuoteComments" elem="SingleComment" key={ id }>
                    <div block="QuoteComments" elem="CommentCreated">
                        { author }
                        { ': ' }
                        { formatDate(created_at) }

                    </div>
                    { this.renderDeclinedHeader(is_decline) }
                    <div block="QuoteComments" elem="Comment">
                        { comment }
                    </div>
                </div>
            );
        });
    }

    renderDeclinedHeader(isDecline) {
        if (!isDecline) {
            return null;
        }

        return (
            <div block="QuoteComments" elem="Delined">
                { __('Request Declined: Reason:') }
            </div>
        );
    }

    renderAddCommentForm() {
        const {
            handleFormSubmit,
            handleSubmitError,
            handleSubmitSuccess,
            isAbleToSubmit,
            isCommentFormSubmitting
        } = this.props;

        if (!isAbleToSubmit) {
            return null;
        }

        return (
            <div block="QuoteComments" elem="FormWrapper">
                <Form
                  mix={ { block: 'QuoteComments', elem: 'ActionsForm' } }
                  key="addCommentForm"
                  onSubmit={ handleFormSubmit }
                  onSubmitSuccess={ handleSubmitSuccess }
                  onSubmitError={ handleSubmitError }
                >
                    <Field
                      type="textarea"
                      label={ __('Add a Comment') }
                      name="quotecomment"
                      id="quotecomment"
                    />

                    <div block="QuoteComments" elem="FormButtons">
                        <button
                          block="Button"
                          mods={ { isCommentFormSubmitting } }
                          disabled={ isCommentFormSubmitting }
                        >
                            <span>{ __('Add a comment') }</span>
                            <span>{ __('Adding comment...') }</span>
                        </button>
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <div block="QuoteComments">
                { this.renderAllComments() }
                { this.renderAddCommentForm() }
            </div>
        );
    }
}

export default QuoteCommentsComponent;
