import { getIndexedProduct } from 'Util/Product';

import {
    B2BQUOTE_UPDATE_SHIPPING_PRICE,
    CLEAR_B2B_QUOTES,
    CLEAR_B2B_SINGLE_QUOTE,
    SET_B2B_QUOTE_LIST,
    SET_B2B_SINGLE_QUOTE
} from './B2bQuote.action';

export const B2BQUOTELIST = 'B2QuotesList';

/** @namespace Screenpages/b2bquote/Store/B2bQuote/Reducer/getInitialState */
export const getInitialState = () => ({
    b2bQuoteList: [],
    isGridLoading: true,
    singleQuoteData: {},
    isSingleQuoteLoaded: false,
    b2bCartTotals: {
        items: {}
    }
});

/** @namespace Screenpages/b2bquote/Store/B2bQuote/Reducer/B2bQuoteReducer */
export const B2bQuoteReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_B2B_QUOTE_LIST:
        const { b2bQuoteList } = action;
        return {
            ...state,
            b2bQuoteList,
            isGridLoading: false
        };

    case SET_B2B_SINGLE_QUOTE:
        const { singleQuoteData } = action;
        if (Object.hasOwnProperty.call(singleQuoteData, 'items')) {
            const normalisedProducts = singleQuoteData.items.map((item) => {
                const NormaliszedItem = item;
                NormaliszedItem.product = getIndexedProduct(item.product);
                return NormaliszedItem;
            });

            singleQuoteData.items = normalisedProducts;
        }

        return {
            ...state,
            singleQuoteData,
            isSingleQuoteLoaded: true
        };
    case CLEAR_B2B_SINGLE_QUOTE:
        return {
            ...state,
            singleQuoteData: {},
            isSingleQuoteLoaded: false
        };
    case CLEAR_B2B_QUOTES: {
        return {
            ...getInitialState()
        };
    }
    case B2BQUOTE_UPDATE_SHIPPING_PRICE: {
        const {
            data: {
                items,
                ...rest
            }
        } = action;

        return {
            ...state,
            singleQuoteData: {
                ...state.singleQuoteData,
                ...rest
            }
        };
    }
    default:
        return state;
    }
};

export default B2bQuoteReducer;
