import { Field } from 'Util/Query';

export class ProductListQueryPlugin {
  aroundGetProductInterfaceFields = (args, callback, instance) => [
      ...callback.apply(instance, args),
      this._getProductLabelField()
  ];

  _getProductLabelField() {
      return new Field('mp_label_data')
          .addFieldList(this._getProductLabelFields());
  }

  _getProductLabelFields() {
      return [
          'rule_id',
          'name',
          'enabled',
          'store_ids',
          'customer_group_ids',
          'priority',
          'label_template',
          'label_image',
          'label',
          'label_font',
          'label_font_size',
          'label_color',
          'label_css',
          'label_position',
          'label_position_grid',
          'same',
          'list_template',
          'list_image',
          'list_label',
          'list_font',
          'list_font_size',
          'list_color',
          'list_css',
          'list_position',
          'list_position_grid',
          'conditions_serialized',
          'bestseller',
          'limit',
          'from_date',
          'to_date',
          'updated_at',
          'stop_process',
          'created_at'
      ];
  }
}

const { aroundGetProductInterfaceFields } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: {
                position: 100,
                implementation: aroundGetProductInterfaceFields
            }
        }
    }
};
