import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { quoteRowType } from '../../type/B2bQuote';
import { QUOTE_VIEW_POPUPID } from '../QuoteViewPopup/QuoteViewPopup.config';
import QuoteTableRow from './QuoteTableRow.component';

/** @namespace Screenpages/b2bquote/Component/QuoteTableRow/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    currencyCode: _state.ConfigReducer.getCurrencyData
});

/** @namespace Screenpages/b2bquote/Component/QuoteTableRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showPopup: (payload) => _dispatch(showPopup(QUOTE_VIEW_POPUPID, payload))
});

/** @namespace Screenpages/b2bquote/Component/QuoteTableRow/Container/QuoteTableRowContainer */
export class QuoteTableRowContainer extends PureComponent {
    static propTypes = {
        QuoteRowData: quoteRowType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        onViewQuote: this._onViewQuote.bind(this),
        formatDate: this._formatDate.bind(this)
    };

    containerProps = () => {
        const { QuoteRowData } = this.props;
        return {
            QuoteRowData
        };
    };

    _onViewQuote() {
        const { showPopup, QuoteRowData: { quote_id, quote_name } } = this.props;

        showPopup({
            title: __('Quote: %s', quote_name),
            quote_id
        });
    }

    _formatDate(rawDate) {
        const date = new Date(rawDate.replace(/\s/, 'T'));
        const RADIX = 10;

        const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

        const day = addLeadingZero(date.getDate());
        const month = addLeadingZero(date.getMonth() + 1);

        return `${day}.${month}.${date.getFullYear()}`;
    }

    render() {
        return (
            <QuoteTableRow
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTableRowContainer);
