/* eslint-disable max-len */
import {
    CartIcon as SourceCartIcon
} from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.override.style';

/** @namespace Rapelli/Component/CartIcon/Component/CartIconComponent */
export class CartIconComponent extends SourceCartIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9.9,1.271a3.178,3.178,0,0,1,3.178,3.178v.636H6.72V4.449A3.178,3.178,0,0,1,9.9,1.271Zm4.449,3.813V4.449a4.449,4.449,0,1,0-8.9,0v.636H1v12.71a2.542,2.542,0,0,0,2.542,2.542h12.71a2.542,2.542,0,0,0,2.542-2.542V5.084ZM2.271,6.355H17.523V17.794a1.271,1.271,0,0,1-1.271,1.271H3.542a1.271,1.271,0,0,1-1.271-1.271Z" />
            </svg>
        );
    }
}

export default CartIconComponent;
