import {
    isNotEmpty as sourceIsNotEmpty,
    MIN_PASSWORD_LENGTH as SOURCE_MIN_PASSWORD_LENGTH,
    validatePassword as sourceValidatePassword,
    validatePasswordMatch as sourceValidatePasswordMatch,
    validateTelephone as sourceValidateTelephone
} from 'SourceComponent/Form/Form.config';

// TODO: implement MIN_PASSWORD_LENGTH
export const MIN_PASSWORD_LENGTH = SOURCE_MIN_PASSWORD_LENGTH;
export const MAX_ADDRESS_LENGTH = 40;
export const MAX_NAME_LENGTH = 20;
export const MAX_35_LENGTH = 35;

// TODO: implement validateEmail
/** @namespace Rapelli/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$|^$/i);

// TODO: implement validateEmails
/** @namespace Rapelli/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

// TODO: implement validatePassword
export const validatePassword = sourceValidatePassword;

// TODO: implement validateTelephone
export const validateTelephone = sourceValidateTelephone;

// TODO: implement isNotEmpty
export const isNotEmpty = sourceIsNotEmpty;

// TODO: implement validatePasswordMatch
export const validatePasswordMatch = sourceValidatePasswordMatch;

/** @namespace Rapelli/Component/Form/Config/validatePostCode */
export const validatePostCode = ({ value }) => value.length > 0 && value.match(new RegExp('^[0-9]{1,4}$'));

/** @namespace Rapelli/Component/Form/Config/isNotSelectRegion */
export const isNotSelectRegion = ({ value }) => parseInt(value, 10) !== 0;

/** @namespace Rapelli/Component/Form/Config/isMaxLength */
export const isMaxLength = ({ value }) => value.length <= MAX_ADDRESS_LENGTH;

/** @namespace Rapelli/Component/Form/Config/nameMaxLength */
export const nameMaxLength = ({ value }) => value.length <= MAX_NAME_LENGTH;

/** @namespace Rapelli/Component/Form/Config/max35Length */
export const max35Length = ({ value }) => value.length <= MAX_35_LENGTH;

/** @namespace Rapelli/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    notSelectRegion: {
        validate: isNotSelectRegion,
        message: __('This field is required!')
    },
    maxLength: {
        validate: isMaxLength,
        message: __('You can\'t use more than 40 characters')
    },
    postCode: {
        validate: validatePostCode,
        message: __('Post code is not valid, must be 4 characters and numeric only')
    },
    nameMaxLength: {
        validate: nameMaxLength,
        message: __('You can\'t use more than 20 characters')
    },
    max35Length: {
        validate: max35Length,
        message: __('You can\'t use more than 35 characters')
    }
});

export default formConfig();
