import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LOQATE_TIMEOUT } from './Loqate.config';
// import Loqate from './Loqate.component';

/** @namespace Screenpages/Loqate/Component/Loqate/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    isLoqatenabled: _state.ConfigReducer.sp_loqate_enabled,
    loqateAccountCode: _state.ConfigReducer.sp_loqate_accountcode
});

/** @namespace Screenpages/Loqate/Component/Loqate/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Screenpages/Loqate/Component/Loqate/Container/LoqateContainer */
export class LoqateContainer extends PureComponent {
    static propTypes = {
        isLoqatenabled: PropTypes.bool,
        loqateAccountCode: PropTypes.string
    };

    static defaultProps = {
        isLoqatenabled: false,
        loqateAccountCode: ''
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        this.injectLoqareScript();
    }

    componentDidUpdate() {
        this.injectLoqareScript();
    }

    injectLoqareScript() {
        const { isLoqatenabled, loqateAccountCode } = this.props;

        if (!isLoqatenabled || !loqateAccountCode) {
            return;
        }
        const script = document.createElement('script');

        script.innerHTML = `(function(n,t,i,r){var u,f;n[i]=n[i]||{},
        n[i].initial={accountCode:"${loqateAccountCode}",host:"${loqateAccountCode}.pcapredict.com"},
        n[i].on=n[i].on||function(){(n[i].onq=n[i].onq||[]).push(arguments)},
        u=t.createElement("script"),u.async=!0,u.src=r,
        f=t.getElementsByTagName("script")[0],
        f.parentNode.insertBefore(u,f)})
        (window,document,"pca","//${loqateAccountCode}.pcapredict.com/js/sensor.js")`;
        setTimeout(() => {
            document.head.insertBefore(script, document.head.firstChild);
        }, LOQATE_TIMEOUT);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoqateContainer);
