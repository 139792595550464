import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import B2bCompanyProfieQuery from '../../query/B2bCompanyProfie.query';
import { b2bCompanySingleRoleType } from '../../type/B2bCompany';
import CompanyRolesEditForm from './CompanyRolesEditForm.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showNotify: (t, m) => _dispatch(showNotification(t, m))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/CompanyRolesEditFormContainer */
export class CompanyRolesEditFormContainer extends PureComponent {
    static propTypes = {
        handleCloseAndReload: PropTypes.func.isRequired,
        showNotify: PropTypes.func.isRequired,
        roleData: b2bCompanySingleRoleType
    };

    static defaultProps = {
        roleData: {}
    };

    state = {
        isFormSubmitting: false,
        formPermissions: [],
        clicker: 0
    };

    containerFunctions = {
        handleSubmit: this._handleSubmit.bind(this),
        handleSubmitSuccess: this._handlSubmitSuccess.bind(this),
        handleSubmitError: this._handleSubmitError.bind(this),
        handleOptionChange: this._handleOptionChange.bind(this),
        handleDeleteRole: this._handleDeleteRole.bind(this)
    };

    componentDidMount() {
        const { roleData: { role_id } = 0 } = this.props;
        if (role_id > 0) {
            this._setPermissionsFromCurrentProps();
        }
    }

    componentDidUpdate(prevProps) {
        const { roleData: { role_id } = 0 } = this.props;
        const { roleData: { role_id: prevRoleId } = 0 } = prevProps;
        const { formPermissions } = this.state;
        if (role_id !== prevRoleId && role_id > 0) {
            this._setPermissionsFromCurrentProps();
        } else if (role_id === 0 && prevRoleId > 0) {
            if (formPermissions.length > 0) {
                this._clearPermissionFromState();
            }
        }
    }

    componentWillUnmount() {
        // this.setState({ formPermissions: [] });
    }

    _setPermissionsFromCurrentProps() {
        const { roleData: { permissions } = [] } = this.props;
        const convertedPermissions = Object.entries(permissions).reduce(
            (convertedPermissions, [, v]) => ({ ...convertedPermissions, [v]: true }), []
        );

        this.setState({ formPermissions: convertedPermissions });
        // const { formPermissions } = this.state;
        // permissions.map((field) => {
        //     res = [...res, res[field] = true];
        // });
        // const { formPermissions } = this.state;
        // convert2 = [];

        // const permissions2 = permissions.map((data) => {
        //     const res = [];
        //     res[data] = true;
        //     res = [...res. newVal];
        // });

        // this.setState({ formPermissions: permissions2 });
    }

    _clearPermissionFromState() {
        this.setState({ formPermissions: [] });
    }

    containerProps = () => {
        const { clicker, isFormSubmitting, formPermissions } = this.state;
        const { roleData } = this.props;
        return {
            isFormSubmitting,
            roleData,
            formPermissions,
            clicker
        };
    };

    _handleSubmit() {
        //
    }

    _handlSubmitSuccess(fields) {
        const { handleCloseAndReload, showNotify, roleData: { role_id } = 0 } = this.props;
        if (fields) {
            this.setState({ isFormSubmitting: true });
            fetchMutation(B2bCompanyProfieQuery.setUpdateCompanyRole(role_id, fields)).then(
                /** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/fetchMutation/then */
                ({ b2bSaveCompanyRole: { status, message } }) => {
                    this.setState({ isFormSubmitting: false });
                    if (status) {
                        showNotify('success', __('Role has been saved'));
                        handleCloseAndReload();
                        return;
                    }
                    handleCloseAndReload();
                    showNotify('error', message);
                },
                /** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/fetchMutation/then */
                ([{ message }]) => {
                    this.setState({ isFormSubmitting: false });
                    showNotify('error', message);
                }
            );
        }
    }

    _handleSubmitError(e) {
        const { showNotify } = this.props;
        if (e) {
            // TODO, what can I catch
        }
        showNotify('error', 'Form has failed');
        // this.setState({ isFormSubmitting: false });
    }

    _handleDeleteRole() {
        const { handleCloseAndReload, showNotify, roleData: { role_id } = 0 } = this.props;
        if (role_id > 0) {
            fetchMutation(B2bCompanyProfieQuery.setDeleteCompanyRole(role_id)).then(
                /** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Container/fetchMutation/then */
                ({ b2bDeleteCompanyRole: { status, message } }) => {
                    handleCloseAndReload();
                    if (status) {
                        showNotify('success', __('The role has been deleted'));
                        return;
                    }
                    showNotify('error', message);
                }
            );
        }
    }

    _handleOptionChange(option, selected) {
        const { formPermissions } = this.state;
        const { clicker } = this.state;
        formPermissions[option] = selected;
        this.setState({ formPermissions });
        this.setState({ clicker: clicker + 1 }); // HACK

        /**
         * On investigation, I found that ReactJS does a shallowEquql, comparing props and state to determine if it should re-render
         * If update an onject or array within state, the shallowEqual will be the same, causing the component to not update
         node_modules/react-dom/cjs/react-dom.development.js
         function checkShouldComponentUpdate...
            if (ctor.prototype && ctor.prototype.isPureReactComponent) {
                return !shallowEqual(oldProps, newProps) || !shallowEqual(oldState, newState);
            }
         */
    }

    render() {
        return (
            <CompanyRolesEditForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRolesEditFormContainer);
