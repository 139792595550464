import AdyenCheckout from '@adyen/adyen-web';

import { fetchQuery } from 'Util/Request';

import { ADYEN_CC, CREDIT_CARD_TYPE } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';
import AdyenQuery from '../query/Adyen.query';
import { getCcCodeByAltCodeUtil } from '../util/GetCcCodeByAltCode.util';

import '@adyen/adyen-web/dist/adyen.css';

const componentDidMount = (args, callback, instance) => {
    const { paymentMethods, adyenPaymentMethods } = instance.props;

    getPaymentMethods(instance, paymentMethods, adyenPaymentMethods);
    initializeAdyen(instance, adyenPaymentMethods);

    callback(...args);
};

const getPaymentMethods = (instance, paymentMethods, adyenObject) => {
    const {
        paymentMethodsResponse:
        { paymentMethods: adyenMethods = [], storedPaymentMethods = [] } = {}, paymentMethodsExtraDetails = {}
    } = adyenObject || {};
    const paymentMethodsContainHPP = paymentMethods.some(({ code }) => code === ADYEN_HPP);

    if (!paymentMethodsContainHPP) {
        // return paymentMethods;
    }

    const newMethods = [...paymentMethods, ...adyenMethods].reduce((methods, method) => {
        if (method.code === ADYEN_HPP || method.type === CREDIT_CARD_TYPE) {
            return methods;
        }

        if (method.code === ADYEN_CC && method.brand) {
            const { brands = [] } = adyenMethods.find((cart) => cart.type === CREDIT_CARD_TYPE);
            return [...methods, {
                ...method,
                type: CREDIT_CARD_TYPE,
                details: storedPaymentMethods.find(({ type }) => type === CREDIT_CARD_TYPE),
                brands
            }];
        }

        if ('type' in method) {
            if (method.type === 'applepay' && (location.protocol !== 'https:' || !window.ApplePaySession)) {
                return methods;
            }

            return [
                ...methods,
                {
                    ...method,
                    code: ADYEN_HPP,
                    title: method.name,
                    details: storedPaymentMethods.find(({ type }) => type === method.type) || [],
                    config: paymentMethodsExtraDetails[method.type]
                }
            ];
        }

        return [...methods, method];
    }, []);

    instance.setState({ newMethods });
};

const containerProps = (args, callback, instance) => {
    const { newMethods = [] } = instance.state;

    return {
        ...callback(...args),
        paymentMethods: newMethods
    };
};

const initializeAdyen = async (instance, adyenMethods) => {
    if (!adyenMethods) {
        return;
    }

    const { paymentMethodsResponse } = adyenMethods;
    const { handleDetails } = instance.props;
    const { getAdyenConfig } = await fetchQuery(AdyenQuery.getConfig());
    const {
        originKey,
        mode: environment,
        locale
    } = getAdyenConfig || {};

    const configuration = {
        paymentMethodsResponse, // The `/paymentMethods` response from the server.
        clientKey: originKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        locale,
        environment,
        onChange: (state, dropin) => {
            if (state.isValid) {
                instance.setState({
                    adyenState: state.data
                });
                window.document.getElementsByClassName('CheckoutBilling-Button')[0].removeAttribute('disabled');
            }
        },
        onSubmit: (state, dropin) => {
            if (state.isValid) {
                instance.setState({
                    adyenState: state.data
                });
            }
            // Sorry but we need this to trigger native button submit event
            const checkoutBillingBtn = window.document.getElementsByClassName('CheckoutBilling-Button');

            if (checkoutBillingBtn) {
                checkoutBillingBtn[0].removeAttribute('disabled');
                checkoutBillingBtn[0].click();
            }
            // window.document.getElementsByClassName('CheckoutBilling-Button')[0].removeAttribute('disabled');
            // window.document.getElementsByClassName('CheckoutBilling-Button')[0].click();
        },
        onAdditionalDetails: ({ data }, dropin) => {
            handleDetails(data);
        },
        paymentMethodsConfiguration: {
            card: {
                hasHolderName: true,
                holderNameRequired: true,
                enableStoreDetails: false,
                hideCVC: false,
                name: 'Credit or debit card'
            }
        }
    };

    window.adyen = await AdyenCheckout(configuration);
};

const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;

    if (code === ADYEN_CC) {
        const [fields, asyncData] = args;
        const { additional_data: extraData } = asyncData[0];
        const { adyenState = {} } = instance.state;
        const stateData = JSON.stringify(adyenState);
        const cc_type = getCcCodeByAltCodeUtil(adyenState.paymentMethod.brand);

        const additional_data = {
            ...extraData, stateData, cc_type
        };

        return { code, additional_data };
    }

    if (code === ADYEN_HPP) {
        const [fields, asyncData] = args;
        const { additional_data: extraData } = asyncData[0];
        const { adyenState = {} } = instance.state;
        const stateData = JSON.stringify(adyenState);

        const additional_data = {
            ...extraData, stateData
        };

        return { code, additional_data };
    }

    return callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount,
            containerProps,
            _getPaymentData
        }
    }
};
