import { connect } from 'react-redux';

import {
    FieldInputContainer as SourceFieldInputContainer
} from 'SourceComponent/FieldInput/FieldInput.container';

/** @namespace Rapelli/Component/FieldInput/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    loqateAccountCode: state.ConfigReducer.sp_loqate_accountcode
    // TODO extend mapStateToProps
});

/** @namespace Rapelli/Component/FieldInput/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    // TODO extend mapDispatchToProps
});

/** @namespace Rapelli/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends SourceFieldInputContainer {

}

export default connect(mapStateToProps, mapDispatchToProps)(FieldInputContainer);
