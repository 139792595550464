import { DataContainer as SourceDataContainer } from 'SourceUtil/Request/DataContainer';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { hash } from 'Util/Request/Hash';

// 12 hours
export const TWLEVE_HOURS_IN_SECONDS = 43200;

/** @namespace Rapelli/Util/Request/DataContainer/DataContainer */
export class DataContainer extends SourceDataContainer {
    fetchData(rawQueries, onSuccess = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            // onSuccess(window.dataCache[queryHash]);
            // return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, TWLEVE_HOURS_IN_SECONDS)
        );

        this.promise.promise.then(
            /** @namespace Rapelli/Util/Request/DataContainer/then */
            (response) => {
                // window.dataCache[queryHash] = response;
                onSuccess(response);
            },
            /** @namespace Rapelli/Util/Request/DataContainer/then */
            (err) => onError(err)
        );
    }
}

export default DataContainer;
