import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER as SOURCE_CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS as SOURCE_ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

// TODO: implement CUSTOMER
export const CUSTOMER = SOURCE_CUSTOMER;

// TODO: implement ONE_MONTH_IN_SECONDS
export const ONE_MONTH_IN_SECONDS = SOURCE_ONE_MONTH_IN_SECONDS;

/** @namespace Rapelli/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    // TODO implement logic
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Rapelli/Store/MyAccount/Dispatcher/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return 2;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Rapelli/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                Promise.reject();

                return false;
            }
        );
    }

    /**
    * Overridden to add additional revoke account token mutation when logging out.
    */
    logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();
        } else {
            const mutation = MyAccountQuery.getRevokeAccountToken();
            fetchMutation(mutation);
            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }
        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();
        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));
        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );
        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    }
}

export default new MyAccountDispatcher();
