import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup/Popup.container';

import { THREE_D } from './AdyenCC.config';

/** @namespace AdyenPayment/Component/AdyenCC/Component/AdyenCCComponent */
export class AdyenCCComponent extends PureComponent {
    static propTypes = {
        fieldRef: PropTypes.shape({
            current: PropTypes.instanceOf(Element)
        }).isRequired
    };

    render() {
        const { fieldRef } = this.props;

        return (
            <>
            <div ref={ fieldRef } />
                <Popup
                  id={ THREE_D }
                  mix={ { block: 'AdyenCC', elem: 'Popup' } }
                >
                    <div id="three" />
                </Popup>
            </>
        );
    }
}

export default AdyenCCComponent;
