/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';

import Breadcrumbs from 'Component/Breadcrumbs';
import {
    CartPage as SourceCartPage,
    Checkout as SourceCheckout,
    CmsPage as SourceCmsPage,
    ConfirmAccountPage as SourceConfirmAccountPage,
    ContactPage as SourceContactPage,
    CookiePopup as SourceCookiePopup,
    CreateAccountPage as SourceCreateAccountPage,
    DemoNotice as SourceDemoNotice,
    Footer as SourceFooter,
    ForgotPasswordPage as SourceForgotPasswordPage,
    Header as SourceHeader,
    HomePage as SourceHomePage,
    LoginAccountPage as SourceLoginAccountPage,
    MenuPage as SourceMenuPage,
    MyAccount as SourceMyAccount,
    NavigationTabs as SourceNavigationTabs,
    NewVersionPopup as SourceNewVersionPopup,
    NotificationList as SourceNotificationList,
    OfflineNotice as SourceOfflineNotice,
    PasswordChangePage as SourcePasswordChangePage,
    ProductComparePage as SourceProductComparePage,
    Router as SourceRouter,
    SearchPage as SourceSearchPage,
    SomethingWentWrong as SourceSomethingWentWrong,
    StyleGuidePage as SourceStyleGuidePage,
    WishlistShared as SourceWishlistShared,
    withStoreRegex as sourceWithStoreRegex
} from 'SourceComponent/Router/Router.component';

import {
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    COOKIEBOT
} from './Router.config';

import './Router.override.style';

// TODO: implement CartPage
export const CartPage = SourceCartPage;

// TODO: implement Checkout
export const Checkout = SourceCheckout;

// TODO: implement CmsPage
export const CmsPage = SourceCmsPage;

// TODO: implement CookiePopup
export const CookiePopup = SourceCookiePopup;

// TODO: implement DemoNotice
export const DemoNotice = SourceDemoNotice;

// TODO: implement Header
export const Header = SourceHeader;

// TODO: implement HomePage
export const HomePage = SourceHomePage;

// TODO: implement MyAccount
export const MyAccount = SourceMyAccount;

// TODO: implement PasswordChangePage
export const PasswordChangePage = SourcePasswordChangePage;

// TODO: implement SearchPage
export const SearchPage = SourceSearchPage;

// TODO: implement ConfirmAccountPage
export const ConfirmAccountPage = SourceConfirmAccountPage;

// TODO: implement MenuPage
export const MenuPage = SourceMenuPage;

// TODO: implement Footer
export const Footer = SourceFooter;

// TODO: implement NavigationTabs
export const NavigationTabs = SourceNavigationTabs;

// TODO: implement NewVersionPopup
export const NewVersionPopup = SourceNewVersionPopup;

// TODO: implement NotificationList
export const NotificationList = SourceNotificationList;

// TODO: implement WishlistShared
export const WishlistShared = SourceWishlistShared;

// TODO: implement OfflineNotice
export const OfflineNotice = SourceOfflineNotice;

// TODO: implement ContactPage
export const ContactPage = SourceContactPage;

// TODO: implement ProductComparePage
export const ProductComparePage = SourceProductComparePage;

// TODO: implement CreateAccountPage
export const CreateAccountPage = SourceCreateAccountPage;

// TODO: implement LoginAccountPage
export const LoginAccountPage = SourceLoginAccountPage;

// TODO: implement ForgotPasswordPage
export const ForgotPasswordPage = SourceForgotPasswordPage;

// TODO: implement SomethingWentWrong
export const SomethingWentWrong = SourceSomethingWentWrong;

// TODO: implement StyleGuidePage
export const StyleGuidePage = SourceStyleGuidePage;

// TODO: implement withStoreRegex
export const withStoreRegex = sourceWithStoreRegex;

// eslint-disable-next-line max-len
export const CookieBotComponent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/SPCookieBot'));

/** @namespace Rapelli/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouter {
    // TODO implement logic
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NotificationList
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DemoNotice
        },
        {
            component: <Header />,
            position: 20,
            name: Header
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NavigationTabs
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NewVersionPopup
        },
        {
            component: <CookieBotComponent />,
            position: 250,
            name: COOKIEBOT
        }
    ];
}

export default RouterComponent;
