import B2bQuoteReducer from '../store/B2bQuote/B2bQuote.reducer';

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    B2bQuoteReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};
