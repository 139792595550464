/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAccountQuery from 'Query/MyAccount.query';
import {
    updateCustomerSignInStatus,
    updateIsLoading,
} from 'Store/MyAccount/MyAccount.action';
import {
    CartDispatcher,
    ProductCompareDispatcher,
    WishlistDispatcher,
} from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken,
} from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { fetchMutation, getErrorMessage } from 'Util/Request';

// Get authentication token genereted for new customers
export const getCustomerToken = (args, _callback, instance) => {
    const [options, dispatch] = args;
    const { customer: { email }, password } = options;
    const mutation = MyAccountQuery.getCreateAccountMutation(options);
    dispatch(updateIsLoading(true));

    return fetchMutation(mutation).then(
        /** @namespace Scandiweb/GoogleRecaptcha/Plugin/AutomaticLoginOnRegistration/getCustomerToken/fetchMutation/then */
        (data) => {
            const { createCustomer: { customer } } = data;
            const { confirmation_required, login_token } = customer;

            if (confirmation_required) {
                dispatch(updateIsLoading(false));

                return 2;
            }

            return instance.signIn({ email, password, login_token }, dispatch);
        },

        /** @namespace Scandiweb/GoogleRecaptcha/Plugin/AutomaticLoginOnRegistration/getCustomerToken/fetchMutation/then/catch */
        (error) => {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', getErrorMessage(error)));

            return false;
        }
    );
};

// Check if authentication token is provided.
// Otherwise creates a new login request
export const checkForAuthToken = async (args, _callback, instance) => {
    const [options, dispatch] = args;
    const {
        login_token: preAuthorizedToken,
    } = options;

    if (preAuthorizedToken) {
        setAuthorizationToken(preAuthorizedToken);
    } else {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);

        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);
    }

    ProductCompareDispatcher.then(
        ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
    );

    const cartDispatcher = (await CartDispatcher).default;
    const guestCartToken = getGuestQuoteId();
    // if customer is authorized, `createEmptyCart` mutation returns customer cart token
    const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

    if (guestCartToken && guestCartToken !== customerCartToken) {
        // merge guest cart id and customer cart id using magento capabilities
        await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
    }

    setGuestQuoteId(customerCartToken);
    cartDispatcher.updateInitialCartData(dispatch, true);

    WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    );

    await instance.requestCustomerData(dispatch);

    dispatch(updateCustomerSignInStatus(true));
    dispatch(updateIsLoading(false));
    dispatch(hideActiveOverlay());
    dispatch(showNotification('success', __('You are successfully logged in!')));

    return true;
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            createAccount: getCustomerToken,
            signIn: checkForAuthToken,
        },
    },
};
