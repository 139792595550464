import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation } from 'Util/Request';

import B2bQuotesQuery from '../../query/B2bQuotes.query';
import { clearSingleB2bQuote } from '../../store/B2bQuote/B2bQuote.action';
import { singleQuoteType } from '../../type/B2bQuote';
import QuoteViewPopup from './QuoteViewPopup.component';
import { QUOTE_VIEW_POPUPID } from './QuoteViewPopup.config';

export const B2BDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/B2bQuote/B2bQuote.dispatcher'
);

/** @namespace Screenpages/b2bquote/Component/QuoteViewPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    payload: _state.PopupReducer.popupPayload[QUOTE_VIEW_POPUPID] || {},
    QuoteData: _state.B2bQuoteReducer.singleQuoteData,
    isQuoteLoaded: _state.B2bQuoteReducer.isSingleQuoteLoaded
});

/** @namespace Screenpages/b2bquote/Component/QuoteViewPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    hideActivePopup: () => _dispatch(hideActivePopup()),
    showNotification: (type, message) => _dispatch(showNotification(type, message)),
    getSingleB2bQuote: (quote_id) => B2BDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestB2bQuoteById(_dispatch, quote_id)
    ),
    clearSingleB2bQuote: () => _dispatch(clearSingleB2bQuote())
});

/** @namespace Screenpages/b2bquote/Component/QuoteViewPopup/Container/QuoteViewPopupContainer */
export class QuoteViewPopupContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            quote_id: PropTypes.number
        }).isRequired,
        showNotification: PropTypes.func.isRequired,
        hideActivePopup: PropTypes.func.isRequired,
        reloadQuotesGrid: PropTypes.func.isRequired,
        getSingleB2bQuote: PropTypes.func.isRequired,
        QuoteData: singleQuoteType.isRequired,
        isQuoteLoaded: PropTypes.bool.isRequired,
        clearSingleB2bQuote: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleSubmitNewComment: this._handleSubmitNewComment.bind(this),
        handleCloseQuote: this._handleCloseQuote.bind(this),
        handleDeleteQuote: this._handleDeleteQuote.bind(this),
        formatDate: this._formatDate.bind(this)
    };

    componentDidUpdate(beforeProps) {
        const { payload: { quote_id } = {} } = this.props;
        const { payload: { quote_id: PrevQuoteId } = {} } = beforeProps;
        const popupOpened = PrevQuoteId === undefined && quote_id !== undefined;
        if (!this._checkCurrentQuote() || popupOpened) {
            this._getSingleQuote();
        }
    }

    containerProps = () => {
        const { QuoteData, isQuoteLoaded } = this.props;
        if (!this._checkCurrentQuote()) {
            return {
                QuoteData: {},
                isLoaded: false
            };
        }

        return {
            QuoteData,
            isLoaded: isQuoteLoaded
        };
    };

    _checkCurrentQuote() {
        const {
            payload: { quote_id: RequestedQuoteId } = {},
            QuoteData: { quote_id } = {}
        } = this.props;

        return RequestedQuoteId === quote_id;
    }

    async _getSingleQuote() {
        const { getSingleB2bQuote, payload: { quote_id } = 0 } = this.props;
        if (isSignedIn()) {
            if (quote_id > 0) {
                await getSingleB2bQuote(quote_id);
            }
        }
    }

    async _handleSubmitNewComment(newComment) {
        const { QuoteData: { quote_id } = {} } = this.props;

        const { reloadQuotesGrid, showNotification } = this.props;
        const query = B2bQuotesQuery.getAddCommentMutation(quote_id, newComment);
        const result = await fetchMutation(query);
        const { b2bUpdateQuoteComment: { success, error_messsage } = {} } = result;
        if (success) {
            await this._getSingleQuote();
            reloadQuotesGrid();
            return;
        }
        showNotification('error', error_messsage);
    }

    async _handleCloseQuote() {
        const { showNotification, hideActivePopup, reloadQuotesGrid } = this.props;
        const { QuoteData: { quote_id } = {} } = this.props;

        const query = B2bQuotesQuery.getCloseQuoteMutation(quote_id);
        const result = await fetchMutation(query);
        const { b2bCloseQuote: { success, error_message } = {} } = result;

        if (success) {
            this._getSingleQuote();
            reloadQuotesGrid();
            hideActivePopup();
            showNotification('success', __('Your quote has been closed'));
        } else {
            hideActivePopup();
            showNotification('error', error_message);
        }
    }

    async _handleDeleteQuote() {
        const {
            showNotification,
            hideActivePopup,
            reloadQuotesGrid,
            clearSingleB2bQuote
        } = this.props;
        const { QuoteData: { quote_id } = {} } = this.props;
        const query = B2bQuotesQuery.getDeleteQuoteMutation(quote_id);
        const result = await fetchMutation(query);
        const { b2bDeleteQuote: { success, error_message } = {} } = result;
        hideActivePopup();
        if (success) {
            showNotification('success', __('Your Quote has been deleted'));
            reloadQuotesGrid();
            clearSingleB2bQuote();
        } else {
            showNotification('error', error_message);
        }
    }

    _formatDate(rawDate) {
        const date = new Date(rawDate.replace(/\s/, 'T'));
        const RADIX = 10;

        const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

        const day = addLeadingZero(date.getDate());
        const month = addLeadingZero(date.getMonth() + 1);

        return `${day}.${month}.${date.getFullYear()}`;
    }

    render() {
        return (
            <QuoteViewPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteViewPopupContainer);
