import Event, { EVENT_FB_CHECKOUT } from '../util/Event';
// eslint-disable-next-line import/no-cycle
import ProductHelper from '../util/Product/ProductHelper';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 * @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/CheckoutInit/Event/CheckoutInitEvent */
export class CheckoutInitEvent extends BaseEvent {
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    bindEvent() {
        Event.observer(EVENT_FB_CHECKOUT, ({ totals, step }) => {
            this.handle(totals, step);
        });
    }

    handler(totals, step) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)
        || (Object.keys(totals).length === 0)
        || (totals.items.length === 0)) {
            return;
        }
        const checkoutStep = this.getCheckoutStep(step);

        if (
            !this.isSignedIn()
            && !this.getCustomerHasIdData()
            && checkoutStep === 'Billing'
        ) {
            this.recreateFbConversionApiObjectWithCustomerQuoteData();
        }
        const currencyCode = this.getCurrencyCode();
        const cartTotalValue = Number(totals.grand_total);
        this.addCartTotalContents(totals);
        this.facebookConversionApiCheckoutStep(
            checkoutStep,
            currencyCode,
            cartTotalValue
        );
    }

    addCartTotalContents(totals) {
        totals.items.forEach((item) => {
            this.fbConversionApiObject.addProduct(item.sku, item.qty);
        });
    }

    getCheckoutStep(step) {
        switch (step) {
        case 1:
            return 'Cart';
        case 2:
            return 'Shipping';
        case 3:
            return 'Billing';
        default:
            return this.getProps().location.pathname;
        }
    }

    getProducts({ items }) {
        const products = Object.values(items).reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: ProductHelper.getQuantity(item)
                }
            ]), []);

        return products;
    }

    /**
     * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
     * InitiateCheckout event
     * @param {*} checkoutStep
     * @param {*} currencyCode
     * @param {*} cartTotalValue
     */
    facebookConversionApiCheckoutStep(checkoutStep, currencyCode, cartTotalValue) {
        const eventId = this.generateEventId();
        this.fbConversionApiObject.sendEvent(
            'InitiateCheckout',
            checkoutStep,
            { value: cartTotalValue, currency: currencyCode },
            { eventId }
        );
    }

    recreateFbConversionApiObjectWithCustomerQuoteData() {
        this.initFacebookConversionApi();
    }
}

export default CheckoutInitEvent;
