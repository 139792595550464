import { Field } from 'Util/Query';

/** @namespace Screenpages/b2bquote/Query/B2bQuotes/Query/B2bQuotesQuery */
export class B2bQuotesQuery {
    getPlaceQuoteMutation(comment, name) {
        return new Field('b2bPlaceQuote')
            .addField(this._getQuoteField())
            .addArgument('b2bquotecomment', 'String', comment)
            .addArgument('b2bquotename', 'String', name);
    }

    getCustomerQuotes() {
        return new Field('getB2bQuoteList')
            .addFieldList(this._getQuoteGrid());
    }

    getAddCommentMutation(quote_id, comment) {
        return new Field('b2bUpdateQuoteComment')
            .addFieldList(this._getUpdateQuoteResultFields())
            .addArgument('quote_id', 'Int', quote_id)
            .addArgument('quotecomment', 'String', comment);
    }

    getCloseQuoteMutation(quote_id) {
        return new Field('b2bCloseQuote')
            .addFieldList(this._getUpdateQuoteResultFields())
            .addArgument('quote_id', 'Int', quote_id);
    }

    getDeleteQuoteMutation(quote_id) {
        return new Field('b2bDeleteQuote')
            .addFieldList(this._getUpdateQuoteResultFields())
            .addArgument('quote_id', 'Int', quote_id);
    }

    _getQuoteField() {
        return new Field('b2bquote')
            .addFieldList(['quote_id']);
    }

    _getQuoteGrid() {
        return [
            this._getQuoteGridFields()
        ];
    }

    _getQuoteGridFields() {
        return new Field('b2bquoteitems')
            .addFieldList([
                'quote_id',
                'quote_name',
                'created_at',
                'created_by',
                'status',
                'last_updated',
                'quote_total',
                'quote_currency_code'
            ]);
    }

    _getUpdateQuoteResultFields() {
        return [
            'success',
            'error_message'
        ];
    }
}
export default new B2bQuotesQuery();
