import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { QuoteCommentsType } from '../../type/B2bQuote';
import QuoteComments from './QuoteComments.component';

/** @namespace Screenpages/b2bquote/Component/QuoteComments/Container/QuoteCommentsContainer */
export class QuoteCommentsContainer extends PureComponent {
    static propTypes = {
        QuoteComments: QuoteCommentsType,
        formatDate: PropTypes.func.isRequired,
        isAbleToSubmit: PropTypes.bool.isRequired,
        handleSubmitNewComment: PropTypes.func.isRequired
    };

    static defaultProps = {
        QuoteComments: {}
    };

    state = {
        isCommentFormSubmitting: false
    };

    containerFunctions = {
        handleFormSubmit: this._handleFormSubmit.bind(this),
        handleSubmitSuccess: this._handleSubmitSuccess.bind(this),
        handleSubmitError: this._handleSubmitError.bind(this)
    };

    containerProps = () => {
        const { QuoteComments, formatDate, isAbleToSubmit } = this.props;
        const { isCommentFormSubmitting } = this.state;
        return {
            QuoteComments,
            formatDate,
            isAbleToSubmit,
            isCommentFormSubmitting
        };
    };

    _handleFormSubmit() {
        this.setState({ isCommentFormSubmitting: true });
    }

    _handleSubmitSuccess(fields) {
        const { quotecomment } = fields;
        const { handleSubmitNewComment } = this.props;
        handleSubmitNewComment(quotecomment);
    }

    _handleSubmitError() {
        this.setState({ isCommentFormSubmitting: false });
    }

    render() {
        return (
            <QuoteComments
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default QuoteCommentsContainer;
