import {
    ACCOUNT_FORGOT_PASSWORD as SOURCE_ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE as SOURCE_AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE as SOURCE_BEFORE_ITEMS_TYPE,
    BREADCRUMBS as SOURCE_BREADCRUMBS,
    CART as SOURCE_CART,
    CHANGE_PASSWORD as SOURCE_CHANGE_PASSWORD,
    CHECKOUT as SOURCE_CHECKOUT,
    CMS_PAGE as SOURCE_CMS_PAGE,
    COMPARE as SOURCE_COMPARE,
    CONFIRM_ACCOUNT as SOURCE_CONFIRM_ACCOUNT,
    CONTACT_PAGE as SOURCE_CONTACT_PAGE,
    COOKIE_POPUP as SOURCE_COOKIE_POPUP,
    CREATE_ACCOUNT as SOURCE_CREATE_ACCOUNT,
    DEMO_NOTICE as SOURCE_DEMO_NOTICE,
    FOOTER as SOURCE_FOOTER,
    FORGOT_PASSWORD as SOURCE_FORGOT_PASSWORD,
    HEADER as SOURCE_HEADER,
    HOME as SOURCE_HOME,
    LOGIN as SOURCE_LOGIN,
    MENU as SOURCE_MENU,
    MY_ACCOUNT as SOURCE_MY_ACCOUNT,
    NAVIGATION_TABS as SOURCE_NAVIGATION_TABS,
    NEW_VERSION_POPUP as SOURCE_NEW_VERSION_POPUP,
    NOTIFICATION_LIST as SOURCE_NOTIFICATION_LIST,
    SEARCH as SOURCE_SEARCH,
    SHARED_WISHLIST as SOURCE_SHARED_WISHLIST,
    STYLE_GUIDE as SOURCE_STYLE_GUIDE,
    SWITCH_ITEMS_TYPE as SOURCE_SWITCH_ITEMS_TYPE,
    URL_REWRITES as SOURCE_URL_REWRITES
} from 'SourceComponent/Router/Router.config';

// TODO: implement BEFORE_ITEMS_TYPE
export const BEFORE_ITEMS_TYPE = SOURCE_BEFORE_ITEMS_TYPE;

// TODO: implement SWITCH_ITEMS_TYPE
export const SWITCH_ITEMS_TYPE = SOURCE_SWITCH_ITEMS_TYPE;

// TODO: implement AFTER_ITEMS_TYPE
export const AFTER_ITEMS_TYPE = SOURCE_AFTER_ITEMS_TYPE;

// TODO: implement NOTIFICATION_LIST
export const NOTIFICATION_LIST = SOURCE_NOTIFICATION_LIST;

// TODO: implement NAVIGATION_TABS
export const NAVIGATION_TABS = SOURCE_NAVIGATION_TABS;

// TODO: implement DEMO_NOTICE
export const DEMO_NOTICE = SOURCE_DEMO_NOTICE;

// TODO: implement HEADER
export const HEADER = SOURCE_HEADER;

// TODO: implement BREADCRUMBS
export const BREADCRUMBS = SOURCE_BREADCRUMBS;

// TODO: implement NEW_VERSION_POPUP
export const NEW_VERSION_POPUP = SOURCE_NEW_VERSION_POPUP;

// TODO: implement HOME
export const HOME = SOURCE_HOME;

// TODO: implement SEARCH
export const SEARCH = SOURCE_SEARCH;

// TODO: implement CMS_PAGE
export const CMS_PAGE = SOURCE_CMS_PAGE;

// TODO: implement CART
export const CART = SOURCE_CART;

// TODO: implement CHECKOUT
export const CHECKOUT = SOURCE_CHECKOUT;

// TODO: implement CHANGE_PASSWORD
export const CHANGE_PASSWORD = SOURCE_CHANGE_PASSWORD;

// TODO: implement CREATE_ACCOUNT
export const CREATE_ACCOUNT = SOURCE_CREATE_ACCOUNT;

// TODO: implement LOGIN
export const LOGIN = SOURCE_LOGIN;

// TODO: implement ACCOUNT_FORGOT_PASSWORD
export const ACCOUNT_FORGOT_PASSWORD = SOURCE_ACCOUNT_FORGOT_PASSWORD;

// TODO: implement FORGOT_PASSWORD
export const FORGOT_PASSWORD = SOURCE_FORGOT_PASSWORD;

// TODO: implement CONFIRM_ACCOUNT
export const CONFIRM_ACCOUNT = SOURCE_CONFIRM_ACCOUNT;

// TODO: implement MY_ACCOUNT
export const MY_ACCOUNT = SOURCE_MY_ACCOUNT;

// TODO: implement MENU
export const MENU = SOURCE_MENU;

// TODO: implement SHARED_WISHLIST
export const SHARED_WISHLIST = SOURCE_SHARED_WISHLIST;

// TODO: implement CONTACT_PAGE
export const CONTACT_PAGE = SOURCE_CONTACT_PAGE;

// TODO: implement COMPARE
export const COMPARE = SOURCE_COMPARE;

// TODO: implement STYLE_GUIDE
export const STYLE_GUIDE = SOURCE_STYLE_GUIDE;

// TODO: implement URL_REWRITES
export const URL_REWRITES = SOURCE_URL_REWRITES;

// TODO: implement FOOTER
export const FOOTER = SOURCE_FOOTER;

// TODO: implement COOKIE_POPUP
export const COOKIE_POPUP = SOURCE_COOKIE_POPUP;

export const COOKIEBOT = 'COOKIEBOT';
