/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { B2B_CHECKOUT_URL } from '../route/B2bCheckout/B2bCheckout.config';

export const B2bCheckout = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ '../route/B2bCheckout')
);

/** @namespace Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export class RoutePlugin {
    aroundSwitchItemsType = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`${ B2B_CHECKOUT_URL }/:step?`) } render={ (props) => <B2bCheckout { ...props } /> } />,
            position: 100
        }
    ];

    addB2bCheckoutToRouteMap = (member) => ({
        ...member,
        '/b2bcheckout': { name: 'B2BCHECKOUT_TAB', isHidden: true }
    });
}

const { addB2bCheckoutToRouteMap, aroundSwitchItemsType } = new RoutePlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: [
                {
                    position: 100,
                    implementation: aroundSwitchItemsType
                }
            ]
        }
    },
    'Component/NavigationTabsContainer/Container': {
        'member-property': {
            routeMap: addB2bCheckoutToRouteMap
        }
    }
};

export default config;
