import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CompanyUserGridUserType } from '../../type/B2bCompany';

import './CompanyUsersRow.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyUsersRow/Component/CompanyUsersRowComponent */
export class CompanyUsersRowComponent extends PureComponent {
    static propTypes = {
        CompanyUserData: CompanyUserGridUserType.isRequired, // TODO check prop types
        editCurrentUser: PropTypes.func.isRequired
    };

    renderSingleResult() {
        const {
            editCurrentUser,
            CompanyUserData: {
                entity_id,
                firstname,
                lastname,
                email,
                role_name,
                status
            } = {}
        } = this.props;

        return (
            <tr
              block="CompanyUsersRow"
              elem="ResultItem"
              mods={ { status } }
              key={ entity_id }
              onClick={ editCurrentUser }
              onKeyDown={ editCurrentUser }
              role="button"
              tabIndex={ 0 }
            >
                <td>{ `${firstname } ${ lastname}` }</td>
                <td>{ email }</td>
                <td>{ role_name }</td>
            </tr>
        );

        // return (
        //   <div
        //     block="CompanyUsersRow"
        //     elem="ResultItem"
        //     mods={ { status } }
        //     key={ entity_id }
        //     onClick={ editCurrentUser }
        //     onKeyDown={ editCurrentUser }
        //     role="button"
        //     tabIndex={ 0 }
        //   >
        //       <span>
        //           { `${firstname } ${ lastname}` }
        //       </span>
        //       <span>
        //           { email }
        //       </span>
        //       <span>
        //           { role_name }
        //       </span>
        //   </div>
        // );
    }

    render() {
        return (
            this.renderSingleResult()
        );
    }
}

export default CompanyUsersRowComponent;
