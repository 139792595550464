// import PropTypes from 'prop-types';
import { de, fr, it } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import Field from 'Component/Field/Field.container';
import getStore from 'Util/Store';

import 'react-datepicker/dist/react-datepicker.css';
import './DeliverySlot.style';

registerLocale('de', de);
registerLocale('it', it);
registerLocale('fr', fr);

/** @namespace Screenpages/Planzerpaket/Component/DeliverySlot/Component/DeliverySlotComponent */
export class DeliverySlotComponent extends PureComponent {
    static propTypes = {
        getIncludedDates: PropTypes.func.isRequired,
        showDatePicker: PropTypes.func.isRequired,
        isPostcodeValid: PropTypes.func.isRequired,
        onDateSelected: PropTypes.func.isRequired,
        setTimeSelected: PropTypes.func.isRequired,
        getTimeSelectOptions: PropTypes.func.isRequired,
        availableTimes: PropTypes.arrayOf(PropTypes.object),
        deliveryDate: PropTypes.instanceOf(Date),
        deliveryTime: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        availableTimes: [],
        deliveryDate: null,
        deliveryTime: {}
    };

    renderDatePicker() {
        const {
            getIncludedDates, showDatePicker, isPostcodeValid, onDateSelected, deliveryDate
        } = this.props;
        const includedDates = getIncludedDates();
        const store = getStore();
        const {
            ConfigReducer: { code: storeCode } = {}
        } = store.getState();

        if (!deliveryDate && includedDates.length) {
            onDateSelected(includedDates[0]);
        }
        if (showDatePicker() && isPostcodeValid()) {
            return (
                <div className="Field Field_isValid">
                    <div className="Field-LabelContainer">
                        <label htmlFor="timeslot" className="Field-Label">{ __('Delivery Date') }</label>
                    </div>
                    <DatePicker
                      locale={ storeCode }
                      inline
                      includeDates={ includedDates }
                      selected={ deliveryDate }
                      onChange={ onDateSelected }
                    />
                </div>
            );
        // eslint-disable-next-line no-else-return
        } else if (showDatePicker() && !isPostcodeValid()) {
            return null;
        }

        return null;
    }

    renderTimesChoices() {
        const {
            availableTimes, showDatePicker, isPostcodeValid, getTimeSelectOptions, setTimeSelected
        } = this.props;

        if (showDatePicker() && isPostcodeValid()) {
            const options = getTimeSelectOptions(availableTimes).map((time) => (
                <button
                  block="DeliverySlot"
                  elem="Button"
                  type="button"
                  key={ `DeliverySlot-Input-${time.id}` }
                >
                    <Field
                      type="checkbox"
                      id={ `DeliverySlot-Input-${time.id}` }
                      name={ time.label }
                      value={ time.value }
                      label={ time.label }
                      onChange={ setTimeSelected }
                      checked={ time.selected }
                    />
                      <div
                        block="DeliverySlot"
                        elem="OptionLabel"
                      >
                          { time.surcharge }
                      </div>
                </button>
            ));

            return options;
        }

        return null;
    }

    render() {
        return (
            <div className="DeliverySlot FieldForm">
                { this.renderDatePicker() }
                { this.renderTimesChoices() }
            </div>
        );
    }
}

export default DeliverySlotComponent;
