import {
    ANIMATION_DURATION as SOURCE_ANIMATION_DURATION,
    // ERROR_NOTIFICATION_LIFETIME as SOURCE_ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE as SOURCE_ERROR_TYPE
    // NOTIFICATION_LIFETIME as SOURCE_NOTIFICATION_LIFETIME
} from 'SourceComponent/Notification/Notification.config';

// TODO: implement ANIMATION_DURATION
export const ANIMATION_DURATION = SOURCE_ANIMATION_DURATION;

// TODO: implement NOTIFICATION_LIFETIME
export const NOTIFICATION_LIFETIME = 4000;

// TODO: implement ERROR_NOTIFICATION_LIFETIME
export const ERROR_NOTIFICATION_LIFETIME = 4000;

// TODO: implement ERROR_TYPE
export const ERROR_TYPE = SOURCE_ERROR_TYPE;

export const ADDTOCART_TYPE = 'addtocart';
export const ADDTOCART_LIFETIME = 5000;
