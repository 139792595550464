import { OrderQuery as RawOrderQuery } from 'Query/Order.query';
import { Field } from 'Util/Query';

/** @namespace Screenpages/Multichannelorder/Query/Order/Query/OrderQuery */
export class OrderQuery extends RawOrderQuery {
    getMultichannelOrderById(orderId) {
        return new Field('getMultichannelOrderById')
            .addArgument('id', 'String!', orderId)
            .addFieldList(this._getOrderItemsFields());
    }

    // _getOrderProductsFields() {
    //     return this._getDefaultFields();
    // }

    getOrderListQueryWithDates(dateFrom) {
        return new Field('getMultichannelOrderList')
            .setAlias('getOrderList')
            .addArgument('from_date', 'String', dateFrom)
            .addFieldList(this._getOrderListFields(true));
    }

    _getBaseOrderInfoFields(isList) {
        return [
            ...super._getBaseOrderInfoFields(isList),
            'multichannel_order'
        ];
    }

    _getDefaultFields(isList) {
        return [
            ...super._getDefaultFields(isList),
            'promotional_code'
        ];
    }
}

export default new OrderQuery();
