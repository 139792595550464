import {
    getInitialState as sourceGetInitialState,
    MyAccountReducer as SourceMyAccountReducer
} from 'SourceStore/MyAccount/MyAccount.reducer';

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement MyAccountReducer
export const MyAccountReducer = SourceMyAccountReducer;

export default MyAccountReducer;
