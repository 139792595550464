/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const RECAPTCHA_API_URL = 'https://www.google.com/recaptcha/api.js';
export const RECAPTCHA_API_PARAMS = '?onload=recaptchaOnLoad&render=explicit';
export const RECAPTCHA_SCRIPT_ID = 'recaptcha_script';

export const CUSTOMER_LOGIN = {
    id: 'CUSTOMER_LOGIN',
    pos: 2,
};
export const FORGOT_PASSWORD = {
    id: 'FORGOT_PASSWORD',
    pos: 0,
};
export const NEW_CUSTOMER_ACCOUNT = {
    id: 'NEW_CUSTOMER_ACCOUNT',
    pos: 2,
};
export const EDIT_CUSTOMER_ACCOUNT = {
    id: 'EDIT_CUSTOMER_ACCOUNT',
    pos: 0,
};
export const PRODUCT_REVIEW = {
    id: 'PRODUCT_REVIEW',
    pos: 1,
};
export const NEWSLETTER = {
    id: 'NEWSLETTER',
    pos: 0,
};
export const CHECKOUT = {
    id: 'CHECKOUT',
    pos: 3,
};

export const V2_CHECKBOX = 'V2_CHECKBOX';
export const V2_INVISIBLE = 'V2_INVISIBLE';
export const V3_INVISIBLE = 'V3_INVISIBLE';

export const RECAPTCHA_TYPES_MAP = {
    [V2_CHECKBOX]: 'recaptcha',
    [V2_INVISIBLE]: 'invisible',
    [V3_INVISIBLE]: 'recaptcha_v3',
};

export const RECAPTCHA_HTTP_HEADER = 'X-ReCaptcha';

export const RECAPTCHA_LOCATION = 'recaptcha_location';
export const RECAPTCHA_TOKENS = 'recaptcha_tokens';
