import { isSignedIn } from 'Util/Auth';

import { getSharedCatalogId } from '../util/SharedCatalog';

export class RequestPlugin {
    AddCompanyToUrl= (args, callback, obj) => {
        if (isSignedIn()) {
            const query = args[0];
            const variables = args[1];
            const url = args[2];
            const sharedCatalogId = getSharedCatalogId();
            variables.gid = sharedCatalogId;
            const newArgs = [query, variables, url];
            return callback.apply(obj, newArgs);
        }

        return callback.apply(obj, args);
    };
}

const { AddCompanyToUrl } = new RequestPlugin();

export default {
    'Util/Request/formatURI': {
        function: AddCompanyToUrl
    }
};
