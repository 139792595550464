import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { MPProductLabelType } from '../../type/MPProductLabel';
import ProductLabel from '../ProductLabel';

// import './ProductLabelWrapper.style';

/**
 * I needed to use a combintaion of render logic and component logic to handle the createRef logic.
 * This filename method bypasses the Scandi ESLint rules and saves on file loada and easier to read in 1 file
 * At the time I had an issue with createRef loading correclty for Product Page, not category page
 * * The height val is set to force the chilld component to re-render if the height change
 * (whe the object had loaded)
 * ReactJS PureComponents automatically re-render on shallowEqual checks of props and state
 * Also added resize to the window to force a re-render of the child component to reposition itself
*/

/** @namespace Screenpages/Mpproductlabel/Component/ProductLabelWrapper/ProductLabelWrapper */
export class ProductLabelWrapper extends PureComponent {
    static propTypes = {
        ProductLabelData: MPProductLabelType,
        isProductPage: PropTypes.bool
    };

    static defaultProps = {
        ProductLabelData: {},
        isProductPage: false
    };

    state = {
        height: 0,
        width: 0
    };

    ProductLabelRef = createRef();

    componentDidMount() {
        const { ProductLabelData } = this.props;
        const hasLabels = ProductLabelData.length > 0;

        if (hasLabels && this.ProductLabelRef.current) {
            this.updateHeight();
        }
        window.addEventListener('resize', this.updateHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeight);
    }

    __construct(props) {
        super.__construct(props);
        // this.ProductLabelRef = createRef();
        this.state = {
            height: 0,
            width: 0
        };
    }

    compoendidDidUpdate() {
        const { height } = this.state;
        if (height === 0) {
            this.updateHeight();
        }
    }

    updateHeight = () => {
        if (!this.ProductLabelRef.current) {
            return;
        }
        const isGallery = this.ProductLabelRef.current.parentElement.classList.contains('ProductGallery');

        if (isGallery) {
            const SliderHeight = this.ProductLabelRef.current.parentElement
                .querySelector('.ProductGallery-SliderWrapper')
                .getBoundingClientRect().height;
            const SliderWidth = this.ProductLabelRef.current.parentElement
                .querySelector('.ProductGallery-SliderWrapper')
                .getBoundingClientRect().width;

            this.setState({
                height: SliderHeight,
                width: SliderWidth
            });
        } else {
            const newHeight = this.ProductLabelRef.current.parentElement.getBoundingClientRect().height;
            const newWidth = this.ProductLabelRef.current.parentElement.getBoundingClientRect().width;
            this.setState({
                height: newHeight,
                width: newWidth
            });
        }
    };

    renderLabel(Label, Key) {
        const { isProductPage } = this.props;
        const { height, width } = this.state;
        const { from_date, to_date } = Label;
        const style = {
            position: 'absolute',
            zIndex: 2
            // width,
            // height
        };

        /* compare dates to ensure labels are dislayed between from/to dates */
        let { enabled } = Label;
        let today = new Date().setHours(0, 0, 0, 0);
        if (from_date && today < new Date(from_date).setHours(0, 0, 0, 0)) {
            enabled = false;
        }
        if (to_date && today > new Date(to_date).setHours(0, 0, 0, 0)) {
            enabled = false;
        }

        return enabled
            ? (
                <div
                  block="ProductLabelWrapper"
                  key={ Key }
                  ref={ this.ProductLabelRef }
                  style={ style }
                >
                    <ProductLabel
                      SingleProductLabel={ Label }
                      isProductPage={ isProductPage }
                      lblContainerHeight={ height }
                      lblContainerWidth={ width }
                    />
                </div>
            )
            : null;
    }

    render() {
        const { ProductLabelData } = this.props;
        const hasLabels = ProductLabelData.length > 0;

        return hasLabels
            ? ProductLabelData.map((label, index) => this.renderLabel(label, index))
            : null;
    }
}
export default ProductLabelWrapper;
