/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import CookieBot from 'react-cookiebot/lib/CookieBot';

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import history from 'Util/History';

import './App.override.style';

/** @namespace Rapelli/Component/App/Component/AppComponent */
export class AppComponent extends SourceApp {
    // TODO implement logic
    componentDidMount() {
        // eslint-disable-next-line fp/no-let
        let pageViewed = history.location.pathname;

        // track subsequent page change views
        this.unlisten = history.listen(() => {
            if (typeof window.dmPt === 'function') {
                if (pageViewed !== history.location.pathname) {
                    window.dmPt('track');
                    if (isSignedIn()) {
                        const { email } = BrowserDatabase.getItem(CUSTOMER) || {};
                        window.dmPt('identify', email);
                    }
                    pageViewed = history.location.pathname;
                }
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }
    // rootComponents = [
    //     this.renderRouter.bind(this),
    //     this.renderSharedTransition.bind(this),
    //     this.renderCookieBot.bind(this)
    // ];

    // renderCookieBot() {
    //     const domainGroupId = 'e472e717-4a45-4b19-8d9b-ab7aaaf83257';
    //     return (
    //         <CookieBot domainGroupId={ domainGroupId } />
    //     );
    // }
}

export default AppComponent;
