import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CartItemType } from 'Type/MiniCart';
import { CONFIGURABLE } from 'Util/Product';
import { objectToUri } from 'Util/Url';

import QuoteViewItem from './QuoteViewItem.component';

/** @namespace Screenpages/b2bquote/Component/QuoteViewItem/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Screenpages/b2bquote/Component/QuoteViewItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Screenpages/b2bquote/Component/QuoteViewItem/Container/QuoteViewItemContainer */
export class QuoteViewItemContainer extends PureComponent {
    static propTypes = {
        QuoteItem: CartItemType.isRequired,
        CurrencyCode: PropTypes.string.isRequired
    };

    containerFunctions = {
        getCurrentProduct: this._getCurrentProduct.bind(this)
    };

    containerProps = () => {
        const { QuoteItem, CurrencyCode } = this.props;
        return {
            QuoteItem,
            CurrencyCode,
            linkTo: this._getProductLinkTo()
        };
        // isDisabled: this._getIsDisabled()
    };

    _getCurrentProduct() {
        const { QuoteItem: { product } } = this.props;
        const variantIndex = this._getVariantIndex();
        return variantIndex < 0 ? product : product.variants[variantIndex];
    }

    _getVariantIndex() {
        const {
            QuoteItem: {
                sku: itemSku,
                product: { variants = [] } = {}
            }
        } = this.props;

        return variants.findIndex(({ sku }) => sku === itemSku || itemSku.includes(sku));
    }

    _getProductLinkTo() {
        const {
            QuoteItem: {
                product,
                product: {
                    type_id,
                    configurable_options,
                    parent,
                    url
                } = {}
            } = {}
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return {
                pathname: url,
                state: { product }
            };
        }

        const variant = this._getCurrentProduct();
        if (!variant) {
            return {};
        }
        const { attributes } = variant;

        const parameters = Object.entries(attributes).reduce(
            (parameters, [code, { attribute_value }]) => {
                if (Object.keys(configurable_options).includes(code)) {
                    return { ...parameters, [code]: attribute_value };
                }

                return parameters;
            }, {}
        );

        const stateProduct = parent || product;

        return {
            pathname: url,
            state: { product: stateProduct },
            search: objectToUri(parameters)
        };
    }

    render() {
        return (
            <QuoteViewItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteViewItemContainer);
