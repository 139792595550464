import PropTypes from 'prop-types';

export const MPPSingleProductLabelType = PropTypes.shape({
    bestseller: PropTypes.number,
    conditions_serialized: PropTypes.string,
    created_at: PropTypes.string,
    customer_group_ids: PropTypes.string,
    enabled: PropTypes.number,
    from_date: PropTypes.string,
    label: PropTypes.string,
    label_color: PropTypes.string,
    label_css: PropTypes.string,
    label_font: PropTypes.string,
    label_font_size: PropTypes.string,
    label_image: PropTypes.string,
    label_position: PropTypes.string,
    label_position_grid: PropTypes.string,
    label_template: PropTypes.string,
    limit: PropTypes.string,
    list_color: PropTypes.string,
    list_css: PropTypes.string,
    list_font: PropTypes.string,
    list_font_size: PropTypes.string,
    list_image: PropTypes.string,
    list_label: PropTypes.string,
    list_position: PropTypes.string,
    list_position_grid: PropTypes.string,
    list_template: PropTypes.string,
    name: PropTypes.string,
    priority: PropTypes.string,
    rule_id: PropTypes.number,
    same: PropTypes.number,
    stop_process: PropTypes.string,
    store_ids: PropTypes.string,
    to_date: PropTypes.string,
    updated_at: PropTypes.string
});

export const MPProductLabelType = PropTypes.arrayOf(MPPSingleProductLabelType);

export const MPLabelStylesType = PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    left: PropTypes.string,
    top: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    position: PropTypes.string
});
