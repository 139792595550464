import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { customerType } from 'Type/Account';

// import { getOrderList } from 'Store/Order/Order.action';
import MultichannelOrderToolbar from './MultichannelOrderToolbar.component';
import { DEFAULT_DATE_NUM_MONTHS } from './MultichannelOrderToolbar.config';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Order/Order.dispatcher'
);
/** @namespace Screenpages/Multichannelorder/Component/MultichannelOrderToolbar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Screenpages/Multichannelorder/Component/MultichannelOrderToolbar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
    getOrderList: (from_date) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestOrdersWithDates(from_date, dispatch)
    )
});

/** @namespace Screenpages/Multichannelorder/Component/MultichannelOrderToolbar/Container/MultichannelOrderToolbarContainer */
export class MultichannelOrderToolbarContainer extends PureComponent {
    static propTypes = {
        getOrderList: PropTypes.func.isRequired,
        customer: customerType
    };

    static defaultProps = {
        customer: {}
    };

    containerFunctions = {
        handleSubmit: this._handleSubmit.bind(this),
        handleSubmitSuccess: this._handlSubmitSuccess.bind(this),
        handleSubmitError: this._handleSubmitError.bind(this)
    };

    _handleSubmit() {
        //
    }

    _handlSubmitSuccess(fields) {
        //
        const { month, year } = fields;
        const fromDate = new Date(year, month, 2);
        const { getOrderList } = this.props;
        getOrderList(fromDate);
    }

    _handleSubmitError(_fields) {
        //
    }

    containerProps = () => {
        const {
            customer: {
                canUserRequestMultichannelOrders
            } = {}
        } = this.props;

        return {
            monthsArray: this.getMonthsArray(),
            yearsArray: this.getYearsArray(),
            isRequestEnabled: canUserRequestMultichannelOrders,
            defaultDate: this.getDefaultFromDate()
        };
    };

    getMonthsArray() {
        const months = [];
        const date = new Date();
        // eslint-disable-next-line fp/no-let, no-magic-numbers
        for (let i = 0; i < 12; i++) {
            date.setMonth(i);
            months.push({
                id: i,
                label: date.toLocaleString('en-GB', { month: 'long' }),
                value: date.getMonth(),
                sort_order: date.getMonth() + 1
            });
        }

        return months;
    }

    getYearsArray() {
        const years = [];
        // get current year
        const currentYear = new Date().getFullYear();
        // loop current year - 5 to current year + 5

        // eslint-disable-next-line fp/no-let
        let cnt = 0;
        // eslint-disable-next-line fp/no-let, no-magic-numbers
        for (let i = 2021; i <= currentYear; i++) {
            // label should be string of i
            years.push({
                id: cnt++,
                label: i.toString(),
                value: i
            });
        }

        return years;
    }

    getDefaultFromDate() {
        const date = new Date();
        // eslint-disable-next-line fp/no-mutation
        date.setMonth(date.getMonth() - DEFAULT_DATE_NUM_MONTHS);
        return date;
    }

    render() {
        return (
            <MultichannelOrderToolbar
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultichannelOrderToolbarContainer);
