/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addressType } from 'Type/Account';
import { formatPrice } from 'Util/Price';

import DeliverySlot from './DeliverySlot.component';

/** @namespace Screenpages/Planzerpaket/Component/DeliverySlot/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    currencyData: state.ConfigReducer.currencyData,
    storeCode: state.ConfigReducer.code
});

/** @namespace Screenpages/Planzerpaket/Component/DeliverySlot/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Screenpages/Planzerpaket/Component/DeliverySlot/Container/DeliverySlotContainer */
export class DeliverySlotContainer extends PureComponent {
    static propTypes = {
        shippingMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        selectedShippingMethod: PropTypes.object.isRequired,
        estimateAddress: addressType.isRequired,
        currencyCode: PropTypes.string,
        saveDeliverySlot: PropTypes.func.isRequired,
        handleDeliverySlotChange: PropTypes.func.isRequired,
        deliveryDate: PropTypes.instanceOf(Date),
        // eslint-disable-next-line react/forbid-prop-types
        deliveryTime: PropTypes.object,
        deliverySurcharge: PropTypes.number,
        storeCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        deliverySurcharge: 0,
        deliveryDate: {},
        deliveryTime: {}
    };

    containerFunctions = {
        getDeliverySlotData: this.getDeliverySlotData.bind(this),
        getIncludedDates: this.getIncludedDates.bind(this),
        showDatePicker: this.showDatePicker.bind(this),
        isPostcodeValid: this.isPostcodeValid.bind(this),
        onDateSelected: this.onDateSelected.bind(this),
        setDateSelected: this.setDateSelected.bind(this),
        setTimeSelected: this.setTimeSelected.bind(this),
        getTimeSelectOptions: this.getTimeSelectOptions.bind(this)
    };

    state = {
        availableTimes: [],
        availableDates: this.getIncludedDates()
    };

    static defaultProps = {
        currencyCode: ''
    };

    containerProps() {
        const {
            shippingMethods,
            selectedShippingMethod,
            estimateAddress,
            currencyCode,
            deliveryTime,
            deliveryDate,
            deliverySurcharge
        } = this.props;
        const {
            availableTimes, availableDates
        } = this.state;

        return {
            shippingMethods,
            selectedShippingMethod,
            estimateAddress,
            availableTimes,
            availableDates,
            deliveryTime,
            deliveryDate,
            deliverySurcharge,
            currencyCode
        };
    }

    getDeliverySlotData() {
        const { shippingMethods } = this.props;

        // eslint-disable-next-line max-len
        const deliverySlotData = shippingMethods.find((shippingMethod) => shippingMethod.method_code === 'planzerpaket');

        return deliverySlotData ? deliverySlotData.delivery_dates : [];
    }

    getIncludedDates() {
        const methodDates = this.getDeliverySlotData();
        const includedDates = methodDates.map(
            (methodDate) => new Date(methodDate.deliveryDate)
        );

        return includedDates ?? [];
    }

    getExtraDataForDate(date) {
        const slots = this.getDeliverySlotData();
        const data = slots.filter((slot) => slot.deliveryDate === date);
        return data[0] ?? false;
    }

    getTimeSlotTitle(timeslot) {
        switch (timeslot) {
        case '08:00 - 17:00':
            return __('In the day');
        case '07:00 - 08:00':
            return __('Early Bird');
        case '07:00 - 10:00':
            return __('Express');
        case '07:00 - 12:00':
            return __('Morning');
        case '08:00 - 12:00':
            return __('Morning');
        default:
            return 'Standard';
        }
    }

    getFormatedTimeSlotCost(surcharge) {
        const { currencyCode } = this.props;
        switch (surcharge) {
        case '0.00':
            return __('Free delivery');
        case '10.00':
            return `${__('Supplement of')} ${ formatPrice(surcharge, currencyCode) }`;
        case '15.00':
            return `${__('Supplement of')} ${ formatPrice(surcharge, currencyCode) }`;
        case '20.00':
            return `${__('Supplement of')} ${ formatPrice(surcharge, currencyCode) }`;
        default:
            return `${__('Supplement of')} ${ formatPrice(surcharge, currencyCode) }`;
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getFormattedTimeSlot(timeSlot) {
        const { storeCode } = this.props;
        const [fromhrs, frommin] = timeSlot.from.split(':');
        const [tohrs, tomin] = timeSlot.to.split(':');
        const timeSlotTitle = this.getTimeSlotTitle(`${ fromhrs }:${frommin} - ${tohrs}:${tomin}`);
        const hours = __('hours');
        if (storeCode.includes('de') || storeCode.includes('fr')) {
            return `${ timeSlotTitle } (${ fromhrs }-${ tohrs } ${ hours })`;
        }

        return `${ timeSlotTitle } (${ hours } ${ fromhrs }-${tohrs})`;

        // const {
        //     ConfigReducer: { code: storeCode } = {}
        // } = store.getState();
    }

    getTimeSelectOptions(options) {
        /* Sort order is defined by order of array, by default from lowest to highest surcharge options */
        const { deliveryDate } = this.props;
        if (deliveryDate) {
            return options.map((option, index) => (
                {
                    id: index,
                    label: this.getFormattedTimeSlot(option),
                    value: JSON.stringify(option),
                    sort_order: index + 1,
                    surcharge: this.getFormatedTimeSlotCost(option.surcharge),
                    selected: option.selected
                }
            ));
        }

        return [
            {
                id: -1,
                label: 'Please select a date first',
                value: 'test',
                surcharge: __('Free delivery')
            }
        ];
    }

    isPostcodeValid() {
        const { estimateAddress } = this.props;
        return estimateAddress.postcode.length > 0;
    }

    showDatePicker() {
        const { selectedShippingMethod } = this.props;
        return selectedShippingMethod.method_code === 'planzerpaket';
    }

    setDateSelected(date) {
        const { handleDeliverySlotChange } = this.props;
        handleDeliverySlotChange({ deliveryDate: date });
    }

    setTimeSelected(time) {
        const { handleDeliverySlotChange } = this.props;
        const deliveryTime = (typeof (time) === 'string') ? JSON.parse(time) : time;
        this.updateChecked(deliveryTime);

        handleDeliverySlotChange({
            deliveryTime,
            planzerDeliverySurcharge: parseFloat(deliveryTime.surcharge)
        });
    }

    getAvailableTimes() {
        return this._availableTimes;
    }

    onDateSelected(date) {
        const { handleDeliverySlotChange } = this.props;
        const offset = date.getTimezoneOffset();
        // eslint-disable-next-line no-magic-numbers
        const zuludate = new Date(date.getTime() - (offset * 60 * 1000));
        const datekey = zuludate.toISOString().split('T')[0];

        const extraData = this.getExtraDataForDate(datekey);
        const timeSlots = this.setDefaultChecked(extraData.timeSlots);

        this.setState({
            availableTimes: timeSlots
        });
        handleDeliverySlotChange({
            deliveryDate: date,
            deliveryTime: timeSlots[0]
        });
        this.setTimeSelected(JSON.stringify(timeSlots[0]));
    }

    setDefaultChecked(timeslots) {
        return timeslots.map((timeslot, index) => ({ ...timeslot, selected: (!index) }));
    }

    updateChecked(selectedTimeSlot) {
        const selectedTime = (typeof (selectedTimeSlot) === 'string') ? JSON.parse(selectedTimeSlot) : selectedTimeSlot;
        if (!selectedTime.selected) {
            const { availableTimes } = this.state;
            const updateSelected = availableTimes.map((availableTimeSlot) => (
                {
                    ...availableTimeSlot,
                    selected: (availableTimeSlot.to === selectedTime.to && availableTimeSlot.from === selectedTime.from)
                }
            ));

            this.setState({ availableTimes: updateSelected });
        }
    }

    render() {
        return (
            <DeliverySlot
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySlotContainer);
