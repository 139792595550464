/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import AddressSearch from 'react-loqate';

import { FieldInput as SourceFieldInput } from 'SourceComponent/FieldInput/FieldInput.component';
import getStore from 'Util/Store';

import 'react-loqate/dist/react-loqate.cjs.development.css';
import './FieldInput.override.style';

/** @namespace Rapelli/Component/FieldInput/Component/FieldInputComponent */
export class FieldInputComponent extends SourceFieldInput {
    // TODO implement logic
    renderDefaultField() {
        const {
            formRef,
            ...validProps
        } = this.props;

        return (
        <input
          ref={ formRef }
          { ...validProps }
        />
        );
    }

    renderSearchField(props) {
        const {
            formRef,
            placeholder,
            validateSeparately,
            onChange,
            isSearchTrigered,
            value,
            id
        } = this.props;

        const isDropdownOpen = () => {
            const items = document.getElementsByClassName('react-loqate-list-item');
            return !!items.length;
        };

        const delay = 300;
        const blurHandler = () => {
            if (!formRef?.current?.value || isDropdownOpen()) {
                return;
            }
            if (!value && !isSearchTrigered) {
                onChange(formRef?.current?.value);
            }
        };
        const tabPressHandler = (e) => {
            if (e.key === 'Tab') {
                if ((id !== 'street0' || !formRef.current.value) && (id !== 'street1' || !formRef.current.value)) {
                    e.preventDefault();
                }
                onChange(formRef?.current?.value);
                const nodesArr = Array.from(formRef.current.form);
                const currNodeEl = nodesArr.find((item) => item.id === id);
                const currNodeIndex = nodesArr.indexOf(currNodeEl);
                formRef.current.form[currNodeIndex + 1].focus();
            }
        };

        return (
            <input
              ref={ formRef }
              value={ value }
              id={ id }
              onBlur={ () => setTimeout(blurHandler, delay) }
              onKeyDown={ tabPressHandler }
              placeholder={ placeholder }
              validateSeparately={ validateSeparately }
              { ...props }
            />
        );
    }

    renderAddressSearch() {
        const { onPostCodeChange, loqateAccountCode } = this.props;
        const currentLocaleCode = getStore().getState()?.ConfigReducer?.code;
        const localVariants = {
            it: 'it-CH',
            de: 'de-CH',
            fr: 'fr-CH'
        };

        const onAddressSelect = (address) => {
            const { formRef, id } = this.props;
            onPostCodeChange(address, formRef?.current?.value, id);
            this.setState({ isSearchTriggered: true });
        };

        return (
            <AddressSearch
              locale={ localVariants[currentLocaleCode] || 'it-CH' }
              countries={ ['CH'] }
              apiKey={ loqateAccountCode }
              className="address-search-box"
              classes={ { list: 'styled-list' } }
              components={ { Input: (props) => this.renderSearchField(props) } }
              debounce={ 100 }
              onSelect={ onAddressSelect }
            />
        );
    }

    render() {
        // const { id, value } = this.props;
        // const isCheclout = location.pathname.includes('checkout');
        // const content = (id === 'postcode' || id === 'street1' || id === 'street0')
        //     && !value && isCheclout ? this.renderAddressSearch()
        //     : this.renderDefaultField();

        return this.renderDefaultField();
    }
}

export default FieldInputComponent;
