/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import Loqate from '../component/Loqate';

export const MAX_NUMBER_FILTERS = 1000;
export const MAX_NUMBER_POSITION = 12;

export class AddScriptPlugin {
    addLoqateScript = (member) => {
        const maxPosition = Math.max(
            member.map((route) => route.posiition).filter((num) => num <= MAX_NUMBER_FILTERS)
        );

        return [
            ...member,
            {
                component: <Loqate />,
                position: maxPosition + MAX_NUMBER_POSITION
            }
        ];
    };

    addConfigFieldsToQuery = (args, callback) => [
        'sp_loqate_enabled',
        'sp_loqate_accountcode',
        ...callback(...args)
    ];

    initPCA = (args, callback) => {
        callback(args);
        // eslint-disable-next-line no-undef
        if (typeof pca !== 'undefined') {
            pca.load();
        }
    };
}

const { addLoqateScript, initPCA, addConfigFieldsToQuery } = new AddScriptPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addLoqateScript
        }
    },
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            componentDidMount: initPCA
        }
    },
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addConfigFieldsToQuery
        }
    }
};
