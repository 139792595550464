import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';

/** @namespace Rapelli/Query/Order/Query/OrderQuery */
export class OrderQuery extends SourceOrderQuery {
    _getDefaultFields(isList) {
        return [
            ...super._getDefaultFields(isList),
            'customer_trade_unit',
            'line_rebate',
            'effective_weight',
            'product_special_price'
        ];
    }

    _getBaseOrderInfoFields(_isList) {
        return [
            'id',
            'increment_id',
            'created_at',
            'status_label',
            'grand_total',
            'currency_code',
            'sub_total',
            'total_weight',
            'order_tax',
            'sap_discount',
            'sap_gross_total',
            'sap_net_total'
        ];
    }
}

export default new OrderQuery();
