import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import { ProductListType } from '../../type/ProductListType';

import './PriceList.style';

/** @namespace Screenpages/b2bpricelist/Component/PriceList/Component/PriceListComponent */
export class PriceListComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isSuccess: PropTypes.bool.isRequired,
        error: PropTypes.string.isRequired,
        product_list: ProductListType.isRequired,
        downloadPriceList: PropTypes.func.isRequired
    };

    renderError() {
        const { isLoading, isSuccess, error } = this.props;
        return !isLoading && !isSuccess ? (
            <div className="error">
                <p>{ __('A error has occured') }</p>
                <p>{ error }</p>
            </div>
        ) : null;
    }

    renderLoading() {
        const { isLoading } = this.props;
        return isLoading ? (
            <Loader isLoading={ isLoading } />
        ) : null;
    }

    renderPriceList() {
        const { product_list, downloadPriceList } = this.props;
        return product_list.length > 0 ? (
            <div block="PriceList" elem="Container">
                <button
                  block="Button"
                  mods={ { likeLink: true } }
                  onClick={ downloadPriceList }
                >
                    { __('Download my price list') }
                </button>
            </div>
        ) : null;
    }

    render() {
        const { isLoading } = this.props;

        return isLoading
            ? (
                <div block="PriceList">
                    { this.renderLoading() }
                </div>
            ) : (
                <div block="PriceList">
                    { this.renderError() }
                    { this.renderPriceList() }
                </div>
            );
    }
}

export default PriceListComponent;
