/* eslint-disable eol-last */
import { Field } from 'Util/Query';

// eslint-disable-next-line no-unused-vars
const getEstimateShippingCosts = (args, callback, instance) => {
    const mutation = callback(...args);
    mutation
        .addFieldList([
            new Field('delivery_dates')
                .addFieldList(['deliveryDate', 'latestPickupDate',
                    new Field('timeSlots')
                        .addFieldList(['to', 'from', 'surcharge'])]),
            new Field('delivery_default_price')
        ]);

    return mutation;
};

export default {
    'Query/Checkout': {
        'member-function': {
            getEstimateShippingCosts
        }
    }
};