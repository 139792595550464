import { createElement } from 'react';
export class Dotdigital extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderDDGScript() {
    const {
      Container
    } = this.props.elements;
    const scriptProps = Container.propsBag[1];
    const domain = scriptProps['data-page-domain'];
    const iframeSrc = `//${domain}/resources/sharing/embed.js`;
    const script = document.createElement("script");
    script.src = iframeSrc; // "//r1.dotdigital-pages.com/resources/sharing/embed.js";
    script.setAttribute("data-sharing", "lp-embed");
    script.setAttribute("data-page-domain", domain);
    script.setAttribute("data-page-id", scriptProps['data-page-id']);

    document.body.appendChild(script);
  }

  render() {
    const {
      BaseDotForm,
      Wrapper,
      Container
    } = this.props.elements;
    const scriptProps = Container.propsBag[1];

    return <BaseDotForm.Ele>
      <Wrapper.Ele>
        <Container.Ele>
          {createElement('script', scriptProps)}
          {this.renderDDGScript()}
        </Container.Ele>
      </Wrapper.Ele>
    </BaseDotForm.Ele>
  }
}

export default Dotdigital
