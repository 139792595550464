// import getStore from 'Util/Store';

import { setSharedCatalogReloadStatus } from '../../../b2bcompany/src/store/B2bCompany/B2bCompany.action';
import { setSharedCatalogId } from '../util/SharedCatalog';

export class HandleSharedCatalogLogoutPlugin {
    aroundHandleLogout(args, callback, _obj) {
        setSharedCatalogId(0);
        const { props: { setSharedCatalogReload } } = _obj;
        setSharedCatalogReload(false);
        // const store = getStore();
        // const { dispatch } = store;
        // dispatch(setSharedCatalogReloadStatus(false));
        callback(...args);
    }

    aroundMapDispatchToProps(_args, _callback, _obj) {
        const [dispatch] = _args;
        return {
            ..._callback(dispatch),
            setSharedCatalogReload: (payload) => dispatch(setSharedCatalogReloadStatus(payload))
        };
    }
}

const { aroundHandleLogout, aroundMapDispatchToProps } = new HandleSharedCatalogLogoutPlugin();

export default {
    'Component/MyAccountTabList/Container': {
        'member-function': {
            handleLogout: aroundHandleLogout
        }
    },
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: aroundMapDispatchToProps
    }
};
