import {
    CmsBlock as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.override.style';

/** @namespace Rapelli/Component/CmsBlock/Component/CmsBlockComponent */
export class CmsBlockComponent extends SourceCmsBlock {
    // TODO implement logic
}

export default CmsBlockComponent;
