// import { showNotification } from 'Store/Notification/Notification.action';

import {
    B2BCOMPANY_ACTION_CLEAR,
    B2BCOMPANY_ACTION_SET_NO_RESULTS,
    B2BCOMPANY_ACTION_SET_PROFILE_DATA,
    B2BCOMPANY_SET_SHAREDCATALOG_RELOAD
} from './B2bCompany.action';

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Reducer/getInitialState */
export const getInitialState = () => ({
    b2bCompanyProfileData: {},
    b2bCompanyUserData: [],
    b2bCompanyRolesData: [],
    b2bAuth: [],
    isCompanyProfileLoaded: false,
    isShardedCatalogReloaded: true
});

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Reducer/B2bCompanyReducer */
export const B2bCompanyReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case B2BCOMPANY_ACTION_SET_PROFILE_DATA:
        const {
            companyProfileData: {
                company_profile,
                users,
                roles,
                b2bauth
            }
        } = action;

        return {
            ...state,
            b2bCompanyProfileData: company_profile,
            b2bCompanyUserData: users,
            b2bCompanyRolesData: roles,
            b2bAuth: b2bauth,
            isCompanyProfileLoaded: true
        };

    case B2BCOMPANY_ACTION_SET_NO_RESULTS: {
        return {
            ...state,
            isCompanyProfileLoaded: true
        };
    }

    case B2BCOMPANY_ACTION_CLEAR: {
        return {
            ...getInitialState()
        };
    }
    case B2BCOMPANY_SET_SHAREDCATALOG_RELOAD: {
        const { status } = action;
        return {
            ...state,
            isShardedCatalogReloaded: status
        };
    }

    default:
        return state;
    }
};

export default B2bCompanyReducer;
