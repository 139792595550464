import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { b2bCompanyUserGridType } from '../../type/B2bCompany';
import CompanyUsersRow from '../CompanyUsersRow';

import './CompanyUserManagement.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyUserManagement/Component/CompanyUserManagementComponent */
export class CompanyUserManagementComponent extends PureComponent {
    static propTypes = {
        CompanyUsers: b2bCompanyUserGridType,
        showEditCompanyUserFormPopup: PropTypes.func.isRequired,
        isAllowedToEdit: PropTypes.bool,
        isShowInactiveUsers: PropTypes.bool,
        switchShowInactiveUsers: PropTypes.func.isRequired
    };

    static defaultProps = {
        isAllowedToEdit: false,
        CompanyUsers: [],
        isShowInactiveUsers: false
    };

    renderSwitchUserViewLink() {
        const { isShowInactiveUsers, switchShowInactiveUsers, isAllowedToEdit } = this.props;
        const btnValue = isShowInactiveUsers ? __('Show Active Users') : __('Show Inactive Users');
        return isAllowedToEdit ? (
            <button block="CompanyUserManagement" elem="SwitchUserView">
                <button
                  block="Button"
                //   elem="SwitchUserViewBtn"
                  mods={ { isHollow: true, isWithoutBorder: true } }
                  onClick={ switchShowInactiveUsers }
                >
                    <span>
                        { btnValue }
                    </span>
                </button>
            </button>
        ) : null;
    }

    renderUsers() {
        const { CompanyUsers, isAllowedToEdit } = this.props;
        return CompanyUsers.map((user) => {
            const { entity_id } = user;
            const key = `users${ entity_id }`;
            return (
                <CompanyUsersRow
                  CompanyUserData={ user }
                  key={ key }
                  isAllowedEditUsers={ isAllowedToEdit }
                />
            );
        });
    }

    renderEditUsersBtn() {
        const { showEditCompanyUserFormPopup, isAllowedToEdit } = this.props;

        return isAllowedToEdit ? (
            <button
              block="Button"
              mods={ { isHollow: true } }
              onClick={ showEditCompanyUserFormPopup }
            >
                <span>{ __('Add a new user') }</span>
            </button>
        ) : null;
    }

    render() {
        return (
            <div block="CompanyUserManagement">
                <table block="CompanyUserManagement-Table">
                    <thead>
                        <tr>
                            <th>{ __('Name') }</th>
                            <th>{ __('Email') }</th>
                            <th>{ __('Role') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderUsers() }
                    </tbody>
                </table>
                { this.renderEditUsersBtn() }
                { this.renderSwitchUserViewLink() }
            </div>
        );
    }
}

export default CompanyUserManagementComponent;
