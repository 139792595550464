import PropTypes from 'prop-types';

export const quoteRowType = PropTypes.shape({
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    last_updated: PropTypes.string,
    quote_id: PropTypes.number,
    quote_name: PropTypes.string,
    quote_total: PropTypes.number,
    status: PropTypes.string,
    quote_currency_code: PropTypes.string
});

export const quoteRowGrid = PropTypes.arrayOf(quoteRowType);

export const singleQuoteType = PropTypes.shape({
    quote_id: PropTypes.number,
    error_message: PropTypes.string,
    created_at: PropTypes.string,
    expires_at: PropTypes.string,
    quote_owner: PropTypes.string,
    status: PropTypes.string,
    quote_name: PropTypes.string,
    sales_rep: PropTypes.string
});

// export const QuoteItem = PropTypes.shape({
//     name: PropTypes.string,
//     thumbnail: PropTypes.string,
//     id: PropTypes.number
// });

// export const QuoteItemsType = PropTypes.arrayOf(QuoteItem);

export const QuoteComment = PropTypes.shape({
    created_at: PropTypes.string,
    comment: PropTypes.string,
    id: PropTypes.number,
    author: PropTypes.string,
    is_decline: PropTypes.number
});

export const QuoteCommentsType = PropTypes.arrayOf(QuoteComment);

export const QuoteRestrictionsType = PropTypes.shape({
    can_submit: PropTypes.bool,
    can_close: PropTypes.bool,
    can_delete: PropTypes.bool,
    can_proceed_to_checkout: PropTypes.bool,
    can_decline: PropTypes.bool,
    is_owner: PropTypes.bool
});
