import Loader from 'Component/Loader';

import DeliverySlotContainer from '../component/DeliverySlot/DeliverySlot.container';

const renderContent = (args, callback, instance) => {
    const {
        isLoading,
        isPickInStoreMethodSelected,
        selectedShippingMethod,
        shippingMethods,
        estimateAddress,
        saveDeliverySlot,
        deliveryDate,
        deliveryTime,
        deliverySurcharge,
        handleDeliverySlotChange
    } = instance.props;

    if (isPickInStoreMethodSelected) {
        return instance.renderPickInStoreMethod();
    }

    return (
        <>
            { instance.renderAddressBook() }
            <div>
                <Loader isLoading={ isLoading } />
                { instance.renderDelivery() }
                <DeliverySlotContainer
                  selectedShippingMethod={ selectedShippingMethod }
                  shippingMethods={ shippingMethods }
                  estimateAddress={ estimateAddress }
                  saveDeliverySlot={ saveDeliverySlot }
                  deliveryDate={ deliveryDate }
                  deliveryTime={ deliveryTime }
                  deliverySurcharge={ deliverySurcharge }
                  handleDeliverySlotChange={ handleDeliverySlotChange }
                />
                { instance.renderActions() }
            </div>
        </>
    );
};

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderContent
        }
    }
};
