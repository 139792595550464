import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Rapelli/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault
        } = this.props;

        showNotification('addtocart', __('Product added to cart!'));
        setQuantityToDefault();

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });
    }

    validateAddToCart() {
        const { quantity, showNotification } = this.props;

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(parseFloat(quantity)) || !isFinite(quantity)) {
            showNotification('info', __('Please enter a numeric value'));
            return false;
        }

        return super.validateAddToCart();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
