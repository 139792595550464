import { Field } from 'Util/Query';

/** @namespace Screenpages/b2bpricelist/Query/B2bPriceList/Query/B2bPriceListQuery */
export class B2bPriceListQuery {
    getPriceListData() {
        return new Field('getProductListInfo')
            .addFieldList(this._getPriceListDataFields());
    }

    _getPriceListDataFields() {
        return [
            'user_id',
            'sap_customer_id',
            this.addProductListFields()
        ];
    }

    addProductListFields() {
        return new Field('product_list')
            .addFieldList([
                'customer_number',
                'name',
                'product_line',
                'material_number',
                'customer_cashier_ean',
                'gtn_base_unit_measure',
                'gtin_sales',
                'gtin_prod_label',
                'gtn_base_unit_measure',
                'gtn_sales_unit_measure',
                'cwm_xcwmat',
                'normal_invoice_price',
                'promo_invoice_price',
                'price_per_quantity',
                'price_per_unit',
                'label_price',
                'label_price_per_quantity',
                'label_price_per_unit',
                'line_rebate',
                'invoice_rebate',
                'customer_material_number',
                'last_order_date'
            ]);
    }
}

export default new B2bPriceListQuery();
