import CompanyOverview from '../component/CompanyOverview';
import { COMPANY_OVERVIEW_URI } from '../component/CompanyOverview/CompanyOverview.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export class AddCompanyToMyAccountPlugin {
  addCompanyToMenu = (member) => ({
      ...member,
      [COMPANY_OVERVIEW_URI]: {
          url: `/${ COMPANY_OVERVIEW_URI }`,
          name: __('Company Profile'),
          b2bCompanyCheck: true
      }
  });

  addCompanyToRender = (member) => ({
      ...member,
      [COMPANY_OVERVIEW_URI]: CompanyOverview
  });

  checkMenuRender = (args, callback, obj) => {
      const tabEntry = args[0];
      const { b2bCompanyCheck } = tabEntry[1];
      const { props } = obj;
      const { isB2BCompanyMember } = props;
      if (isB2BCompanyMember === undefined) {
          return null;
      }
      if (b2bCompanyCheck && !isB2BCompanyMember) {
          return null;
      }

      return (
          callback(...args)
      );
  };

  addCustomerStateToProps(args, callback) {
      const [state] = args;
      const { isB2BCompanyMember } = state.MyAccountReducer.customer;
      return {
          ...callback(...args),
          isB2BCompanyMember
      };
  }

  addB2bCompanyFieldToAccountQuery = (args, callback) => [
      'isB2BCompanyMember',
      ...callback(args)
  ];

  addValuesToComponentProps(args, callback, obj) {
      // const { props } = obj;
      const {
          props: { isB2BCompanyMember }
      } = obj;

      return {
          ...callback(...args),
          isB2BCompanyMember
      };
  }

  addCustomerRequestToDispatchProps(agrs, callback) {
      const [dispatch] = agrs;

      return {
          ...callback(...agrs),
          requestCustomerData: () => MyAccountDispatcher.then(
              ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
          )
      };
  }
}

const {
    addCustomerStateToProps,
    addCompanyToMenu,
    addCompanyToRender,
    checkMenuRender,
    addCustomerRequestToDispatchProps,
    addValuesToComponentProps,
    addB2bCompanyFieldToAccountQuery
} = new AddCompanyToMyAccountPlugin();

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: addCompanyToMenu
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addCompanyToRender
        }
    },
    'Component/MyAccountTabList/Container/mapStateToProps': {
        function: addCustomerStateToProps
    },
    'Component/MyAccountTabList/Component': {
        'member-function': {
            renderTabListItem: checkMenuRender
        }
    },
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: addCustomerRequestToDispatchProps
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            containerProps: addValuesToComponentProps
        }
    },
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: addB2bCompanyFieldToAccountQuery
        }
    }
};
