import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.override.style';

/** @namespace Rapelli/Component/Breadcrumbs/Component/BreadcrumbsComponent */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    // TODO implement logic
}

export default BreadcrumbsComponent;
