/* eslint-disable max-len */
import ProductAttributeValue from 'Component/ProductAttributeValue';
import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace Rapelli/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributesComponent */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    // TODO implement logic
    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            inStock,
            parameters,
            variants
        } = this.props;

        const { attribute_value } = attribute;
        const optionEntries = Object.entries(attribute.attribute_options);
        const findArrayMin = (arr) => arr.reduce((lastResult, nextItem) => (lastResult[1].label < nextItem[1].label ? lastResult : nextItem));
        const noSelectedOption = !Object.keys(parameters).includes(attribute.attribute_code);
        const minOptionValue = attribute_value === findArrayMin(optionEntries)[0];

        if (noSelectedOption && minOptionValue) {
            handleOptionClick(attribute);
        }

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ inStock && getIsConfigurableAttributeAvailable(attribute) }
              onClick={ handleOptionClick }
              getLink={ getLink }
              showProductAttributeAsLink={ showProductAttributeAsLink }
              variants={ variants }
            />
        );
    }
}

export default ProductConfigurableAttributesComponent;
