// import { updateCurrentCategory } from 'Store/Category/Category.action';
// import {
//     updateLoadStatus, updatePageLoadingStatus, updateProductListItems
// } from 'Store/ProductList/ProductList.action';
import getStore from 'Util/Store';

import { setSharedCatalogReloadStatus } from '../../../b2bcompany/src/store/B2bCompany/B2bCompany.action';

export class HandleSharedCatalogLoginPlugin {
    aroundSignin(args, callback, _obj) {
        const res = callback(...args);
        // updateCurrentCategory({});
        // updateProductListItems({});
        // updateLoadStatus(false);
        // updatePageLoadingStatus(false);
        const store = getStore();
        const { dispatch } = store;
        dispatch(setSharedCatalogReloadStatus(false));
        // dispatch(updateCurrentCategory({}));
        // dispatch(updateProductListItems({}));
        // dispatch(updateLoadStatus(false));
        // updateCurrentCategory({});
        return res;
    }

    aroundMapStateToProps(_args, _callback, _obj) {
        const [state] = _args;
        return {
            ..._callback(state),
            isShardedCatalogReloaded: state.B2bCompanyReducer.isShardedCatalogReloaded
        };
    }

    aroundMapDispatchToProps(_args, _callback, _obj) {
        const [dispatch] = _args;
        return {
            ..._callback(dispatch),
            setSharedCatalogReload: (payload) => dispatch(setSharedCatalogReloadStatus(payload))
        };
    }

    aroundIsPreventRequest(_args, _callback, _obj) {
        const res = _callback(..._args);
        if (!res) {
            return res;
        }

        const {
            props: {
                isShardedCatalogReloaded, setSharedCatalogReload
            }
        } = _obj;

        if (!isShardedCatalogReloaded) {
            setSharedCatalogReload(true);
            return false;
        }

        return res;
    }
}

const {
    aroundSignin,
    aroundIsPreventRequest,
    aroundMapDispatchToProps,
    aroundMapStateToProps
} = new HandleSharedCatalogLoginPlugin();

export default {
    'Component/MyAccountSignIn/Container': {
        'member-function': {
            onSignInSuccess: aroundSignin
        }
    },
    'Component/CategoryProductList/Container': {
        'member-function': {
            getIsPreventRequest: aroundIsPreventRequest
        }
    },
    'Component/CategoryProductList/Container/mapStateToProps': {
        function: aroundMapStateToProps
    },
    'Component/CategoryProductList/Container/mapDispatchToProps': {
        function: aroundMapDispatchToProps
    }
};
