import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import { quoteRowType } from '../../type/B2bQuote';

import './QuoteTableRow.style';

/** @namespace Screenpages/b2bquote/Component/QuoteTableRow/Component/QuoteTableRowComponent */
export class QuoteTableRowComponent extends PureComponent {
    static propTypes = {
        QuoteRowData: quoteRowType.isRequired,
        onViewQuote: PropTypes.func.isRequired,
        formatDate: PropTypes.func.isRequired
    };

    render() {
        const {
            formatDate,
            onViewQuote,
            QuoteRowData: {
                quote_name,
                created_by,
                status,
                last_updated,
                quote_total,
                quote_currency_code
            }
        } = this.props;

        return (
        <tr onClick={ onViewQuote } block="QuoteTableRow">
            <td>
                { quote_name }
            </td>
            <td block="hidden-mobile">
                { created_by }
            </td>
            <td>
                { status }
            </td>
            <td block="hidden-mobile">
                { formatDate(last_updated) }
            </td>
            <td>
              { formatPrice(quote_total, quote_currency_code) }
            </td>
        </tr>
        );
    }
}

export default QuoteTableRowComponent;
