import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import {
    b2bComapnyAuthType, b2bComapnyProfileDataType, b2bCompanyRoleType, b2bCompanyUserGridType
} from '../../type/B2bCompany';
import {
    COMPANY_EDIT_POPUP, COMPANY_FORM_EDIT_COMPANY,
    COMPANY_FORM_EDIT_ROLE,
    COMPANY_FORM_EDIT_USER
} from '../CompanyEditPopup/CompanyEditPopup.config';
import CompanyOverview from './CompanyOverview.component';

export const B2bCompanyDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/B2bCompany/B2bCompany.dispatcher'
);

/** @namespace Screenpages/b2bcompany/Component/CompanyOverview/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    b2bCompanyProfileData: _state.B2bCompanyReducer.b2bCompanyProfileData,
    b2bCompanyUserData: _state.B2bCompanyReducer.b2bCompanyUserData,
    b2bCompanyRolesData: _state.B2bCompanyReducer.b2bCompanyRolesData,
    b2bAuth: _state.B2bCompanyReducer.b2bAuth,
    isCompanyProfileLoaded: _state.B2bCompanyReducer.isCompanyProfileLoaded
});

/** @namespace Screenpages/b2bcompany/Component/CompanyOverview/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    getCompanyData: () => B2bCompanyDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCompanyProfile(_dispatch)
    ),
    showEditFormPopup: (payload) => _dispatch(showPopup(COMPANY_EDIT_POPUP, payload))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyOverview/Container/CompanyOverviewContainer */
export class CompanyOverviewContainer extends PureComponent {
    static propTypes = {
        b2bCompanyProfileData: b2bComapnyProfileDataType,
        b2bCompanyUserData: b2bCompanyUserGridType,
        b2bCompanyRolesData: b2bCompanyRoleType,
        b2bAuth: b2bComapnyAuthType,
        isCompanyProfileLoaded: PropTypes.bool,
        showEditFormPopup: PropTypes.func.isRequired,
        getCompanyData: PropTypes.func.isRequired
    };

    static defaultProps = {
        b2bCompanyProfileData: {},
        b2bCompanyUserData: {},
        b2bCompanyRolesData: {},
        b2bAuth: {},
        isCompanyProfileLoaded: false
    };

    containerFunctions = {
        showEditCompanyFormPopup: this._showEditCompanyFormPopup.bind(this),
        showEditCompanyUserFormPopup: this._showEditCompanyUserFormPopup.bind(this),
        showEditCompanyRoleFormPopup: this._showEditCompanyRoleFormPopup.bind(this),
        reloadCompanyProfile: this._loadCompanyProfile.bind(this)
    };

    componentDidMount() {
        this._loadCompanyProfile();
    }

    containerProps = () => {
        const {
            b2bCompanyProfileData, b2bCompanyUserData, b2bCompanyRolesData,
            b2bAuth, isCompanyProfileLoaded
        } = this.props;

        return {
            b2bCompanyProfileData,
            b2bCompanyUserData,
            b2bCompanyRolesData,
            b2bAuth,
            isCompanyProfileLoaded
        };
    };

    _loadCompanyProfile() {
        const { getCompanyData } = this.props;
        getCompanyData();
    }

    _showEditCompanyFormPopup() {
        const { showEditFormPopup } = this.props;
        showEditFormPopup({
            title: __('Edit Company'),
            type: COMPANY_FORM_EDIT_COMPANY
        });
    }

    _showEditCompanyUserFormPopup() {
        const { showEditFormPopup } = this.props;
        showEditFormPopup({
            title: __('Add a new user'),
            type: COMPANY_FORM_EDIT_USER
        });
    }

    _showEditCompanyRoleFormPopup() {
        const { showEditFormPopup } = this.props;
        showEditFormPopup({
            title: __('Add a new role'),
            type: COMPANY_FORM_EDIT_ROLE
        });
    }

    render() {
        return (
            <CompanyOverview
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOverviewContainer);
