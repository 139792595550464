import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';

import { CompanyType } from '../../type/B2bCompany';

import './CompanyEditForm.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Component/CompanyEditFormComponent */
export class CompanyEditFormComponent extends PureComponent {
    static propTypes = {
        CompanyData: CompanyType, // FIXME Check Prop Type
        handleSubmit: PropTypes.func.isRequired,
        handleSubmitSuccess: PropTypes.func.isRequired,
        handleSubmitError: PropTypes.func.isRequired,
        isFormSubmitting: PropTypes.bool.isRequired
    };

    static defaultProps = {
        CompanyData: {}
    };

    renderEditCompanyForm() {
        const {
            handleSubmit, handleSubmitSuccess,
            handleSubmitError, isFormSubmitting, CompanyData
        } = this.props;

        const {
            company_name,
            company_legal_name,
            company_email,
            vat_id
        } = CompanyData;

        return (
          <Form
            mix={ { block: 'CompanyEdit', elem: 'Form' } }
            key="companyeditform"
            onSubmit={ handleSubmit }
            onSubmitSuccess={ handleSubmitSuccess }
            onSubmitError={ handleSubmitError }
          >
              <Field
                type="text"
                label={ __('Company Name') }
                name="company_name"
                id="company_name"
                value={ company_name }
              />
              <Field
                type="text"
                label={ __('Legal Name') }
                name="company_legal_name"
                id="company_legal_name"
                value={ company_legal_name }
              />
              <Field
                type="text"
                label={ __('Company Email') }
                name="company_email"
                id="company_email"
                value={ company_email }
              />
              <Field
                type="text"
                label={ __('VAT / Tax Code') }
                name="vat_id"
                id="vat_id"
                value={ vat_id }
              />
              <button
                block="Button"
                mods={ { isFormSubmitting } }
                disabled={ isFormSubmitting }
              >
                  <span>
                      { __('Save Company') }
                  </span>
              </button>
          </Form>
        );
    }

    render() {
        // TODO handle Edit Address
        return (
            <div block="CompanyEditForm">
                { this.renderEditCompanyForm() }
            </div>
        );
    }
}

export default CompanyEditFormComponent;
