import CheckoutQuery from 'Query/Checkout.query';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import history from 'Util/History';
import {
    fetchMutation,
    fetchQuery
} from 'Util/Request';
import { appendWithStoreCode, getQueryParam } from 'Util/Url';

import { ADYEN_CC, THREE_D } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';
import AdyenQuery from '../query/Adyen.query';

const saveAddressInformation = async (args, callback, instance) => {
    await fetchQuery(AdyenQuery.getPaymentMethods()).then(
        ({ getAdyenPaymentMethods }) => {
            const adyenPaymentMethods = JSON.parse(getAdyenPaymentMethods);
            instance.setState({ adyenPaymentMethods });
        },
        instance._handleError
    );

    await callback(...args);
};

const getOrderFromUrl = (instance) => {
    const orderId = getQueryParam('id', instance.props.location);

    if (!orderId) {
        return false;
    }

    return orderId.replace('/', '');
};

const componentDidMount = (args, callback, instance) => {
    const orderId = getOrderFromUrl(instance);

    if (orderId) {
        instance.setDetailsStep(orderId);
        return null;
    }

    callback(...args);
};

const containerProps = (args, callback, instance) => {
    const { adyenPaymentMethods = null } = instance.state;

    return {
        ...callback(...args),
        adyenPaymentMethods
    };
};

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { showThreePopUp } = instance.props;
    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

    if (code === ADYEN_CC || code === ADYEN_HPP) {
        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }
        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order: { order_id }, action = false, id = 0 } } = orderData;

            instance.setState({ order_id: id, order_increment: order_id });

            if (action) {
                showThreePopUp({ title: __('Secure') });
                window.adyen.createFromAction(JSON.parse(action), { challengeWindowSize: '05' }).mount('#three');
                instance.setState({ isLoading: false });
            } else {
                instance.setDetailsStep(order_id);
            }
        } catch (e) {
            instance._handleError(e);
        }
    } else {
        callback(...args);
    }
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showThreePopUp: (payload) => dispatch(showPopup(THREE_D, payload)),
        hideActiveOverlay: () => dispatch(hideActiveOverlay())
    };
};

export class CheckoutContainerPlugin {
    handleDetails(data) {
        const { order_id, order_increment } = this.state;
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();
        this.setState({ isLoading: true });

        const requstData = {
            ...data,
            orderId: order_id
        };
        const payload = JSON.stringify(requstData);

        fetchQuery(AdyenQuery.handleAdyenPaymentDetails(payload)).then(({ adyenPaymentDetails }) => {
            const result = JSON.parse(adyenPaymentDetails);
            const { resultCode } = result;

            if (resultCode === 'Authorised') {
                this.setDetailsStep(order_increment);
            } else {
                this._handleError();
            }
        },
        (_error) => {
            setTimeout(() => {
                window.location.reload();
                history.push(appendWithStoreCode('/cart'));
            // eslint-disable-next-line no-magic-numbers
            }, 1000);
        });
    }

    containerFunctions = (members, instance) => ({
        ...members,
        handleDetails: this.handleDetails.bind(instance)
    });
}

const { containerFunctions } = new CheckoutContainerPlugin();

export default {
    'Route/Checkout/Container': {
        'member-property': {
            containerFunctions
        },
        'member-function': {
            saveAddressInformation,
            containerProps,
            savePaymentMethodAndPlaceOrder,
            componentDidMount
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
