import PriceList from '../component/PriceList';

export const PRICE_LIST_URI = 'pricelist';

export class MyAccountPlugin {
    addPriceListToMenu = (member) => ({
        ...member,
        [PRICE_LIST_URI]: {
            url: `/${PRICE_LIST_URI}`,
            name: __('Price List'),
            b2bCompanyCheck: true
        }
    });

    addPriceListToRender = (member) => ({
        ...member,
        [PRICE_LIST_URI]: PriceList
    });
}

const { addPriceListToMenu, addPriceListToRender } = new MyAccountPlugin();

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: {
                position: 110,
                implementation: addPriceListToMenu
            }
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: {
                position: 110,
                implementation: addPriceListToRender
            }

        }
    }
};
