import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import { ONE_HUNDRED } from './ProductAttributeValue.config';
/** @namespace Rapelli/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderStringValue(value, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return label || value || __('N/A');
        }

        if (!isSwatch) {
            return this.renderDropdown(value, count);
        }

        const newVal = this.getConfigLabel(value);
        return (
            <span
              block="ProductAttributeValue"
              elem="String"
              mods={ { isSelected } }
              title={ label }
            >
                { newVal }
            </span>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getConfigLabel(origValue) {
        // const parameters = [value];
        const { variants, attribute: { attribute_code, attribute_value } } = this.props;
        // const configurableVariantIndex = getVariantIndex(variants, parameters, true);

        const selectedVariant = variants.find(({ attributes }) => {
            const { attribute_value: foundValue } = attributes[attribute_code] || {};

            return attribute_value === foundValue;
        });

        if (selectedVariant && selectedVariant?.b2b_fields?.weight_multiply) {
            const origWeightNumber = origValue.replace(/[^0-9.]/g, '');
            const origWeightType = origValue.replace(/\d/g, '').replace('.', '');

            const newWeightVal = origWeightNumber * selectedVariant.b2b_fields.weight_multiply;
            const newWeightValRounded = Math.round(newWeightVal * ONE_HUNDRED) / ONE_HUNDRED;
            return `${newWeightValRounded + origWeightType}`;
        }

        return origValue;
    }
}

export default ProductAttributeValueComponent;
