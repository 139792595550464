import CmsBlock from 'Component/CmsBlock';
import Field from 'Component/Field/Field.container';
import {
    CheckoutPayment as SourceCheckoutPayment
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.override.style';

/** @namespace Rapelli/Component/CheckoutPayment/Component/CheckoutPaymentComponent */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    renderPaymentIconCms() {
        const {
            method: { type: methodType, code: methodCode }
        } = this.props;
        // eslint-disable-next-line fp/no-let
        let cmsBlockIdentifer = '';

        if (methodCode === 'adyen_cc') {
            cmsBlockIdentifer = 'checkout_payments_credit_card_icon';
        } else if (methodCode === 'adyen_hpp' && methodType === 'klarna') {
            cmsBlockIdentifer = 'checkout_payments_klarna_icon';
        } else if (methodCode === 'adyen_hpp' && methodType === 'twint') {
            cmsBlockIdentifer = 'checkout_payments_twint_icon';
        }

        return (
            <CmsBlock identifier={ cmsBlockIdentifer } />
        );
    }

    renderPaymentCms() {
        const {
            method: { type: methodType, code: methodCode }
        } = this.props;
        // eslint-disable-next-line fp/no-let
        let cmsBlockIdentifer = '';

        if (methodCode === 'adyen_hpp' && methodType === 'klarna') {
            cmsBlockIdentifer = 'checkout_payments_klarna';
        } else if (methodCode === 'adyen_hpp' && methodType === 'twint') {
            cmsBlockIdentifer = 'checkout_payments_twint';
        }

        return (
            <CmsBlock identifier={ cmsBlockIdentifer } />
        );
    }

    render() {
        const {
            isSelected,
            method: { title: methodTitle, type: methodType }
            // method: { title }
        } = this.props;

        const title = methodType === 'klarna' ? __('Invoice') : methodTitle;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type="checkbox"
                      id={ `option-${ title }` }
                      name={ `option-${ title }` }
                      checked={ isSelected }
                      disabled
                    />
                    <div>{ __(`${title}`) }</div>
                    <div block="CheckoutPayment" elem="Icons">
                        { this.renderPaymentIconCms() }
                    </div>
                    <div block="CheckoutPayment" elem="Cms">
                        { this.renderPaymentCms() }
                    </div>
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
