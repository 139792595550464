import {
    KeyValueTable as SourceKeyValueTable
} from 'SourceComponent/KeyValueTable/KeyValueTable.component';

import './KeyValueTable.override.style';

/** @namespace Rapelli/Component/KeyValueTable/Component/KeyValueTableComponent */
export class KeyValueTableComponent extends SourceKeyValueTable {
    renderTableRow = (data) => {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <tr
              key={ key }
              block="KeyValueTable"
              elem={ key }
            >
                <th>{ label }</th>
                <td>{ value }</td>
            </tr>
        );
    };
}

export default KeyValueTableComponent;
