import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { CompanyType } from '../../type/B2bCompany';
import CompanyEditForm from '../CompanyEditForm';
import CompanyRolesEditForm from '../CompanyRolesEditForm';
import CompanyUserEditForm from '../CompanyUserEditForm';
import {
    COMPANY_EDIT_POPUP, COMPANY_FORM_EDIT_COMPANY, COMPANY_FORM_EDIT_ROLE, COMPANY_FORM_EDIT_USER
} from './CompanyEditPopup.config';

import './CompanyEditPopup.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyEditPopup/Component/CompanyEditPopupComponent */
export class CompanyEditPopupComponent extends PureComponent {
    static propTypes = {
        CompanyData: CompanyType, // TODO check props
        handleCloseAndReload: PropTypes.func.isRequired,
        handleUnknownType: PropTypes.func.isRequired,
        EditType: PropTypes.string,
        getUserData: PropTypes.func.isRequired,
        getRoleData: PropTypes.func.isRequired
    };

    static defaultProps = {
        CompanyData: false,
        EditType: ''
    };

    renderEditCompanyForm() {
        const {
            CompanyData, handleCloseAndReload, handleUnknownType
        } = this.props;

        // HACK need better validating
        if (!CompanyData) {
            handleUnknownType();
            return null;
        }

        return (
            <CompanyEditForm
              CompanyData={ CompanyData }
              handleCloseAndReload={ handleCloseAndReload }
            />
        );
    }

    renderContent() {
        const { EditType } = this.props;
        switch (EditType) {
        case COMPANY_FORM_EDIT_COMPANY:
            return this.renderEditCompanyForm();
        case COMPANY_FORM_EDIT_USER:
            return this.renderEditUserForm();
        case COMPANY_FORM_EDIT_ROLE:
            return this.renderEditRoleForm();
        default:
            return this.renderUnknown();
        }
    }

    renderEditUserForm() {
        const { handleCloseAndReload, getUserData } = this.props;
        const userData = getUserData();
        return (
            <CompanyUserEditForm
              handleCloseAndReload={ handleCloseAndReload }
              userData={ userData }
            />
        );
    }

    renderEditRoleForm() {
        const { handleCloseAndReload, getRoleData } = this.props;
        return (
            <CompanyRolesEditForm
              handleCloseAndReload={ handleCloseAndReload }
              roleData={ getRoleData() }
            />
        );
    }

    renderUnknown() {
        // const { handleUnknownType } = this.props;
        // handleUnknownType();
        return null;
    }

    render() {
        return (
            <Popup
              id={ COMPANY_EDIT_POPUP }
              mix={ { block: 'CompanyEdit' } }
              clickOutside={ false }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default CompanyEditPopupComponent;
