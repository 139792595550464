import B2bCompanyReducer from '../store/B2bCompany/B2bCompany.reducer';

export class AddB2bCompanyReducerPlugin {
  addStaticReducer = (args, callback) => ({
      ...callback(args),
      B2bCompanyReducer
  });
}
const { addStaticReducer } = new AddB2bCompanyReducerPlugin();
export default {
    'Store/Index/getReducers': {
        function: addStaticReducer
    }
};
