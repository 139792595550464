import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import {
    formatPrice,
    roundPrice
} from 'Util/Price';

/** @namespace Rapelli/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    renderOldPrice() {
        const {
            discountPercentage,
            roundedRegularPrice,
            defaultFinalPriceExclTax,
            isSchemaRequired,
            variantsCount,
            b2bOldPrice,
            priceCurrency,
            isShowB2bSpecialPrice
        } = this.props;

        if (isShowB2bSpecialPrice > 0 && b2bOldPrice > defaultFinalPriceExclTax) {
            return (
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  aria-label={ __('Old product price') }
                  itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
                >
                    { formatPrice(roundPrice(b2bOldPrice, priceCurrency)) }
                </del>
            );
        }

        if (discountPercentage === 0 || roundedRegularPrice === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { roundedRegularPrice }
            </del>
        );
    }
}

export default ProductPriceComponent;
