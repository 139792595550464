import {
    BIG_PLACEHOLDER_CONFIG as SOURCE_BIG_PLACEHOLDER_CONFIG,
    SMALL_PLACEHOLDER_CONFIG as SOURCE_SMALL_PLACEHOLDER_CONFIG
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.config';

// TODO: implement BIG_PLACEHOLDER_CONFIG
export const BIG_PLACEHOLDER_CONFIG = SOURCE_BIG_PLACEHOLDER_CONFIG;

// TODO: implement SMALL_PLACEHOLDER_CONFIG
export const SMALL_PLACEHOLDER_CONFIG = SOURCE_SMALL_PLACEHOLDER_CONFIG;
