/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import AddToCartEvent from './events/AddToCart.event';
import CheckoutInitEvent from './events/CheckoutInit.event';
import PageViewEvent from './events/PageView.event';
import PurchaseEvent from './events/Purchase.event';
import {
    EVENT_ADD_TO_CART,
    EVENT_CHECKOUT_INIT,
    EVENT_PAGE_VIEW,
    EVENT_PURCHASE
} from './FacebookConversionApi.config';
import { getCookie } from './util/Cookie';

/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    fbconfig: state.ConfigReducer.fbconfig,
    fbCustomerData: state.ConfigReducer.fbCustomerData,
    state
});

/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    dispatch
});

/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Container/FacebookConversionApiContainer */
export class FacebookConversionApiContainer extends PureComponent {
    static propTypes = {
        fbconfig: PropTypes.shape(),
        fbCustomerData: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        state: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        dispatch: PropTypes.func
    };

    static defaultProps = {
        fbconfig: {
            enabled: false,
            debug_mode: false
        },
        fbCustomerData: {
            customer_ip: ''
        },
        state: {},
        dispatch: () => {}
    };

    /**
     * Event tracked in FacebookConversionApiContainer
     */
    static eventList = {
        [EVENT_PAGE_VIEW]: PageViewEvent,
        [EVENT_PURCHASE]: PurchaseEvent,
        [EVENT_CHECKOUT_INIT]: CheckoutInitEvent,
        [EVENT_ADD_TO_CART]: AddToCartEvent
    };

    /**
     * For ensuring only one instance is loaded
     *
     * @type {FacebookConversionApiContainer}
     */
    static instance = null;

    /**
     * Get FacebookConversionApiContainer Instance
     *
     * @return {FacebookConversionApiContainer}
     */
    static getInstance() {
        return this.instance;
    }

    /**
    * @param name
    * @return {null|BaseEvent}
    */
    static getEvent(name) {
        if (this.getInstance()) {
            return this.getInstance().getEvent(name);
        }

        return null;
    }

    fbConversionApiData = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Event data object
     *
     * @type {{}}
     */
        events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate() {
        this.initialize();
    }

    componentWillUnmount() {
        this.destruct();
    }

    initialize() {
        const { fbconfig: { enabled } } = this.props;

        if (this.enabled || !enabled || FacebookConversionApiContainer.getInstance()) {
            return;
        }

        this.enabled = true;
        FacebookConversionApiContainer.instance = this;
        this.setFacebookConversionApiData();
        this.registerEvents();
    }

    /**
     * Set initial API data.
     */
    setFacebookConversionApiData() {
        const {
            fbconfig: { debug_mode: debugMode, credentials: { access_token, pixel_id } },
            fbCustomerData: { customer_ip: customerIp }
        } = this.props;
        const fbcCookie = debugMode
            ? null
            : this.getFbcCookie();
        const fbpCookie = debugMode ? 'fb.1.1111111111111.111111111' : this.getFbpCookie();
        const clientUserAgent = navigator.userAgent;

        this.fbConversionApiData = {
            access_token,
            pixel_id,
            customerEmail: [],
            customerPhone: [],
            customerIp,
            clientUserAgent,
            fbpCookie,
            fbcCookie,
            debugMode
        };
    }

    /**
     * If user clicks on an ad from FB, fbclid query parameter added to URL
     * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
     *
     * Click ID
     * Format: fb.${subdomain_index}.${creation_time}.${fbclid}
     */
    getFbcCookie() {
        return getCookie('_fbc');
    }

    /**
     * Browser ID
     * Format fb.${subdomain_index}.${creation_time}.${random_number}
     */
    getFbpCookie() {
        return getCookie('_fbp');
    }

    /**
     * Register event handlers
     */
    registerEvents() {
        this.events = Object.entries(FacebookConversionApiContainer.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }

    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    setGroupedProducts() {
        // TODO, Rapelli currently aren't using grouped products
    }

    getGroupedProducts() {
        return this.groupedProducts;
    }

    destruct() {
        Object.values(this.events).forEach((event, name) => {
            // eslint-disable-next-line fp/no-delete
            delete this.events[name];
        });

        this.events = {};
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookConversionApiContainer));
