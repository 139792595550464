/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import { MPLabelStylesType } from '../../type/MPProductLabel';

import './ProductLabel.style';

/** @namespace Screenpages/Mpproductlabel/Component/ProductLabel/Component/ProductLabelComponent */
export class ProductLabelComponent extends PureComponent {
    static propTypes = {
        LabelStyles: MPLabelStylesType,
        LabelText: PropTypes.string,
        TemplateImagePath: PropTypes.string,
        LabelImagePath: PropTypes.string,
        AdditionalTextStyle: PropTypes.object
    };

    static defaultProps = {
        LabelStyles: {},
        LabelText: '',
        TemplateImagePath: '',
        LabelImagePath: '',
        AdditionalTextStyle: {}
    };

    renderTemplate() {
        const { TemplateImagePath } = this.props;
        const { LabelStyles: { width, height } } = this.props;
        return TemplateImagePath
            ? (
                <div block="ProductLabel" elem="Template">
                    <Image
                      src={ TemplateImagePath }
                      width={ width }
                      height={ height }
                    />
                </div>
            )
            : null;
    }

    renderImage() {
        const { LabelImagePath } = this.props;
        const { LabelStyles: { width, height } } = this.props;
        return LabelImagePath
            ? (
                <div block="ProductLabel" elem="Image">
                    <Image
                      src={ LabelImagePath }
                      width={ width }
                      height={ height }
                    />
                </div>
            )
            : null;
    }

    renderLabelText() {
        const { LabelText, AdditionalTextStyle } = this.props;

        return LabelText
            ? (
                <div block="ProductLabel" elem="LabelText" style={ AdditionalTextStyle }>
                    { LabelText }
                </div>
            )
            : null;
    }

    render() {
        const { LabelStyles } = this.props;

        return (
            <div block="ProductLabel" style={ LabelStyles }>
                { this.renderTemplate() }
                { this.renderLabelText() }
                { this.renderImage() }
            </div>
        );
    }
}

export default ProductLabelComponent;
