import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import loader from './Loader.gif';

import './Loader.override.style';

/** @namespace Rapelli/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    /**
     * Overridden to change the loader
     */
    renderMain() {
        return (
            <div block="Loader" elem="IconWrapper">
                <img src={ loader } alt="loader" block="Loader" elem="Image" />
            </div>
        );
    }
}

export default LoaderComponent;
