import { getFormFields, trimAddressFields } from 'Util/Address';

export class CheckoutShippingContainerPlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleDeliverySlotChange: this.handleDeliverySlotChange.bind(instance)
    });

    handleDeliverySlotChange(deliveryState) {
        this.setState(deliveryState);
    }
}

const { containerFunctions } = new CheckoutShippingContainerPlugin();

const state = (originalMember) => ({
    ...originalMember,
    deliveryDate: null,
    deliveryTime: {},
    deliverySurcharge: 0,
    planzerDeliverySurcharge: 0
});

const containerProps = (args, callback, instance) => {
    const {
        deliveryTime, deliveryDate, deliverySurcharge, planzerDeliverySurcharge
    } = instance.state;

    return {
        ...callback(...args),
        deliveryDate,
        deliveryTime,
        deliverySurcharge,
        planzerDeliverySurcharge
    };
};

const  formatDateIgnoringTimeZone = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);

    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + '.000Z';
  }

// Hate to bring up all function fonctionality, but function structure left me no choice to access 'data'
const onShippingSuccess = async (args, callback, instance) => {
    const {
        saveAddressInformation,
        updateShippingFields,
        addressLinesQty,
        selectedStoreAddress
    } = instance.props;

    const {
        deliveryDate: unformattedDeliveryDate = {}, deliveryTime = {}
    } = instance.state;

    const delivery_surcharge = deliveryTime.surcharge ? deliveryTime.surcharge : 0;
    const delivery_time = JSON.stringify(deliveryTime);

    const fields = args[0];

    const {
        selectedCustomerAddressId,
        selectedShippingMethod
    } = instance.state;

    const formFields = getFormFields(fields, addressLinesQty);

    const shippingAddress = selectedCustomerAddressId
        ? instance._getAddressById(selectedCustomerAddressId)
        : trimAddressFields(formFields);

    const shippingAddressWithDeliveryDates = selectedShippingMethod.carrier_code === 'planzerpaket' ? {
        ...shippingAddress,
        delivery_date: formatDateIgnoringTimeZone(unformattedDeliveryDate),
        delivery_time,
        delivery_surcharge
    } : {
        ...shippingAddress
    };

    const {
        carrier_code: shipping_carrier_code,
        method_code: shipping_method_code
    } = selectedShippingMethod;

    const isInStoreDelivery = Object.keys(selectedStoreAddress).length > 0;

    const data = {
        // eslint-disable-next-line max-len
        billing_address: isInStoreDelivery ? instance.getStoreAddress(shippingAddress, true) : shippingAddressWithDeliveryDates,

        // eslint-disable-next-line max-len
        shipping_address: isInStoreDelivery ? instance.getStoreAddress(shippingAddress) : shippingAddressWithDeliveryDates,
        shipping_carrier_code,
        shipping_method_code
    };

    saveAddressInformation(data);
    const shippingMethod = `${shipping_carrier_code}_${shipping_method_code}`;
    updateShippingFields({ ...fields, shippingMethod });
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-property': {
            state,
            containerFunctions
        },
        'member-function': {
            containerProps,
            onShippingSuccess
        }
    }
};
