import Event, { EVENT_FB_PRODUCT_ADD_TO_CART } from '../util/Event';
import BaseEvent from './BaseEvent.event';

export const SPAM_PROTECTION_DELAY = 200;
/**
 * Product add to cart event
 * @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/AddToCart/Event/AddToCartEvent */
export class AddToCartEvent extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_FB_PRODUCT_ADD_TO_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            massAddAction = false,
            isFromCart = false,
            isGrouped = false,
            isItem = false
        }) => {
            this.handle(
                { configurableVariantIndex, ...product },
                quantity || 1,
                isItem,
                isGrouped,
                isFromCart,
                massAddAction
            );
        });
    }

    /**
     * Handle product add to cart
     */
    handler(product, quantity, isItem, isGrouped, isFromCart, massAddAction) {
        if (!massAddAction && this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const products = [];

        if (isGrouped) {
            const { items, quantities, attributes: parentAttributes } = product;
            // eslint-disable-next-line fp/no-let
            let groupedProductPrice = 0;

            items.forEach(
                ({ product }) => {
                    const { id, attributes } = product;
                    const attributesToPass = attributes.reduce((acc, attribute) => {
                        const { attribute_code } = attribute;
                        return {
                            ...acc,
                            [attribute_code]: {
                                ...parentAttributes[attribute_code],
                                ...attribute
                            }
                        };
                    }, {});

                    const productToPush = {
                        ...this.ProductHelper.getProductData({
                            // pass attributes to every children
                            ...product,
                            attributes: attributesToPass
                        }, true),
                        quantity: quantities[id]
                    };

                    const { price, quantity } = productToPush;
                    groupedProductPrice += price * quantity;

                    products.push(productToPush);
                }
            );

            const groupedProductData = {
                ...this.ProductHelper.getProductData({ ...product, groupedProductPrice }),
                quantity: 0
            };

            this.ProductHelper.addGroupedProduct(groupedProductData, product, groupedProductPrice);
            products.push({
                ...groupedProductData
            });
        } else {
            const { type_id } = product;
            const productData = isItem
                ? this.ProductHelper.getItemData(product)
                : this.ProductHelper.getProductData(product, type_id === 'configurable');

            products.push({
                ...productData,
                quantity
            });

            if (isFromCart) {
                const { id, price } = productData;
                this.ProductHelper.updateGroupedProduct(id, price * quantity);
            }
        }
        products.forEach((product) => {
            // configurable variants are tracked as 'dimension3', id is config parent SKU
            const sku = product.dimension3 ? product.dimension3 : product.id;
            if (
                (sku && sku.length)
                && (product.quantity)
                && (product.price)
            ) {
                this.fbConversionApiAddProduct(sku, product.quantity, product.price);
            }
        });
    }

    checkForCustomerIdData() {
        if (this.isSignedIn()
        && (!(this.customerIdData.customerEmail.length) || !(this.customerIdData.customerPhone.length))
        ) {
            this.initFacebookConversionApi();
        }
    }

    fbConversionApiAddProduct(sku, quantity, price) {
        this.checkForCustomerIdData();

        const eventId = this.generateEventId();
        const currencyCode = this.getCurrencyCode();
        const sourceUrl = window.location.href;
        this.fbConversionApiObject.addProduct(sku, quantity);
        this.fbConversionApiObject.sendEvent(
            'AddToCart', sourceUrl, {
                value: price,
                currency: currencyCode
            },
            { eventId }
        );
    }
}

export default AddToCartEvent;
