import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';

import './MultichannelOrderToolbar.style';

/** @namespace Screenpages/Multichannelorder/Component/MultichannelOrderToolbar/Component/MultichannelOrderToolbarComponent */
export class MultichannelOrderToolbarComponent extends PureComponent {
    static propTypes = {
        monthsArray: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })).isRequired,
        yearsArray: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleSubmitSuccess: PropTypes.func.isRequired,
        handleSubmitError: PropTypes.func.isRequired,
        isRequestEnabled: PropTypes.bool,
        defaultDate: PropTypes.instanceOf(Date).isRequired
    };

    static defaultProps = {
        isRequestEnabled: false
    };

    renderMonthsDropdown() {
        const { monthsArray, defaultDate } = this.props;
        return (
            <div block="MultichannelOrderToolbar" elem="MonthsDropdown">
                <Field
                  type="select"
                  label={ __('Select month') }
                  name="month"
                  value={ defaultDate.getMonth() }
                  id="month"
                  selectOptions={ monthsArray }
                />
            </div>
        );
    }

    renderYearsDropdown() {
        const { yearsArray, defaultDate } = this.props;
        return (
            <div block="MultichannelOrderToolbar" elem="YearsDropdown">
                <Field
                  type="select"
                  label={ __('Select year') }
                  name="year"
                  id="year"
                  value={ defaultDate.getFullYear() }
                  selectOptions={ yearsArray }
                />
            </div>
        );
    }

    renderSbmBtn() {
        return (
            <button
              block="Button"
            >
            <span>{ __('Search') }</span>
            </button>
        );
    }

    renderForm() {
        const {
            handleSubmit, handleSubmitSuccess,
            handleSubmitError
        } = this.props;

        return (
            <Form
              mix={ { block: 'MultichannelOrderToolbar', elem: 'Form' } }
              key="multichannelorderform"
              onSubmit={ handleSubmit }
              onSubmitSuccess={ handleSubmitSuccess }
              onSubmitError={ handleSubmitError }
            >
                { this.renderMonthsDropdown() }
                { this.renderYearsDropdown() }
                { this.renderSbmBtn() }
            </Form>
        );
    }

    render() {
        const { isRequestEnabled } = this.props;
        return isRequestEnabled ? (
            <div block="MultichannelOrderToolbar">
                { this.renderForm() }
            </div>
        ) : null;
    }
}

export default MultichannelOrderToolbarComponent;
