export class CategoryPagePlugin {
    addSharedCatalogIdToFromStateToProps(args, callback) {
        const [state] = args;

        return {
            ...callback(...args),
            sharedCatalogId: state.MyAccountReducer.customer.group_id
        };
    }

    aroundComponentDidUpdate(args, callback, obj) {
        const [prevProps] = args;
        const { props } = obj;
        const {
            sharedCatalogId, requestCategory, isSearchPage, categoryIds
        } = props;

        const { sharedCatalogId: prevSharedCatalogId } = prevProps;

        callback(...args);

        if (sharedCatalogId !== prevSharedCatalogId) {
            requestCategory({
                isSearchPage,
                categoryIds
            });
        }
    }

    aroundContainerProps(args, callback, obj) {
        const { props: { sharedCatalogId } } = obj;
        return {
            ...callback(...args),
            sharedCatalogId
        };
    }
}

const {
    addSharedCatalogIdToFromStateToProps,
    aroundComponentDidUpdate,
    aroundContainerProps
} = new CategoryPagePlugin();

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: addSharedCatalogIdToFromStateToProps
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate,
            containerProps: aroundContainerProps
        }
    },
    'Component/CategoryProductList/Container/mapStateToProps': {
        function: addSharedCatalogIdToFromStateToProps
    },
    'Component/CategoryProductList/Container': {
        'member-function': {
            containerProps: aroundContainerProps
        }
    }
};
