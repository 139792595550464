/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_FB_CHECKOUT = 'fb_checkout';
export const EVENT_FB_PURCHASE = 'fb_purchase';
export const EVENT_FB_PRODUCT_ADD_TO_CART = 'fb_product_add_to_cart';
export const EVENT_FB_PAGE_VIEW_INIT = 'fb_page_view_init';
export const EVENT_FB_IMPRESSIONS = 'fb_impressions';
