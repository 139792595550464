/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'Store/Config/Config.action';

import ReCaptchaQuery from '../query/ReCaptcha.query';

export const addToPrepareRequest = (args, callback) => ([
    ...callback(...args),
    ReCaptchaQuery.getData(),
]);

export const addReCaptchaConfigToState = (args, callback) => ({
    ...callback(...args),
    googleReCaptcha: {
        locations: [],
        types_config: [],
    },
});

export const getReCaptchaConfigFromAction = (args, callback) => {
    const [, action] = args;
    const { type, config: { googleReCaptcha } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        googleReCaptcha,
    };
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addReCaptchaConfigToState,
    },
    'Store/Config/Reducer': {
        function: getReCaptchaConfigFromAction,
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addToPrepareRequest,
        },
    },
};
