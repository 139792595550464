import { Field } from 'Util/Query';
/** @namespace Screenpages/b2bcompany/Query/B2bCompanyProfie/Query/B2bCompanyProfieQuery */
export class B2bCompanyProfieQuery {
    setCompanyInviteAcceptance(input) {
        return new Field('b2bCompanyAcceptInvite')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('input', 'B2bAcceptInviteInput', input);
    }

    getCompanyProfileData() {
        return new Field('getB2bCompanyInformation')
            .addField(this._getCompanyProfileFields())
            .addField(this._getUserGridFields())
            .addField(this._getCompanyRoleGridFields())
            .addField(this.getB2bAuthFields());
    }

    setUpdateCompanyProfileMutation(fields) {
        return new Field('b2bSetUpdateCompanyProfileData')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('input', 'B2bSaveCompanyInput', fields);
    }

    setUpdateCompanyUser(entity_id, fields) {
        const mutation = new Field('b2bSaveCompanyCustomer')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('input', 'B2bCompanyCustomrerInput', fields);

        if (entity_id) {
            mutation.addArgument('userid', 'Int', entity_id);
        }

        return mutation;
    }

    setUpdateCompanyRole(role_id, fields) {
        const mutation = new Field('b2bSaveCompanyRole')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('input', 'B2bRoleInput', fields);

        if (role_id) {
            mutation.addArgument('roleid', 'Int', role_id);
        }

        return mutation;
    }

    setDeleteCompanyUser(user_id) {
        return new Field('b2bDeleteCompanyCustomer')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('userid', 'Int', user_id);
    }

    setDeleteCompanyRole(role_id) {
        return new Field('b2bDeleteCompanyRole')
            .addFieldList(this._getUpdateCompanyResponseFields())
            .addArgument('roleid', 'Int', role_id);
    }

    _getCompanyProfileFields() {
        return new Field('company_profile')
            .addFieldList([
                'company_name',
                'company_legal_name',
                'company_email',
                'company_contact_name',
                'company_contact_email',
                'company_salesrep_name',
                'company_salesrep_email',
                'vat_id',
                this._getCompanyAdressFields()
            ]);
    }

    _getCompanyAdressFields() {
        return new Field('legal_address')
            .addFieldList([
                'street',
                'city',
                'region',
                'region_id',
                'postcode',
                'country_code',
                'telephone'
            ]);
    }

    _getUpdateCompanyResponseFields() {
        return [
            'status',
            'message'
        ];
    }

    _getUserGridFields() {
        return new Field('users')
            .addFieldList([
                'entity_id',
                'firstname',
                'lastname',
                'job_title',
                'telephone',
                'email',
                'is_owner',
                'role_name',
                'role_id',
                'status'
            ]);
    }

    _getCompanyRoleGridFields() {
        return new Field('roles')
            .addFieldList([
                'role_id',
                'role_name',
                'permissions'
            ]);
    }

    getB2bAuthFields() {
        return new Field('b2bauth')
            .addFieldList([
                'code',
                'value'
            ]);
    }
}

export default new B2bCompanyProfieQuery();
