/**
 * Adobe Recommendations compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import ProductCard from 'Component/ProductCard';

import { UnitType } from '../../type/Recommendations.type';

import './RecommendationUnit.style';

/** @namespace Scandiweb/AdobeRecommendations/Component/RecommendationUnit/Component/RecommendationUnitComponent */
export class RecommendationUnitComponent extends PureComponent {
    static propTypes = {
        unit: UnitType.isRequired
    };

    renderTitle() {
        const { unit: { storefrontLabel } } = this.props;

        if (!storefrontLabel) {
            return null;
        }

        return (
            <h2 block="RecommendationUnit" elem="Title">
                { storefrontLabel }
            </h2>
        );
    }

    renderProduct = (product) => (
        <ProductCard key={ product.id } product={ product } />
    );

    renderProducts() {
        const { unit: { products } } = this.props;

        return (
            <div block="RecommendationUnit" elem="Products">
                { products.map(this.renderProduct) }
            </div>
        );
    }

    render() {
        return (
            <div block="RecommendationUnit">
                { this.renderTitle() }
                { this.renderProducts() }
            </div>
        );
    }
}

export default RecommendationUnitComponent;
