import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace Rapelli/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    // TODO implement logic

    render() {
        const {
            mix, type, message, validationStatus
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default FieldComponent;
