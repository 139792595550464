import { B2B_CHECKOUT_URL } from '../route/B2bCheckout/B2bCheckout.config';

export const B2BCHECKOUT_TAB = 'B2BCheckoutTab';
/** @namespace Screenpages/b2bquote/Plugin/HeaderPlugin/Plugin/HeaderPlugin */
export class HeaderPlugin {
  changeCheckoutCheck = (args, callback, instance) => {
      const { history } = instance.props;
      const { location: { pathname } } = history;
      const result = callback(...args);
      const isB2bCheckout = pathname.includes(B2B_CHECKOUT_URL) && !pathname.includes('success');
      const { isCheckout } = result;
      const isAnyCheckout = isB2bCheckout || isCheckout;
      return {
          ...result,
          isCheckout: isAnyCheckout
      };
  };
}

const { changeCheckoutCheck } = new HeaderPlugin();

export default {
    'Component/Header/Container': {
        'member-function': {
            containerProps: changeCheckoutCheck
        }
    }
};
