import Event, { EVENT_FB_PAGE_VIEW_INIT } from '../util/Event';
// eslint-disable-next-line import/no-cycle
import BaseEvent from './BaseEvent.event';

export const GENERAL_EVENT_DELAY = 500;

/**
 * Handles Page Load and Page Navigation
 * @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/PageView/Event/PageViewEvent */
export class PageViewEvent extends BaseEvent {
    /**
     * If already loading data, do not do second request
     *
     * @type {boolean}
     */
    isLoadingData = false;

    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Current meta data
     *
     * @type {{}}
     */
    currentMeta = { title: '' };

    prevLocation = '';

    saveCartDataToStorage() {
        const storage = this.getStorage() || {};
        storage.cartLast = storage.cart;
        storage.cart = this.getCartTotals().items;
        this.setStorage(storage);
    }

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        setTimeout(() => {
            this.saveCartDataToStorage();
            this.handle();
        }, GENERAL_EVENT_DELAY);

        const { history } = this.getProps();

        this.prevLocation = history.location;
        history.listen((location) => {
            const { pathname } = location;
            const { pathname: prevPathname } = this.prevLocation;

            // prevents from firing general on filter change (PLP) and on attribute change (PDP)
            if (
                pathname === prevPathname
            ) {
                return;
            }

            this.saveCartDataToStorage();
            this.prevLocation = location;

            setTimeout(() => {
                this.handle();
            }, GENERAL_EVENT_DELAY);
        });
    }

    // track landing page, products on page

    // product impressions
    fbConversionApiViewContent(cartTotal) {
        const eventId = this.generateEventId();
        const currencyCode = this.getCurrencyCode();
        const sourceUrl = window.location.href;

        this.fbConversionApiObject.sendEvent(
            'ViewContent', sourceUrl, {
                value: cartTotal,
                currency: currencyCode
            },
            { eventId }
        );
    }

    addCartProducts(items) {
        items.forEach((item) => {
            this.fbConversionApiObject.addProduct(item.id, item.quantity);
        });
    }

    /**
     * Handler General
     */
    handler() {
        Event.dispatch(EVENT_FB_PAGE_VIEW_INIT, {});
        const cartData = this.prepareCartData();
        if (cartData.itemsData && cartData.itemsData.length) {
            this.addCartProducts(cartData.itemsData);
        }
        const cartTotal = cartData.total ?? 0;
        this.fbConversionApiViewContent(
            cartTotal
        );
    }
}

export default PageViewEvent;
