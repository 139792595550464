export class ProductPagePlugin {
    addSharedCatalogIdToFromStateToProps(args, callback) {
        const [state] = args;

        return {
            ...callback(...args),
            sharedCatalogId: state.MyAccountReducer.customer.group_id
        };
    }

    aroundComponentDidUpdate(args, callback, obj) {
        const [prevProps] = args;
        const { props } = obj;
        const { sharedCatalogId } = props;

        const { sharedCatalogId: prevSharedCatalogId } = prevProps;

        callback(...args);

        if (sharedCatalogId !== prevSharedCatalogId) {
            obj.requestProduct();
        }
    }
}

const { addSharedCatalogIdToFromStateToProps, aroundComponentDidUpdate } = new ProductPagePlugin();

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        function: addSharedCatalogIdToFromStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate
        }
    }
};
