import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import Popup from 'Component/Popup';

import { singleQuoteType } from '../../type/B2bQuote';
import QuoteComments from '../QuoteComments';
import QuoteViewActions from '../QuoteViewActions';
import QuoteViewItem from '../QuoteViewItem';
import { QUOTE_VIEW_POPUPID } from './QuoteViewPopup.config';

import './QuoteViewPopup.style';

/** @namespace Screenpages/b2bquote/Component/QuoteViewPopup/Component/QuoteViewPopupComponent */
export class QuoteViewPopupComponent extends PureComponent {
    static propTypes = {
        QuoteData: singleQuoteType,
        isLoaded: PropTypes.bool.isRequired,
        handleSubmitNewComment: PropTypes.func.isRequired,
        handleCloseQuote: PropTypes.func.isRequired,
        handleDeleteQuote: PropTypes.func.isRequired,
        formatDate: PropTypes.func.isRequired
    };

    static defaultProps = {
        QuoteData: {}
    };

    renderNotOwner() {
        const { QuoteData: { restrictions: { is_owner } = false } } = this.props;
        if (!is_owner) {
            return (
                <div block="QuoteViewPopup" elem="NotOwner">
                    <p>{ __('You are not the owner of this quote') }</p>
                    <p>{ __('You can not complete any actions on this quote') }</p>
                </div>
            );
        }

        return null;
    }

    renderQuoteTop() {
        const { QuoteData, formatDate } = this.props;

        const {
            created_at,
            quote_owner,
            sales_rep
        } = QuoteData;

        const {
            QuoteData: {
                negotiated_quote: {
                    status_label,
                    expiration_period,
                    order_number,
                    order_id
                }
            } = {}
        } = this.props;

        const CreatedField = `${ formatDate(created_at) } ${ quote_owner}`;

        const expires = !!order_id && expiration_period ? formatDate(expiration_period) : '';

        return (
            <div block="QuoteViewPopup" elem="QuoteHeader">
                { this.renderTextBlock(__('Created'), CreatedField) }
                { this.renderTextBlock(__('Expires'), expires) }
                { this.renderTextBlock(__('Sales Rep'), sales_rep) }
                { this.renderTextBlock(__('Status'), status_label) }
                { this.renderTextBlock(__('Order Number'), order_number) }
            </div>
        );
    }

    renderTextBlock(label, value) {
        const key = label.replace(/ /g, '');
        if (value === '') {
            return null;
        }

        return (
            <dl key={ key }>
                <dt>
                    { label }
                </dt>
                <dd block="QuoteViewPopup" elem="TextValue">
                    { value }
                </dd>
            </dl>
        );
    }

    renderItems() {
        const {
            QuoteData: {
                items, quote_currency_code
            } = {}
        } = this.props;

        return (
            <ExpandableContent
              key="quoteitems"
              heading={ __('Items') }
              mix={ { block: 'QuoteViewPopup', elem: 'Expandable' } }
              isContentExpanded
            >
                <div block="QuoteViewPopup" elem="Items">
                    <table block="QuoteViewPopup" elem="Products">
                        <thead>
                            <tr>
                                <th block="ProductsHeader" elem="ImgWrapper">{ __('Image') }</th>
                                <th block="ProductsHeader" elem="ProductData">{ __('Name') }</th>
                                <th block="ProductsHeader" elem="Qty">{ __('Qty') }</th>
                                <th block="ProductsHeader" elem="Price">{ __('Total') }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { items.map((item) => (
                                <QuoteViewItem
                                  key={ item.item_id }
                                  QuoteItem={ item }
                                  CurrencyCode={ quote_currency_code }
                                />
                            )) }
                        </tbody>
                    </table>
                </div>
            </ExpandableContent>
        );
    }

    renderComments() {
        const {
            formatDate,
            handleSubmitNewComment,
            QuoteData: {
                comments,
                restrictions: {
                    can_submit
                }
            } = {}
        } = this.props;

        return (
            <ExpandableContent
              key="quotecomments"
              heading={ __('Comments') }
              mix={ { block: 'QuoteViewPopup', elem: 'Expandable' } }
              isContentExpanded={ false }
            >
                <QuoteComments
                  QuoteComments={ comments }
                  formatDate={ formatDate }
                  handleSubmitNewComment={ handleSubmitNewComment }
                  isAbleToSubmit={ can_submit }
                />
            </ExpandableContent>
        );
    }

    renderShipping() {
        const { QuoteData: { shipping_address } = {} } = this.props;
        if (!shipping_address) {
            return null;
        }

        return (
            <ExpandableContent
              key="quoteshipping"
              heading={ __('Shipping') }
              mix={ { block: 'QuoteViewPopup', elem: 'Expandable' } }
              isContentExpanded={ false }
            >
                <MyAccountAddressTable
                  title={ __('Shipping address') }
                  address={ shipping_address }
                  mix={ { block: 'MyAccountOrderPopup', elem: 'Address' } }
                />
            </ExpandableContent>
        );
    }

    renderQuoteActions() {
        const { isLoaded } = this.props;
        if (!isLoaded) {
            return null;
        }
        const {
            handleCloseQuote, handleDeleteQuote, QuoteData,
            QuoteData: { quote_id, negotiated_quote, restrictions } = {}
        } = this.props;

        return (
            <QuoteViewActions
              QuoteId={ quote_id }
              QuoteData={ QuoteData }
              NegotiatedQuote={ negotiated_quote }
              handleCloseQuote={ handleCloseQuote }
              handleDeleteQuote={ handleDeleteQuote }
              Restrictions={ restrictions }
            />
        );
    }

    renderPopupContent() {
        const { isLoaded } = this.props;
        if (isLoaded) {
            return (
                <div block="QuoteViewPopup" elem="ContentWrapper">
                    { this.renderNotOwner() }
                    { this.renderQuoteTop() }
                    { this.renderItems() }
                    { this.renderComments() }
                    { this.renderShipping() }
                    { this.renderQuoteActions() }
                </div>
            );
        }

        return (<Loader isLoading={ !isLoaded } />);
    }

    render() {
        return (
            <Popup
              id={ QUOTE_VIEW_POPUPID }
              mix={ { block: 'QuoteViewPopup' } }
            >
                { this.renderPopupContent() }
            </Popup>
        );
    }
}

export default QuoteViewPopupComponent;
