export const B2BCOMPANY_ACTION_SET_PROFILE_DATA = 'B2BCOMPANY_ACTION_SET_PROFILE_DATA';
export const B2BCOMPANY_ACTION_SET_NO_RESULTS = 'B2BCOMPANY_ACTION_SET_NO_RESULTS';
export const B2BCOMPANY_ACTION_CLEAR = 'B2BCOMPANY_ACTION_CLEAR';
export const B2BCOMPANY_SET_SHAREDCATALOG_RELOAD = 'B2BCOMPANY_SET_SHAREDCATALOG_RELOAD';

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Action/setCompanyProfileData */
export const setCompanyProfileData = (companyProfileData) => ({
    type: B2BCOMPANY_ACTION_SET_PROFILE_DATA,
    companyProfileData
});

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Action/setCompanyError */
export const setCompanyError = () => ({
    type: B2BCOMPANY_ACTION_SET_NO_RESULTS
});

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Action/clearCompanyProfile */
export const clearCompanyProfile = () => ({
    type: B2BCOMPANY_ACTION_CLEAR
});

/** @namespace Screenpages/b2bcompany/Store/B2bCompany/Action/setSharedCatalogReloadStatus */
export const setSharedCatalogReloadStatus = (status) => ({
    type: B2BCOMPANY_SET_SHAREDCATALOG_RELOAD,
    status
});
