import { AUTH_TOKEN } from 'SourceUtil/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

export * from 'SourceUtil/Auth/Token';

/**
 * Overridden to change ONE_HOUR from seconds to hours and added one hour in seconds.
 */
export const ONE_HOUR = 3600;
export const ONE_HOUR_IN_HOURS = 1;

/**
 * Overridden to change cookie life time to access token life time.
 * @namespace Rapelli/Util/Auth/Token/setAuthorizationToken
 */
export const setAuthorizationToken = (token) => {
    if (!token) {
        return;
    }

    const state = getStore().getState();
    const {
        access_token_lifetime = ONE_HOUR_IN_HOURS
    } = state.ConfigReducer;

    BrowserDatabase.setItem(token, AUTH_TOKEN, access_token_lifetime * ONE_HOUR);
};
