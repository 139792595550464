import { roundPrice } from 'Util/Price';

import Event, { EVENT_FB_PURCHASE } from '../util/Event';
import BaseEvent from './BaseEvent.event';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/Purchase/Event/PurchaseEvent */
export class PurchaseEvent extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_FB_PURCHASE, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.fbConversionApiPurchase(
            orderId,
            totals
        );
    }

    getBasicCartData(totals) {
        const basicCartData = totals.items.map((item) => ({
            id: item.sku,
            quantity: item.qty,
            item_price: item.price
        }));

        return basicCartData;
    }

    getCustomCartData(totals, couponCode) {
        const result = totals.items.map((item) => ({
            id: item.sku,
            name: item.product.name,
            coupon: couponCode,
            discount: item.discount_amount,
            brand: this.ProductHelper.getBrand(item.product),
            variant: this.ProductHelper.getWeightWithLabel(item.product),
            item_price: item.price,
            quantity: item.qty,
            productType: item.product.type_id
        }));

        return result;
    }

    fbConversionApiPurchase(orderId, totals) {
        const currencyCode = this.getCurrencyCode();
        const sourceUrl = window.location.href;
        const purchaseGrandTotal = Number(+roundPrice(totals.grand_total));
        const couponCode = totals.coupon_code || '';
        const cartContents = this.getBasicCartData(totals);
        const cartData = this.getCustomCartData(totals, couponCode);
        const numItems = totals.items.length;
        this.fbConversionApiObject.sendEvent(
            'Purchase', sourceUrl, {
                content_type: 'product',
                contents: cartContents,
                value: purchaseGrandTotal,
                currency: currencyCode,
                num_items: numItems,
                custom_properties: {
                    items_data: cartData
                }
            },
            { eventId: orderId }
        );
    }
}

export default PurchaseEvent;
