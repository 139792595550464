import { isSignedIn } from 'Util/Auth';
import { getIndexedProducts } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

import MultichannelOrderToolbar from '../component/MultichannelOrderToolbar';
import OrderQuery from '../query/Order.query';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Order/Order.dispatcher'
);
export class MyAccountPlugin {
    addMultichannelFieldsToCustomerQuery = (args, callback) => [
        ...callback(...args),
        'canUserRequestMultichannelOrders'
    ];

    aroundRequestOrderDetails = async (args, callback, instance) => {
        const {
            props: {
                payload: {
                    order: {
                        base_order_info: {
                            multichannel_order,
                            id
                        } = {}
                    }
                }
            }
        } = instance;

        if (!multichannel_order) {
            return callback(args);
        }
        if (!isSignedIn()) {
            return null;
        }

        const { getMultichannelOrderById = false } = await this.getMultichannelOrderById(id);

        if (!getMultichannelOrderById) {
            instance.setState({
                isLoading: false
            });
            instance.props.showNotification('error', __('Order not found'));
            return null;
        }

        const { order_products = [] } = getMultichannelOrderById;
        const indexedProducts = getIndexedProducts(order_products);
        const order = { ...getMultichannelOrderById, order_products: indexedProducts };
        const { base_order_info: { currency_code } = {} } = order;
        instance.setState({
            isLoading: false,
            currency_code,
            order
        });

        return null;
    };

    async getMultichannelOrderById(id) {
        try {
            return await fetchQuery(OrderQuery.getMultichannelOrderById(id));
        } catch (error) {
            return { getMultichannelOrderById: false };
        }
    }

    aroundRenderTable = (args, callback, _instance) => (
        <>
            <MultichannelOrderToolbar />
            { callback(args) }
        </>
    );

    changegetOrderList(args, _callback) {
        const [dispatch] = args;
        return {
            getOrderList: (from_date = '') => OrderDispatcher.then(
                ({ default: dispatcher }) => dispatcher.requestOrdersWithDates(from_date, dispatch)
            )
        };
    }

    aroundCanRenderReOrderBtn = (args, callback, instance) => {
        const {
            props: {
                payload: {
                    order: {
                        base_order_info: {
                            multichannel_order
                        } = {}
                    } = {}
                } = {}
            }
        } = instance;

        if (!multichannel_order) {
            return callback(args);
        }

        return false;
    };
}

const {
    addMultichannelFieldsToCustomerQuery,
    aroundRequestOrderDetails,
    aroundRenderTable,
    changegetOrderList,
    aroundCanRenderReOrderBtn
} = new MyAccountPlugin();

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: addMultichannelFieldsToCustomerQuery
        }
    },
    'Component/MyAccountOrderPopup/Container': {
        'member-function': {
            requestOrderDetails: aroundRequestOrderDetails,
            canRenderReOrderBtn: aroundCanRenderReOrderBtn
        }
    },
    'Component/MyAccountMyOrders/Component': {
        'member-function': {
            renderTable: aroundRenderTable
        }
    },
    'Component/MyAccountMyOrders/Container/mapDispatchToProps': {
        function: changegetOrderList
    }
};
