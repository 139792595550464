/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

// https://www.morlandfr.test/company/customer/acceptinvitation/?code=7118738801ed34a2ed78634f2e3bbf4082fa0a0e6084b71db5d16743f86c5a40&customer%5Bjob_title%5D=testing&customer%5Btelephone%5D=1234&customer%5Bstatus%5D=1&customer%5Bcustomer_id%5D=5&customer%5Bcompany_id%5D=1
export const B2B_ACCEPT_INVITE_URL = '/company/customer/acceptinvitation';
export const B2bAcceptCompanyInvitePage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "acceptcompanyinvite" */ '../route/AcceptInvitePage')
);
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

/** @namespace Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export class RoutePlugin {
    aroundSwitchItemsType = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`${ B2B_ACCEPT_INVITE_URL }/:input?`) } render={ (props) => <B2bAcceptCompanyInvitePage { ...props } /> } />,
            position: 100
        },
        {
            component: <Route path={ withStoreRegex('/customer/account*') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 120
        }
    ];

    addB2bAcceptInviteToRouteMap = (member) => ({
        ...member,
        '/company/customer/': { name: 'B2B_ACCEPT_INVITE_URL', isHidden: false }
    });
}

const { addB2bAcceptInviteToRouteMap, aroundSwitchItemsType } = new RoutePlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: [
                {
                    position: 100,
                    implementation: aroundSwitchItemsType
                }
            ]
        }
    },
    'Component/NavigationTabsContainer/Container': {
        'member-property': {
            routeMap: addB2bAcceptInviteToRouteMap
        }
    }
};

export default config;
