import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';

/** @namespace Rapelli/Component/MyAccountAddressTable/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Rapelli/Component/MyAccountAddressTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Rapelli/Component/MyAccountAddressTable/Container/MyAccountAddressTableContainer */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
