import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { CompanyRoleGridEntryType } from '../../type/B2bCompany';
import { COMPANY_EDIT_POPUP, COMPANY_FORM_EDIT_ROLE } from '../CompanyEditPopup/CompanyEditPopup.config';
import CompanyRolesRow from './CompanyRolesRow.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesRow/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({ });

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showEditRoleForm: (payload) => _dispatch(showPopup(COMPANY_EDIT_POPUP, payload))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesRow/Container/CompanyRolesRowContainer */
export class CompanyRolesRowContainer extends PureComponent {
    static propTypes = {
        Roles: CompanyRoleGridEntryType.isRequired,
        showEditRoleForm: PropTypes.func.isRequired,
        isAllowedEditRole: PropTypes.bool
        // showEditRolesPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        isAllowedEditRole: false
    };

    containerFunctions = {
        editCurrentRole: this._editCurrentRole.bind(this)
    };

    containerProps = () => {
        const { Roles } = this.props;
        return {
            Roles
        };
    };

    _editCurrentRole() {
        const {
            isAllowedEditRole, showEditRoleForm, Roles,
            Roles: { role_id, role_name } = 0
        } = this.props;

        if (!isAllowedEditRole) {
            return;
        }
        if (role_id) {
            showEditRoleForm({
                title: __('Edit Role %s', role_name),
                type: COMPANY_FORM_EDIT_ROLE,
                roleData: Roles
            });
        }
    }

    render() {
        return (
            <CompanyRolesRow
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRolesRowContainer);
