import ProductLabelWrapper from "../component/ProductLabelWrapper";

export class ProductImagePlugin {
    aroundRenderPicture = (args, callback, instance) => {
        const {
            product: { mp_label_data = [] },
        } = instance.props;

        const filtred_mp_label_data = [...mp_label_data].reduce(
            (acc, item) => {
                
                if (acc.idArr[item.rule_id]) {
                    return acc;
                }

                acc.objArr.push(item);
                acc.idArr[item.rule_id] = true;

                return acc;
            },
            {
                objArr: [],
                idArr: {},
            }
        );

        return (
            <>
                <ProductLabelWrapper
                    ProductLabelData={filtred_mp_label_data.objArr}
                />
                {callback.apply(instance, args)}
            </>
        );
    };

    addLabelDataToGallery = (args, callback, instance) => {
        const {
            product: { mp_label_data = [] },
        } = instance.props;
        return {
            ...callback(args),
            MPLabelData: mp_label_data,
        };
    };

    aroundRenderSlider = (args, callback, instance) => {
        const { MPLabelData = [] } = instance.props;
        return (
            <>
                {callback(args)}
                <ProductLabelWrapper
                    ProductLabelData={MPLabelData}
                    isProductPage
                />
            </>
        );
    };
}

const { aroundRenderPicture, addLabelDataToGallery, aroundRenderSlider } =
    new ProductImagePlugin();

export default {
    "Component/ProductCard/Component": {
        "member-function": {
            renderPicture: {
                position: 100,
                implementation: aroundRenderPicture,
            },
        },
    },
    "Component/ProductGallery/Container": {
        "member-function": {
            containerProps: {
                position: 100,
                implementation: addLabelDataToGallery,
            },
        },
    },
    "Component/ProductGallery/Component": {
        "member-function": {
            renderSlider: {
                position: 100,
                implementation: aroundRenderSlider,
            },
        },
    },
};
