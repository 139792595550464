/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { formatPrice } from 'Util/Price';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';

import { KG_CALCULATION } from './ProductCard.config';

import './ProductCard.override.style';

/** @namespace Rapelli/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        unitMeasure: PropTypes.string
    };

    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                options = [],
                variants
            },
            configurableVariantIndex,
            layout,
            inStock
        } = this.props;

        const quantity = 1;
        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions
        };

        if (type_id === BUNDLE || type_id === GROUPED || type_id === CONFIGURABLE) {
            if (variants.length > 1) {
                return (
                    <>
                        { this.renderCardLinkWrapper((
                            <button
                              block="Button AddToCart"
                              mods={ { layout } }
                            >
                                <span>{ __('Add To Cart') }</span>
                            </button>
                        )) }
                    </>
                );
            }
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
              disabled={ !inStock }
              layout={ layout }
            />
        );
    }

    // renderVisibleOnHover() {
    //     const { device } = this.props;

    //     if (device.isMobile) {
    //         return null;
    //     }

    //     return (
    //         <>
    //             { this.renderConfigurableOptions() }
    //             <div block="ProductCard" elem="Footer">
    //                 { this.renderAddToCart() }
    //                 { this.renderProductActions() }
    //             </div>
    //         </>
    //     );
    // }

    renderConfigurableOptions() {
        const {
            parameters,
            updateConfigurableVariant,
            product: { variants },
            isLoading,
            inStock
        } = this.props;

        return (
            <ProductConfigurableAttributes
              configurable_options={ this.getAttributesToShow() }
              updateConfigurableVariant={ updateConfigurableVariant }
              parameters={ parameters }
              variants={ variants }
              isExpandable={ false }
              isReady={ !isLoading }
              showProductAttributeAsLink={ false }
              inStock={ inStock }
            />
        );
    }

    renderVisibleOnHover() {
        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { this.renderAddToCart() }
                    { this.renderProductActions() }
                </div>
            </>
        );
    }

    renderProductCardCategory() {
        const {
            product: {
                product_card_category
            }
        } = this.props;

        return (
            <div block="ProductCard" elem="Category">
               { product_card_category }
            </div>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                { this.renderCardLinkWrapper((
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            { this.renderReviews() }
                            { this.renderAdditionalProductDetails() }
                            { this.renderMainDetails() }
                            { this.renderProductCardCategory() }
                            { this.renderPricePerKilo() }
                        </div>
                    </>
                )) }
                { this.renderProductIcons() }
                <div block="ProductCard" elem="isVisible">
                    { this.renderVisibleOnHover() }
                </div>
            </>
        );
    }

    renderProductPrice() {
        const {
            product: {
                price_range, price_tiers = [], type_id
            },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            unitMeasure
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                  price={ price_range }
                  price_tiers={ price_tiers }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                  label={ this.renderProductTypePriceBadge() }
                  unitMeasure={ unitMeasure }
                />
            </div>
        );
    }

    renderPricePerKilo() {
        const {
            product: {
                id,
                price_range,
                price_range: {
                    maximum_price: { regular_price: { currency: currency_code } = {} } = {}
                } = {},
                type_id,
                variants = {},
                attributes: {
                    product_price_unit: { attribute_options = {} } = {}
                } = {}
            },
            product,
            displayPricePerKG = 0
        } = this.props;

        if (!id) {
            return null;
        }

        const {
            // productPrice,
            inStock
        } = this.props;

        const stockStatusLabel = inStock ? __('In stock') : __('Out of stock');

        if (!inStock) {
            return (
                <span
                  block="ProductActions"
                  elem="Stock Out-Of-Stock"
                >
                    { stockStatusLabel }
                </span>
            );
        }

        const {
            minimum_price: {
                final_price: {
                    value: minFinalPrice = 0
                } = {},
                default_final_price: {
                    value: minDefaultFinalPrice = 0
                } = {}
            } = {},
            maximum_price: {
                final_price: {
                    value: maxFinalPrice = 0
                } = {},
                default_final_price: {
                    value: maxDefaultFinalPrice = 0
                } = {}
            } = {}
        } = price_range;

        let price = minFinalPrice > 0 ? minFinalPrice : maxFinalPrice;
        let defaultprice = minDefaultFinalPrice > 0 ? minDefaultFinalPrice : maxDefaultFinalPrice;
        let weight = 0;
        let weight_variability = 0;
        let product_price_unit = '';

        //                b2b_fields: { sales_unit_measure, price: b2b_price, old_price: b2b_old_price } = {}

        let { b2b_fields: { sales_unit_measure, price: b2b_price, old_price: b2b_old_price } } = product;

        if (type_id !== 'configurable') {
            weight = parseFloat(this.props.product.attributes.weight?.attribute_value) ?? 0;
            weight_variability = this.props.product.attributes.weight_variability?.attribute_value ?? '';
            product_price_unit = this.props.product.attributes.product_price_unit?.attribute_value ?? '';
            // eslint-disable-next-line max-len
            defaultprice = minDefaultFinalPrice === price && maxDefaultFinalPrice !== price ? maxDefaultFinalPrice : minDefaultFinalPrice;
        } else if (variants.length && type_id === 'configurable') {
            variants.sort((a, b) => a.attributes.weight.attribute_value - b.attributes.weight.attribute_value);
            weight = parseFloat(variants[0].attributes.weight.attribute_value) ?? 0;
            weight_variability = variants[0].attributes.weight_variability.attribute_value ?? '';
            const specialPrice = parseFloat(variants[0].attributes.special_price?.attribute_value) ?? 0;
            price = parseFloat(variants[0].attributes.price.attribute_value) ?? price;
            // products only accosicated with B2B stores have default price of 9999
            // this is handled in GQL with exception of here as this is pulled from the attribute of the options
            // eslint-disable-next-line no-magic-numbers
            price = price === 9999 ? minFinalPrice : price;
            defaultprice = price;
            if (specialPrice > 0 && specialPrice < price) {
                price = specialPrice;
            }
            product_price_unit = variants[0].attributes.product_price_unit?.attribute_value ?? '';
            sales_unit_measure = variants[0].b2b_fields.sales_unit_measure ?? '';
            b2b_price = variants[0].b2b_fields.price ?? '';
            b2b_old_price = variants[0].b2b_fields.old_price ?? '';
        }

        const PriceUnitString = attribute_options[product_price_unit]?.label || '';
        // const PriceUnitStringFixed = PriceUnitString !== '03' ? PriceUnitString : '';
        if ((!price || price.length === 0) || (!weight || weight.length === 0) || (parseFloat(weight) === 0)) {
            return null;
        }

        // eslint-disable-next-line fp/no-let
        let weightVariability = 0;
        if (weight_variability) {
            const weightVariabilityNumber = weight_variability.replace(/\D/g, '');
            // eslint-disable-next-line no-magic-numbers
            weightVariability = parseFloat(weightVariabilityNumber) / 1000;
        }

        const calculatePriceToDisplay = (weightVariability, price, weight) => {
            if (weight > 0) {
                if (weightVariability > 0) {
                    return price / (weight + weightVariability);
                }

                return price / weight;
            }

            return price;
        };

        const priceperkilo = calculatePriceToDisplay(
            weightVariability,
            price,
            parseFloat(weight)
        );

        const defaultpriceperkilo = calculatePriceToDisplay(
            weightVariability,
            defaultprice,
            parseFloat(weight)
        );

        const pricePerGram = priceperkilo / KG_CALCULATION;
        const defaultpricePerGram = defaultpriceperkilo / KG_CALCULATION;

        if (sales_unit_measure) {
            switch (sales_unit_measure.toLowerCase()) {
            case 'kg':
                return this.renderPriceInKg(b2b_price, b2b_old_price, currency_code);
            case 'pc':
                return this.renderPriceInUnit(b2b_price, b2b_old_price, currency_code, true);
            case '01':
            default:
                return this.renderPriceInKg(b2b_price, b2b_old_price, currency_code);
            }
        }
        if (product_price_unit) {
            switch (PriceUnitString) {
            case '02':
                return this.renderPriceInKg(priceperkilo, defaultpriceperkilo, currency_code);
            case '03':
                return this.renderPriceInUnit(price, defaultprice, currency_code);
            case '01':
            default:
                return this.renderPriceInG(pricePerGram, defaultpricePerGram, currency_code);
            }
        }

        if (displayPricePerKG) {
            return this.renderPriceInKg(priceperkilo, defaultpriceperkilo, currency_code);
        }

        return this.renderPriceInG(pricePerGram, defaultpricePerGram, currency_code);
    }

    renderPriceInKg(priceperkilo, defaultpriceperkilo, currency_code) {
        if (priceperkilo === defaultpriceperkilo) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductCard" elem="Price">
                        { __(formatPrice(priceperkilo, currency_code)) }
                        <span className="weight">{ __('/kg') }</span>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductCard" elem="Price">
                    { formatPrice(priceperkilo, currency_code) }
                    <span className="weight">{ __(' /kg') }</span>
                </div>
                <div block="DefaultProductCard" elem="Price">
                    <span>{ formatPrice(defaultpriceperkilo, currency_code) }</span>
                    <span className="weight">{ __(' /kg') }</span>
                </div>
            </div>
        );
    }

    renderPriceInG(pricePerGram, defaultpricePerGram, currency_code) {
        if (pricePerGram !== defaultpricePerGram) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductCard" elem="Price">
                        { formatPrice(pricePerGram, currency_code) }
                        <span className="weight">{ __(' /100g') }</span>
                    </div>
                    <div block="DefaultProductCard" elem="Price">
                        <span>{ formatPrice(defaultpricePerGram, currency_code) }</span>
                        <span className="weight">{ __(' /100g') }</span>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductCard" elem="Price">
                    { formatPrice(pricePerGram, currency_code) }
                    <span className="weight">{ __(' /100g') }</span>
                </div>
            </div>
        );
    }

    renderPriceInUnit(price, defaultprice, currency_code, showLbl = false) {
        if (price !== defaultprice) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductCard" elem="Price">
                        { formatPrice(price, currency_code) }
                        { this.renderPcLbl(showLbl) }
                    </div>
                    <div block="DefaultProductCard" elem="Price">
                        <span>
                            { formatPrice(defaultprice, currency_code) }
                            { this.renderPcLbl(showLbl) }
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductCard" elem="Price">
                    { formatPrice(price, currency_code) }
                    { this.renderPcLbl(showLbl) }
                </div>
            </div>
        );
    }

    renderPcLbl(showLbl) {
        const lblVal = showLbl ? __('pz') : '';

        return showLbl ? (
                <span className="weight">
                    /
                    { lblVal }
                </span>
        ) : null;
    }

    renderProductIcons() {
        const {
            gridProductIcons,
            gridProductIconsEnabled,
            product: {
                attributes: { product_icons: { attribute_options: productIcons } = {} } = {}
            }
        } = this.props;

        if (!+gridProductIconsEnabled || !productIcons || Object.keys(productIcons) <= 0) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductIcons">
                { gridProductIcons.map((icon) => this.renderProductIcon(icon, productIcons)) }
            </div>
        );
    }

    renderProductIcon(productIcon, productIcons) {
        const { label, value } = productIcon;

        const productIconsKey = Object.keys(productIcons);
        const isPresent = productIconsKey.find((icon) => productIcons[icon].label === label);

        if (!isPresent) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductIcon">
                <Html content={ value } />
            </div>
        );
    }

    renderMainDetails() {
        const {
            sp_b2b_pricelist_graphql,
            product: { name, sales_text }
        } = this.props;

        if (sp_b2b_pricelist_graphql && sales_text) {
            return (
                <p
                  block="ProductCard"
                  elem="Name"
                  mods={ { isLoaded: !!sales_text } }
                >
                    <TextPlaceholder content={ sales_text } length="medium" />
                </p>
            );
        }

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }
}

export default ProductCardComponent;
