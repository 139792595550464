// import { requestCustomerData } from 'Store/MyAccount/MyAccount.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
// import getStore from 'Util/Store';

export const CUSTOMER = 'customer';

export const SHARED_CATALOG_ID = 'shared_catalog_id';

export const ONE_HOUR = 3600;

/** @namespace Screenpages/b2bsharedcatalog/Util/SharedCatalog/setSharedCatalogId */
export const setSharedCatalogId = (sharedCatalogId) => {
    BrowserDatabase.setItem(sharedCatalogId, SHARED_CATALOG_ID, ONE_HOUR);
};

/** @namespace Screenpages/b2bsharedcatalog/Util/SharedCatalog/getSharedCatalogId */
export const getSharedCatalogId = () => {
    const sharedCatalogId = BrowserDatabase.getItem(SHARED_CATALOG_ID);
    if (isSignedIn() && !sharedCatalogId) {
        const customer = BrowserDatabase.getItem(CUSTOMER);
        if (!customer) {
            return 0;
        }

        const { group_id } = customer;
        setSharedCatalogId(group_id);
        return group_id;
    }

    return (typeof sharedCatalogId === 'number') ? sharedCatalogId : 0;
};
