import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Rapelli/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    // TODO implement logic
}

export default CmsBlockContainer;
