import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CompanyRoleGridEntryType } from '../../type/B2bCompany';

import './CompanyRolesRow.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesRow/Component/CompanyRolesRowComponent */
export class CompanyRolesRowComponent extends PureComponent {
    static propTypes = {
        Roles: CompanyRoleGridEntryType.isRequired, // TODO Check Prop []
        editCurrentRole: PropTypes.func.isRequired
    };

    renderSingleRoleRow() {
        const {
            editCurrentRole,
            Roles: {
                role_id,
                role_name
            }
        } = this.props;

        return (
            <div block="CompanyRolesRow" elem="Row" key={ role_id }>
                <span
                  block="CompanyRolesRow"
                  elem="RoleCell"
                  key={ role_id }
                  role="button"
                  onClick={ editCurrentRole }
                  onKeyDown={ editCurrentRole }
                  tabIndex={ 0 }
                >
                    { role_name }
                </span>
            </div>
        );
    }

    render() {
        return (
            <div block="CompanyRolesRow">
                { this.renderSingleRoleRow() }
            </div>
        );
    }
}

export default CompanyRolesRowComponent;
