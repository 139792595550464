import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { CartItemType } from 'Type/MiniCart';
import { formatPrice } from 'Util/Price';

import './QuoteViewItem.style';

/** @namespace Screenpages/b2bquote/Component/QuoteViewItem/Component/QuoteViewItemComponent */
export class QuoteViewItemComponent extends PureComponent {
    static propTypes = {
        QuoteItem: CartItemType.isRequired,
        CurrencyCode: PropTypes.string.isRequired,
        getCurrentProduct: PropTypes.func.isRequired,
        linkTo: PropTypes.oneOfType([
            PropTypes.shape({
                pathname: PropTypes.string,
                search: PropTypes.string
            }),
            PropTypes.string
        ]).isRequired
    };

    renderProductConfigurations() {
        const {
            QuoteItem: {
                product: {
                    configurable_options,
                    variants
                }
            },
            getCurrentProduct
        } = this.props;

        if (!variants || !configurable_options) {
            return null;
        }

        const { attributes = [] } = getCurrentProduct() || {};

        if (!Object.entries(attributes).length) {
            return null;
        }

        return (
            <ul
              block="QuoteViewItem"
              elem="Options"
            >
                { Object.entries(attributes).map(this.renderProductConfigurationOption) }
            </ul>
        );
    }

    renderProductConfigurationOption = ([key, attribute]) => {
        const {
            QuoteItem: {
                product: {
                    configurable_options
                }
            }
        } = this.props;

        const { attribute_code, attribute_value } = attribute;

        if (!Object.keys(configurable_options).includes(key) || attribute_value === null) {
            return null;
        }

        const {
            [attribute_code]: { // configurable option attribute
                attribute_options: {
                    [attribute_value]: { // attribute option value label
                        label
                    }
                }
            }
        } = configurable_options;

        return (
            <li
              key={ attribute_code }
              aria-label={ attribute_code }
              block="CartItem"
              elem="Option"
            >
                { label }
            </li>
        );
    };

    renderItem() {
        const { QuoteItem, CurrencyCode, linkTo } = this.props;

        const {
            item_id, qty, row_total,
            product: {
                name,
                thumbnail
            } = {}
        } = QuoteItem;

        const { url: thumbnailUrl } = thumbnail || {};

        const key = item_id;

        return (
            <tr block="QuoteViewItem" elem="ItemRow" key={ key }>
                <td block="QuoteViewItem" elem="ImgWrapper">
                    <div block="QuoteViewItem" elem="ImgWrapper">
                        { thumbnailUrl && (
                            <Link
                              to={ linkTo }
                            >
                            <Image
                              src={ thumbnailUrl }
                              name={ name }
                              mix={ { block: 'QuoteViewItem', elem: 'ItemImg' } }
                            />
                            </Link>
                        ) }
                    </div>
                </td>
                <td>
                    <div block="QuoteViewItem" elem="ProductData">
                        <Link
                          to={ linkTo }
                        >
                            { name }
                        </Link>
                        { this.renderProductConfigurations() }
                    </div>
                </td>
                <td>
                    <div block="QuoteViewItem" elem="Qty">
                        { qty }
                    </div>
                </td>
                <td>
                    <div block="QuoteViewItem" elem="Price">
                        { formatPrice(row_total, CurrencyCode) }
                    </div>
                </td>
            </tr>
        );
    }

    render() {
        return (
            this.renderItem()
        );
    }
}

export default QuoteViewItemComponent;
