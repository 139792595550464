import {
    CHECKBOX_TYPE as SOURCE_CHECKBOX_TYPE,
    EMAIL_TYPE as SOURCE_EMAIL_TYPE,
    ENTER_KEY_CODE as SOURCE_ENTER_KEY_CODE,
    FILE_TYPE as SOURCE_FILE_TYPE,
    NUMBER_TYPE as SOURCE_NUMBER_TYPE,
    PASSWORD_TYPE as SOURCE_PASSWORD_TYPE,
    RADIO_TYPE as SOURCE_RADIO_TYPE,
    SELECT_TYPE as SOURCE_SELECT_TYPE,
    TEXT_TYPE as SOURCE_TEXT_TYPE,
    TEXTAREA_TYPE as SOURCE_TEXTAREA_TYPE,
    VALIDATION_STATUS as SOURCE_VALIDATION_STATUS
} from 'SourceComponent/Field/Field.config';

// TODO: implement EMAIL_TYPE
export const EMAIL_TYPE = SOURCE_EMAIL_TYPE;

// TODO: implement TEXT_TYPE
export const TEXT_TYPE = SOURCE_TEXT_TYPE;

// TODO: implement NUMBER_TYPE
export const NUMBER_TYPE = SOURCE_NUMBER_TYPE;

// TODO: implement RADIO_TYPE
export const RADIO_TYPE = SOURCE_RADIO_TYPE;

// TODO: implement CHECKBOX_TYPE
export const CHECKBOX_TYPE = SOURCE_CHECKBOX_TYPE;

// TODO: implement TEXTAREA_TYPE
export const TEXTAREA_TYPE = SOURCE_TEXTAREA_TYPE;

// TODO: implement PASSWORD_TYPE
export const PASSWORD_TYPE = SOURCE_PASSWORD_TYPE;

// TODO: implement SELECT_TYPE
export const SELECT_TYPE = SOURCE_SELECT_TYPE;

// TODO: implement FILE_TYPE
export const FILE_TYPE = SOURCE_FILE_TYPE;

// TODO: implement ENTER_KEY_CODE
export const ENTER_KEY_CODE = SOURCE_ENTER_KEY_CODE;

// TODO: implement VALIDATION_STATUS
export const VALIDATION_STATUS = SOURCE_VALIDATION_STATUS;
