/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';
import CSS from 'Util/CSS';

import {
    ADDTOCART_LIFETIME, ADDTOCART_TYPE, ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE, NOTIFICATION_LIFETIME
} from './Notification.config';

import './Notification.override.style';

/** @namespace Rapelli/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {
    componentDidMount() {
        const { notification: { msgType }, lifeTime } = this.props;

        // Make sure error notification stays a little longer
        if (msgType.toLowerCase() === ERROR_TYPE) {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ERROR_NOTIFICATION_LIFETIME);
        } else if (msgType.toLowerCase() === ADDTOCART_TYPE) {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ADDTOCART_LIFETIME);
        } else {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || NOTIFICATION_LIFETIME);
        }

        CSS.setVariable(this.notification, 'animation-duration', `${ANIMATION_DURATION}ms`);
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">{ __(msgText) }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
