/* eslint-disable max-len */
/* eslint-disable max-lines */
import { RivercodeFacebookConversionAPI } from '@screenpages/rivercode-facebook-conversion-api';

import MyAccountDispatcher, { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { roundPrice } from 'Util/Price';

import { hashValue } from '../util/CustomerData';
// eslint-disable-next-line import/no-cycle
import ProductHelper from '../util/Product/ProductHelper';

export const URL_REWRITE = 'url-rewrite';
export const DATA_RECHECK_TIMEOUT = 1500;
export const EVENT_HANDLE_DELAY = 1500;

/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/BaseEvent/Event/BaseEventEvent */
export class BaseEventEvent {
    FacebookConversionApiContainer = null;

    ProductHelper = {};

    eventName = '';

    lastEventTime = {};

    eventHandleDelay = EVENT_HANDLE_DELAY;

    fbConversionApiObject = null;

    isLoadingData = false;

    customerIdData = {
        customerEmail: [],
        customerPhone: []
    };

    __construct(eventName, FacebookConversionApiContainer) {
        this.eventName = eventName;
        this.ProductHelper = ProductHelper;
        this.FacebookConversionApiContainer = FacebookConversionApiContainer;
        this.initFacebookConversionApi();
    }

    async initFacebookConversionApi() {
        const baseData = this.getFacebookConversionApiContainer().fbConversionApiData;
        // All customer data must be hashed with SHA-256
        const customerHashedDetails = await this.getCustomerPhoneAndEmail();
        baseData.customerEmail = customerHashedDetails.customerEmail;
        baseData.customerPhone = customerHashedDetails.customerPhone;
        const FBConversionAPI = new RivercodeFacebookConversionAPI(...Object.values(baseData));

        this.fbConversionApiObject = FBConversionAPI;
        return true;
    }

    async getCustomerPhoneAndEmail() {
        if (!this.getCustomerHasIdData()) {
            if (this.isSignedIn()) {
                const customer = BrowserDatabase.getItem(CUSTOMER);
                this.customerIdData.customerEmail = [
                    await hashValue(customer.email)
                ];
                const defaultShippingAddress = customer.addresses.filter((address) => address.default_shipping);
                const defaultShippingTelephone = defaultShippingAddress.length ? defaultShippingAddress[0].telephone : customer.addresses[0].telephone;
                const telephone = defaultShippingTelephone.length ? defaultShippingTelephone : '';
                this.customerIdData.customerPhone = [
                    await hashValue(
                        telephone
                    )];
            } else {
                const guestShippingDetails = this.getState().CheckoutReducer;
                const customerGuestEmail = guestShippingDetails.email
                    ? await hashValue(guestShippingDetails.email) : '';
                const customerGuestPhone = guestShippingDetails.shippingFields.telephone
                    ? await hashValue(guestShippingDetails.shippingFields.telephone) : '';

                this.customerIdData.customerEmail = [
                    customerGuestEmail
                ];
                this.customerIdData.customerPhone = [
                    customerGuestPhone
                ];
            }
        }

        return this.customerIdData;
    }

    getFacebookConversionApiContainer() {
        return this.FacebookConversionApiContainer;
    }

    getCustomerHasIdData() {
        return (this.customerIdData.customerEmail.length) * (this.customerIdData.customerPhone.length);
    }

    getHashedVar(val) {
        hashValue(val).then(
            /** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Events/BaseEvent/Event/hashValue/then */
            (digestHex) => digestHex
        );
    }

    getProps() {
        return this.getFacebookConversionApiContainer().props;
    }

    getState() {
        return this.getProps().state;
    }

    getStorage(event = this.eventName) {
        return this.getFacebookConversionApiContainer().getEventDataStorage(event);
    }

    cleanStorage(event = this.eventName) {
        this.getFacebookConversionApiContainer().resetEventDataStorage(event);
    }

    setStorage(data, event = this.eventName) {
        this.getFacebookConversionApiContainer().setEventStorage(event, data);
    }

    getGroupedProducts() {
        return this.getFacebookConversionApiContainer().getGroupedProducts();
    }

    /**
     * @return {string}
     */
    getPageType() {
        const { urlRewrite, currentRouteName } = window;

        if (currentRouteName === URL_REWRITE) {
            if (typeof urlRewrite === 'undefined') {
                return '';
            }

            if (urlRewrite.notFound) {
                return 'notfound';
            }

            return (urlRewrite.type || '').toLowerCase();
        }

        return (currentRouteName || '').toLowerCase();
    }

    /**
     * if user is signed in
     *
     * @return {*|boolean}
     */
    isSignedIn() {
        return this.getState().MyAccountReducer.isSignedIn || false;
    }

    /**
     * Handle event fire
     * Process event logic
     */
    handle(...args) {
        if (!this.checkDataExists(...args)) {
            return;
        }

        // Set little delay on event fire. make it async, to do not block main code
        setTimeout(() => {
            this.handler(...args);
        }, this.eventHandleDelay);
    }

    checkDataExists(...args) {
        if (this.isLoadingData) {
            return false;
        }

        // Wait until config data will be loaded
        if (Object.entries(this.getState().ConfigReducer).length === 0) {
            this.isLoadingData = true;

            setTimeout(() => {
                this.isLoadingData = false;
                this.handle(...args);
            }, DATA_RECHECK_TIMEOUT);

            return false;
        }

        // Wait until customer data will be loaded (or load customer data manually)
        if (this.isSignedIn() && Object.entries(BrowserDatabase.getItem(CUSTOMER) || {}).length === 0) {
            this.isLoadingData = true;

            const dispatch = this.getAppDispatch();
            MyAccountDispatcher.requestCustomerData(dispatch);

            // Wait until user details will be loaded
            setTimeout(() => {
                this.isLoadingData = false;
                this.handle(...args);
            }, DATA_RECHECK_TIMEOUT);

            return false;
        }

        if (this.getPageType() === '') {
            this.isLoadingData = true;

            setTimeout(() => {
                this.isLoadingData = false;
                this.handle(...args);
            }, DATA_RECHECK_TIMEOUT);

            return false;
        }

        return true;
    }

    getCartTotals() {
        return this.getState().CartReducer.cartTotals;
    }

    prepareCartData() {
        const {
            subtotal = 0,
            items_qty = 0,
            tax_amount = 0,
            items = []
        } = this.getCartTotals();

        const itemsData = items
            .map((item) => ({
                ...this.ProductHelper.getItemData(item),
                quantity: this.ProductHelper.getQuantity(item)
            }));

        return {
            items_qty,
            total: +roundPrice(subtotal + tax_amount),
            itemsData
        };
    }

    /**
     * Check if handle event is fired too often
     *
     * @param delay
     * @param type
     *
     * @return {boolean}
     */
    spamProtection(delay, type = 'default') {
        const previousEventTime = this.lastEventTime[type] || 0;
        this.lastEventTime[type] = Date.now();

        return previousEventTime + delay > this.lastEventTime[type];
    }

    /**
     * @return {string}
     */
    getCurrencyCode() {
        return this.getState().ConfigReducer.default_display_currency_code;
    }

    /**
     * Random 10 digit number for use with ID for non-transaction events
     * @return {Number}
     */
    generateEventId() {
        const minm = 1000000000;
        const maxm = 9999999999;
        return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    }
}

export default BaseEventEvent;
