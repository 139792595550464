/* eslint-disable no-magic-numbers */
/** @namespace Screenpages/FacebookConversionApi/Component/FacebookConversionApi/Util/CustomerData/Index/hashValue */
export const hashValue = async (message) => {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

    return hashHex;
};
