import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import B2bCompanyProfieQuery from '../../query/B2bCompanyProfie.query';
import { CompanyType } from '../../type/B2bCompany';
import CompanyEditForm from './CompanyEditForm.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({ });

/** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showNotify: (type, message) => _dispatch(showNotification(type, message))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Container/CompanyEditFormContainer */
export class CompanyEditFormContainer extends PureComponent {
    static propTypes = {
        showNotify: PropTypes.func.isRequired,
        CompanyData: CompanyType, // TODO check PropType
        handleCloseAndReload: PropTypes.func.isRequired
    };

    static defaultProps = {
        CompanyData: {}
    };

    state = {
        isFormSubmitting: false
    };

    containerFunctions = {
        handleSubmit: this._handleSubmit.bind(this),
        handleSubmitSuccess: this._handlSubmitSuccess.bind(this),
        handleSubmitError: this._handleSubmitError.bind(this)
    };

    componentWillUnmount() {
        //
    }

    _handleSubmit() {
        // this.setState({ isFormSubmitting: true });
    }

    _handlSubmitSuccess(fields) {
        const { handleCloseAndReload, showNotify } = this.props;
        if (fields) {
            this.setState({ isFormSubmitting: true });
            fetchMutation(B2bCompanyProfieQuery.setUpdateCompanyProfileMutation(fields)).then(
                /** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Container/fetchMutation/then */
                ({ b2bSetUpdateCompanyProfileData: { status, message } }) => {
                    this.setState({ isFormSubmitting: false });
                    if (status) {
                        showNotify('success', __('Company profile has been saved'));
                        handleCloseAndReload();
                        return;
                    }
                    showNotify('error', message);
                },
                /** @namespace Screenpages/b2bcompany/Component/CompanyEditForm/Container/fetchMutation/then */
                ([{ message }]) => {
                    this.setState({ isFormSubmitting: false });
                    showNotify('error', message);
                }
            );
        }
    }

    _handleSubmitError() {
        const { showNotify } = this.props;
        this.setState({ isFormSubmitting: false });
        showNotify('error', 'we got an error'); // TODO handdle Errors
    }

    containerProps = () => {
        const { isFormSubmitting } = this.state;
        const { CompanyData } = this.props;
        return {
            isFormSubmitting,
            CompanyData
        };
    };

    render() {
        return (
            <CompanyEditForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditFormContainer);
