import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';

import { b2bCompanySingleUserType, b2bUserEditDropdownType } from '../../type/B2bCompany';

import './CompanyUserEditForm.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Component/CompanyUserEditFormComponent */
export class CompanyUserEditFormComponent extends PureComponent {
    static propTypes = {
        isFormSubmitting: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleSubmitSuccess: PropTypes.func.isRequired,
        handleSubmitError: PropTypes.func.isRequired,
        handleDeleteUser: PropTypes.func.isRequired,
        userData: b2bCompanySingleUserType,
        userRoles: b2bUserEditDropdownType,
        userStatusFields: b2bUserEditDropdownType
    };

    static defaultProps = {
        userData: {},
        userRoles: [],
        userStatusFields: []
    };

    renderEditUserForm() {
        const {
            handleSubmit, handleSubmitSuccess, handleSubmitError,
            isFormSubmitting, userData: {
                job_title,
                firstname,
                lastname,
                email,
                telephone
            } = ''
        } = this.props;

        return (
            <Form
              mix={ { block: 'CompanyUsersEditPopup', elem: 'Form' } }
              key="companyusereditform"
              onSubmit={ handleSubmit }
              onSubmitSuccess={ handleSubmitSuccess }
              onSubmitFail={ handleSubmitError }
            >
                <Field
                  type="text"
                  label={ __('Job Title') }
                  name="job_title"
                  id="job_title"
                  value={ job_title }
                  validation={ ['notEmpty'] }
                />
                { this.renderRoleField() }
                <Field
                  type="text"
                  label={ __('First Name') }
                  name="firstname"
                  id="firstname"
                  value={ firstname }
                  validation={ ['notEmpty'] }
                />
                <Field
                  type="text"
                  label={ __('Last Name') }
                  name="lastname"
                  id="lastname"
                  value={ lastname }
                  validation={ ['notEmpty'] }
                />
                <Field
                  type="text"
                  label={ __('Email') }
                  name="email"
                  id="email"
                  value={ email }
                  validation={ ['notEmpty', 'email'] }
                />
                <Field
                  type="text"
                  label={ __('Phone Number') }
                  name="phonenumber"
                  id="phonenumber"
                  value={ telephone }
                  validation={ ['notEmpty', 'telephone'] }
                />
                { this.renderStatusField() }
                <button
                  block="Button"
                  mods={ { isFormSubmitting } }
                  disabled={ isFormSubmitting }
                >
                    <span>
                        { __('Save') }
                    </span>
                </button>
            </Form>

        );
    }

    renderRoleField() {
        const { userRoles, userData: { role_id, is_owner, role_name } = '' } = this.props;

        if (is_owner) {
            return (
              <Field
                type="text"
                label={ __('User Role') }
                name="userrole"
                id="userrole"
                value={ role_name }
                isDisabled
              />
            );
        }

        return (
          <Field
            type="select"
            label={ __('User Role') }
            name="userrole"
            id="userrole"
            selectOptions={ userRoles }
            value={ role_id }
          />
        );
    }

    renderStatusField() {
        const { userData: { status, is_owner }, userStatusFields } = this.props;
        if (is_owner) {
            return null;
        }

        return (
            <Field
              type="select"
              label={ __('Status') }
              selectOptions={ userStatusFields }
              name="status"
              id="status"
              value={ status }
            />
        );
    }

    /**
     * This functionality is working (The functions and query and Graphql Endpoints exists)
     * However, on reading default M2 / writing graphql endpoint. I can see all this action does is set the user status to inactive
     * This action cab be completed by changing the status field when editing the customer
     * @deprecated
     */
    renderDeleteBtn() {
        const { handleDeleteUser, userData: { entity_id } = 0 } = this.props;
        if (entity_id > 0) {
            return (
              <div block="CompanyUserEditForm">
                <button
                  block="CompanyUserEditForm"
                  elem="DeleteBtn"
                  onClick={ handleDeleteUser }
                >
                  <span>
                    { __('Delete user') }
                  </span>
                </button>
              </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div block="CompanyUserEditForm">
                { this.renderEditUserForm() }
            </div>
        );
    }
}

export default CompanyUserEditFormComponent;
