import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { MixType } from 'Type/Common';

import {
    CHECKBOX_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    VALIDATION_STATUS
} from './Field.config';

/** @namespace Rapelli/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    // TODO implement logic

    static propTypes = {
        isControlled: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            FILE_TYPE,
            EMAIL_TYPE
        ]).isRequired,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onPostCodeChange: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        onKeyPress: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        validation: PropTypes.arrayOf(PropTypes.string),
        message: PropTypes.string,
        customValidationStatus: PropTypes.oneOf(
            Object.values(VALIDATION_STATUS)
        ),
        id: PropTypes.string,
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        // eslint-disable-next-line react/forbid-prop-types
        formRefMap: PropTypes.object,
        validateSeparately: PropTypes.bool,
        isSubmitted: PropTypes.bool,
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        placeholder: PropTypes.string,
        subLabel: PropTypes.number,
        filename: PropTypes.string,
        fileExtensions: PropTypes.string,
        mix: MixType,
        selectOptions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.object
                ])
            })
        ),
        name: PropTypes.string.isRequired,
        autocomplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        ariaLabel: PropTypes.string,
        isLabelWithArrow: PropTypes.bool,
        step: PropTypes.number
    };

    static defaultProps = {
        min: 1,
        max: 99,
        checked: false,
        value: null,
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        onClick: () => {},
        onKeyPress: () => {},
        onPostCodeChange: () => {},
        formRef: () => {},
        isControlled: false,
        validation: [],
        message: '',
        customValidationStatus: null,
        id: '',
        formRefMap: {},
        validateSeparately: false,
        isSubmitted: false,
        disabled: false,
        label: '',
        placeholder: '',
        subLabel: null,
        ariaLabel: '',
        mix: {},
        filename: '',
        fileExtensions: '',
        selectOptions: [],
        autocomplete: 'off',
        isLabelWithArrow: false,
        step: 1,
        isSearchTrigered: false
    };

    containerFunctions = {
        onChange: this.onChange.bind(this),
        handleChange: this.handleChange.bind(this),
        onChangeCheckbox: this.onChangeCheckbox.bind(this),
        onFocus: this.onFocus.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onKeyEnterDown: this.onKeyEnterDown.bind(this),
        onClick: this.onClick.bind(this)
    };

    containerProps() {
        const {
            autocomplete,
            customValidationStatus,
            disabled,
            fileExtensions,
            formRef,
            formRefMap,
            id,
            label,
            placeholder,
            max,
            min,
            mix,
            name,
            selectOptions,
            subLabel,
            type,
            validation,
            ariaLabel,
            isLabelWithArrow,
            step,
            onPostCodeChange,
            isSearchTrigered
        } = this.props;

        const {
            checked,
            value,
            validationStatus,
            validationMessage,
            filename
        } = this.state;

        return {
            autocomplete,
            checked,
            customValidationStatus,
            disabled,
            fileExtensions,
            filename,
            formRef,
            formRefMap,
            id,
            label,
            ariaLabel,
            placeholder,
            max,
            message: validationMessage,
            min,
            mix,
            name,
            selectOptions,
            subLabel,
            type,
            validation,
            validationStatus: customValidationStatus ?? validationStatus,
            value,
            isLabelWithArrow,
            step,
            onPostCodeChange,
            isSearchTrigered
        };
    }
}

export default FieldContainer;
