import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';
/** @namespace Rapelli/Component/AddToCart/Component/AddToCartComponent */
export class AddToCartComponent extends SourceAddToCart {
    // TODO implement logic
}

export default AddToCartComponent;
