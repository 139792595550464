import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';

import {
    b2bComapnyAuthType, b2bComapnyProfileDataType, b2bCompanyRoleType, b2bCompanyUserGridType
} from '../../type/B2bCompany';
import CompanyEditPopup from '../CompanyEditPopup';
import CompanyRolesRow from '../CompanyRolesRow';
import CompanyUsersManagement from '../CompanyUserManagement';
import {
    AUTH_CAN_EDIT_COMPANY, AUTH_CAN_EDIT_COMPANY_ROLES, AUTH_CAN_EDIT_COMPANY_USERS
} from './CompanyOverview.config';

import './CompanyOverview.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyOverview/Component/CompanyOverviewComponent */
export class CompanyOverviewComponent extends PureComponent {
    static propTypes = {
        b2bCompanyProfileData: b2bComapnyProfileDataType,
        b2bCompanyUserData: b2bCompanyUserGridType,
        b2bCompanyRolesData: b2bCompanyRoleType,
        b2bAuth: b2bComapnyAuthType,
        isCompanyProfileLoaded: PropTypes.bool,
        showEditCompanyFormPopup: PropTypes.func.isRequired,
        showEditCompanyUserFormPopup: PropTypes.func.isRequired,
        showEditCompanyRoleFormPopup: PropTypes.func.isRequired,
        reloadCompanyProfile: PropTypes.func.isRequired
    };

    static defaultProps = {
        b2bCompanyProfileData: {},
        b2bCompanyUserData: {},
        b2bCompanyRolesData: {},
        b2bAuth: {},
        isCompanyProfileLoaded: false
    };

    renderPtag(lbl) {
        return (<p>{ lbl }</p>);
    }

    renderCompanyProfileResult() {
        return (
            <div block="CompanyOverView">
                { this.renderAccountInformation() }
                { this.renderContacts() }
                { this.renderUsersManagement() }
                { this.renderCompanyRoles() }
                { this.renderPopup() }
            </div>
        );
    }

    renderAccountInformation() {
        const {
            b2bCompanyProfileData:
                { company_name, company_email } = {}
        } = this.props;

        return (
            <div block="CompanyOverview" elem="AccountInfo">
                <div block="CompanyOverview" elem="AccountInfoContent">
                    <div block="CompanyOverview" elem="AccountDetails">
                        <h3>{ __('Account Information') }</h3>
                        { this.renderPtag(company_name) }
                        { this.renderPtag(company_email) }
                        { this.renderEditLink() }
                    </div>
                    { this.renderLegalAddress() }
                </div>
            </div>
        );
    }

    renderLegalAddress() {
        const {
            b2bCompanyProfileData: {
                legal_address
            }
        } = this.props;

        return (
            <MyAccountAddressTable
              title={ __('Legal Address') }
              address={ legal_address }
              key="legaladdress"
              mix={ { block: 'CompanyOverview', elem: 'LegalAddress' } }
            />
        );
    }

    renderContacts() {
        const {
            b2bCompanyProfileData: {
                company_contact_name, company_contact_email,
                company_salesrep_name, company_salesrep_email
            } = ''
        } = this.props;

        return (
            <div block="CompanyOverview" elem="Contacts">
                <h3 block="CompanyOverview" elem="SubHeading">
                    { __('Contacts') }
                </h3>
                <div block="CompanyOverview" elem="CompanyContact">
                    <div block="CompanyOverview" elem="SingleContact" key="companycontact">
                        <h4>
                            { __('Company Contact') }
                        </h4>
                        { this.renderPtag(company_contact_name) }
                        { this.renderPtag(company_contact_email) }
                    </div>
                    <div block="CompanyOverview" elem="SingleContact" key="salesrep">
                        <h4>
                            { __('Sales Rep') }
                        </h4>
                        { this.renderPtag(company_salesrep_name) }
                        { this.renderPtag(company_salesrep_email) }
                    </div>
                </div>
            </div>
        );
    }

    renderEditLink() {
        const { b2bAuth } = this.props;
        const auth = b2bAuth.find((e) => e.code === AUTH_CAN_EDIT_COMPANY);
        const { value } = auth;
        if (!value) {
            return false;
        }
        const { showEditCompanyFormPopup } = this.props;

        return (
                <div block="CompanyOverview" elem="EditLink">
                    <button
                      block="Button"
                      mods={ { isHollow: true } }
                      onClick={ showEditCompanyFormPopup }
                    >
                        { __('Edit Company Information') }
                    </button>
                </div>
        );
    }

    renderPopup() {
        const { reloadCompanyProfile, b2bCompanyProfileData } = this.props;

        return (
            <CompanyEditPopup
              CompanyData={ b2bCompanyProfileData }
              reloadCompanyProfile={ reloadCompanyProfile }
            />
        );
    }

    renderLoading() {
        const { isCompanyProfileLoaded } = this.props;
        return (
            <div block="CompanyOverview" elem="Loading">
                <Loader isLoading={ !isCompanyProfileLoaded } />
            </div>
        );
    }

    renderUsersManagement() {
        const { b2bCompanyUserData, b2bAuth, showEditCompanyUserFormPopup } = this.props;
        const auth = b2bAuth.find((e) => e.code === AUTH_CAN_EDIT_COMPANY_USERS);
        const { value: isAllowedEditUsers } = auth;
        if (!b2bCompanyUserData.length) {
            return null;
        }

        return (
            <div block="CompanyOverview" elem="UserManagement">
                <h3 block="CompanyOverview" elem="SubHeading">{ __('User Management') }</h3>
                    <CompanyUsersManagement
                      CompanyUsers={ b2bCompanyUserData }
                      isAllowedToEdit={ isAllowedEditUsers }
                      showEditCompanyUserFormPopup={ showEditCompanyUserFormPopup }
                    />
            </div>
        );
    }

    renderCompanyRoles() {
        const { b2bCompanyRolesData, b2bAuth } = this.props;

        const auth = b2bAuth.find((e) => e.code === AUTH_CAN_EDIT_COMPANY_ROLES);
        const { value: isAllowedEditRole } = auth;

        return b2bCompanyRolesData.length ? (
            <div block="CompanyOverview" elem="RoleManagement">
                <h3 block="CompanyOverview" elem="SubHeading">{ __('Role Management') }</h3>
                <div block="CompanyOverview" elem="Roles">
                    { b2bCompanyRolesData.map((roles) => {
                        const { role_id } = roles;
                        const key = `roles${ role_id}`;
                        return (
                            <CompanyRolesRow
                              Roles={ roles }
                              key={ key }
                              isAllowedEditRole={ isAllowedEditRole }
                            />
                        );
                    }) }
                </div>
                { this.renderAddRoleBtn() }
            </div>
        ) : null;
    }

    renderAddRoleBtn() {
        const { showEditCompanyRoleFormPopup, b2bAuth } = this.props;
        const auth = b2bAuth.find((e) => e.code === AUTH_CAN_EDIT_COMPANY_ROLES);
        const { value: authVal } = auth;

        return authVal ? (
            <button
              block="Button"
              mods={ { isHollow: true } }
              onClick={ showEditCompanyRoleFormPopup }
            >
                <span>{ __('Add a new role') }</span>
            </button>
        ) : null;
    }

    render() {
        const { isCompanyProfileLoaded, b2bCompanyProfileData } = this.props;

        if (!isCompanyProfileLoaded) {
            return this.renderLoading();
        }
        if (Object.keys(b2bCompanyProfileData).length === 0) {
            return (<div>{ __('No Company Found') }</div>);
        }

        return this.renderCompanyProfileResult();
    }
}

export default CompanyOverviewComponent;
