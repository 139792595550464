import QuoteGrid from '../component/QuoteGrid';

export const QUOTE_TAB_KEY = 'quotes';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export class MyAccountNavigationPlugin {
  // Add our new fields to the customer GraphQL Query
  // This will add the result to the MyAccount reducer
  addQuoteFieldsToQuery = (args, callback) => [
      'isB2bQuoteAllowedCreate',
      'isB2bQuoteAllowedView',
      ...callback(args)
  ];

  // Add the Quotes to the My Account Tabs (note, I am adding an extra object B2bQuoteCheck)
  addQuotesToMyAccountMenu = (member) => ({
      ...member,
      [QUOTE_TAB_KEY]: {
          url: `/${ QUOTE_TAB_KEY }`,
          name: __('My Quotes'),
          b2bQuoteCheck: true
      }
  });

  addQuotesToMyAccountRenderMap = (member) => ({
      ...member,
      [QUOTE_TAB_KEY]: QuoteGrid
  });

  addB2bQuoteAuthToMyAccountProps(args, callback) {
      const [state] = args;
      const { isB2bQuoteAllowedView } = state.MyAccountReducer.customer;
      return {
          ...callback(...args),
          isB2bQuoteAllowedView
      };
  }

  addValuesToComponentProps(args, callback, obj) {
      // const { props } = obj;
      const {
          props: { isB2bQuoteAllowedView, requestCustomerData }
      } = obj;

      return {
          ...callback(...args),
          isB2bQuoteAllowedView,
          requestCustomerData
      };
  }

  addCustomerRequestToDispatchProps(agrs, callback) {
      const [dispatch] = agrs;

      return {
          ...callback(...agrs),
          requestCustomerData: () => MyAccountDispatcher.then(
              ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
          )
      };
  }

  addQuoteCheckForTabListRender(args, callback, obj) {
      const tabEntry = args[0];
      const { b2bQuoteCheck } = tabEntry[1];
      const { props } = obj;
      const { isB2bQuoteAllowedView, requestCustomerData } = props;
      if (isB2bQuoteAllowedView === undefined) {
          requestCustomerData();
      }

      if (b2bQuoteCheck && !isB2bQuoteAllowedView) {
          return null;
      }

      return (
          callback(...args)
      );
  }
}
const {
    addQuoteFieldsToQuery,
    addQuotesToMyAccountMenu,
    addQuotesToMyAccountRenderMap,
    addB2bQuoteAuthToMyAccountProps,
    addQuoteCheckForTabListRender,
    addValuesToComponentProps,
    addCustomerRequestToDispatchProps
} = new MyAccountNavigationPlugin();

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: addQuoteFieldsToQuery
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: addQuotesToMyAccountMenu
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addQuotesToMyAccountRenderMap
        }
    },
    'Component/MyAccountTabList/Container/mapStateToProps': {
        function: addB2bQuoteAuthToMyAccountProps
    },
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: addCustomerRequestToDispatchProps
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            containerProps: addValuesToComponentProps
        }
    },
    'Component/MyAccountTabList/Component': {
        'member-function': {
            renderTabListItem: addQuoteCheckForTabListRender
        }
    }
};
