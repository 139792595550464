/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

import './Slider.override.style';

/** @namespace Rapelli/Component/Slider/Component/SliderComponent */
export class SliderComponent extends SourceSlider {
    goPrev() {
        const { activeImage, children } = this.props;

        if (activeImage > 0) {
            this.changeActiveImage(activeImage - 1);
        } else {
            this.changeActiveImage(children.length - 1);
        }
    }

    goNext() {
        const { activeImage, children } = this.props;
        const nextImage = activeImage + 1;

        if (nextImage < children.length) {
            this.changeActiveImage(nextImage);
        } else {
            this.changeActiveImage(0);
        }
    }

    // TODO implement logic
    renderCrumb(_, i) {
        const {
            activeImage,
            children: { [i]: { props: { name = '' } } = '' } = ''
        } = this.props;
        const isActive = i === Math.abs(-activeImage);
        // this.props.children[i].props.name
        return (
            <button
              block="Slider"
              elem="Image"
              mods={ { type: 'single' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => this.changeActiveImage(i) }
              aria-label={ __('Slide crumb') }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                >
                    { name }
                </div>
            </button>
        );
    }

    renderArrows() {
        const { showArrows } = this.props;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ChevronIcon direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ChevronIcon direction={ RIGHT } />
                </button>
            </>
        );
    }
}

export default SliderComponent;
