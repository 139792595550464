import Event, { EVENT_GTM_ADD_SHIPPING_INFO } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/** @namespace Gtm/Component/GoogleTagManager/Events/AddShippingInfo/Event/AddShippingInfoEvent */
export class AddShippingInfoEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_ADD_SHIPPING_INFO, ({
            selectedShippingMethod,
            shippingAddress,
            totals
        }) => {
            this.handle(
                selectedShippingMethod,
                shippingAddress,
                totals
            );
        });
    }

    /**
     * Add add_shipping_info event info to dataLayer
     */
    handler(
        selectedShippingMethod,
        shippingAddress,
        totals
    ) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const shippingTier = (selectedShippingMethod === 'planzerpaket')
            ? this.getPlanzerPaketSelectedShippingMethod(shippingAddress)
            : selectedShippingMethod;
        const couponCode = (totals.coupon_code && totals.coupon_code.length) ? totals.coupon_code : '';
        const cartItems = this.getCartItems(totals.items, couponCode);

        this.pushEventData({
            ecommerce: {
                checkout: {
                    currency: totals.quote_currency_code,
                    value: totals.grand_total,
                    coupon: couponCode,
                    shipping_tier: shippingTier,
                    items: cartItems
                }
            }
        });
    }

    /**
     * Get product details, GA4 naming and
     *
     * @param {{items}[]}
     *
     * @return {{item_id: string, item_name: string, affiliation: string, coupon: string, discount: number, index: number, item_brand: string, item_variant: string, location_id: string, price: number, quantity: string}[]}
     */
    getCartItems(items, couponCode) {
        // const result = [];
        const result = items.map((item, i) => ({
            item_id: item.sku,
            item_name: item.product.name,
            affiliation: 'Rapelli store',
            coupon: couponCode,
            discount: item.discount_amount,
            index: i,
            item_brand: ProductHelper.getBrand(item.product),
            item_variant: ProductHelper.getWeightWithLabel(item.product),
            location_id: '',
            price: item.price,
            quantity: item.qty
        }));

        return result;
    }

    /**
     * Format Selected Planzer Date and Time into string
     *
     * @param shippingAddress
     * @return String
     */
    getPlanzerPaketSelectedShippingMethod(shippingAddress) {
        /*
            Example of data structure for relevant fields:
            "delivery_date": "2024-06-07T00:00:00.000Z",
            "delivery_time": "{\"to\":\"10:00:00\",\"from\":\"07:00:00\",\"surcharge\":\"15.00\"}",
        */
        const fromTo = JSON.parse(shippingAddress.delivery_time);
        return `Planzerpaket from ${fromTo.from} to ${fromTo.to} on ${shippingAddress.delivery_date}`;
    }
}

export default AddShippingInfoEvent;
