import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import { quoteRowGrid } from '../../type/B2bQuote';
import QuoteTableRow from '../QuoteTableRow';
import QuoteViewPopup from '../QuoteViewPopup';

import './QuoteGrid.style';

/** @namespace Screenpages/b2bquote/Component/QuoteGrid/Component/QuoteGridComponent */
export class QuoteGridComponent extends PureComponent {
    static propTypes = {
        QuoteList: quoteRowGrid.isRequired,
        isLoading: PropTypes.bool.isRequired,
        reloadQuotesGrid: PropTypes.func.isRequired,
        isB2bQuoteEnabled: PropTypes.bool.isRequired
    };

    renderLoadingGrid() {
        const { isLoading } = this.props;
        return (
            <>
                <table block="QuoteGrid" elem="Table">
                    <thead>
                        { this.renderQuoteHeadingRow() }
                    </thead>
                </table>
                <Loader isLoading={ isLoading } />
            </>
        );
    }

    renderNoResults() {
        return (
            <div block="QuoteGrid" elem="NoResults">
                { __('We could not find any quotes') }
            </div>
        );
    }

    renderResults() {
        return (
            <table block="QuoteGrid" elem="Table">
                <thead>
                    { this.renderQuoteHeadingRow() }
                </thead>
                <tbody>
                    { this.renderQuoteRows() }
                </tbody>
            </table>
        );
    }

    renderQuoteHeadingRow() {
        return (
            <tr>
                <th>{ __('Quote Name') }</th>
                <th block="hidden-mobile">{ __('Created By') }</th>
                <th>{ __('Status') }</th>
                <th block="hidden-mobile">{ __('Last Updated') }</th>
                <th>{ __('Quote Total') }</th>
            </tr>
        );
    }

    renderQuoteRows() {
        const { QuoteList } = this.props;
        if (!QuoteList.length) {
            return null;
        }

        return QuoteList.map(this.renderQuoteRow);
    }

    renderQuoteRow(quote) {
        const { quote_id } = quote;

        return (
            <QuoteTableRow
              key={ quote_id }
              QuoteRowData={ quote }
            />
        );
    }

    renderPopup() {
        const { reloadQuotesGrid } = this.props;
        return (
            <QuoteViewPopup
              reloadQuotesGrid={ reloadQuotesGrid }
            />
        );
    }

    renderCustomerNoAccess() {
        return (
            <div block="QuoteGrid" elem="NoAccess">
                <p>
                    { __('Your Acount does not have access to quotes') }
                </p>
                <p>
                    { __('Register as a company to gain access to quotes') }
                </p>
            </div>
        );
    }

    render() {
        const { isLoading, QuoteList, isB2bQuoteEnabled } = this.props;
        if (!QuoteList) {
            return null;
        }

        if (!isB2bQuoteEnabled) {
            return (
                <div block="QuoteGrid">
                    { this.renderCustomerNoAccess() }
                </div>
            );
        }

        if (isLoading) {
            return (
                <div block="QuoteGrid">
                    { this.renderLoadingGrid() }
                </div>
            );
        }

        if (QuoteList.length > 0) {
            return (
                <div block="QuoteGrid">
                    { this.renderResults() }
                    { this.renderPopup() }
                </div>
            );
        }

        return (
            <div block="QuoteGrid">
                { this.renderNoResults() }
            </div>
        );
    }
}

export default QuoteGridComponent;
