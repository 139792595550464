export const AUTH_CAN_MANAGE_QUOTES = 'quoterequesteditdelete';
export const AUTH_CAN_CHECKOUT_QUOTE = 'quotecancheckout';
export const AUTH_CAN_VIEW_QUOTE_SUBORDINATE = 'quoteviewsubordinate';
export const AUTH_CAN_EDIT_LEGAL_ADDRESS = 'companyeditlegaladdress';
export const AUTH_CAN_EDIT_COMPANY = 'companyeditprofile';
export const AUTH_CAN_EDIT_COMPANY_USERS = 'companyusermanageusers';
export const AUTH_CAN_EDIT_COMPANY_ROLES = 'companyusermanageroles';

export const COMPANY_OVERVIEW_URI = 'companyoverview';

export const B2B_DEBOUNCE_TIME = 3000;
