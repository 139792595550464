import PropTypes from 'prop-types';

export const ProductListRowType = PropTypes.shape({
    name: PropTypes.string,
    rapelli_material_number: PropTypes.string,
    short_description: PropTypes.string,
    consumer_unit: PropTypes.string,
    sku: PropTypes.string,
    gtin_tu: PropTypes.string,
    trade_unit: PropTypes.string,
    weight_variability: PropTypes.string,
    cashier_ean: PropTypes.string,
    normal_invoice_price: PropTypes.string,
    promo_invoice_price: PropTypes.string,
    price_per_quantity: PropTypes.string,
    price_per_unit: PropTypes.string,
    label_price: PropTypes.string,
    label_price_per_quantity: PropTypes.string,
    label_price_per_unit: PropTypes.string,
    line_rebate: PropTypes.string,
    invoice_rebate: PropTypes.string,
    customer_material_number: PropTypes.string,
    variable_weight: PropTypes.string,
    weight_variability_numerical: PropTypes.string,
    last_order_date: PropTypes.string
});

export const ProductListType = PropTypes.arrayOf(ProductListRowType);
