import PropTypes from 'prop-types';
// TODO Validate all props

export const b2bUserEditDropdownType = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    label: PropTypes.string
}));

export const b2bRoleFormPermissionsType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
        val: PropTypes.bool
    })),
    PropTypes.objectOf(PropTypes.shape({
        val: PropTypes.bool
    }))
]);
export const B2bCompanyAuthSingleType = PropTypes.shape({
    value: PropTypes.bool
});

export const b2bComapnyAuthType = PropTypes.arrayOf(B2bCompanyAuthSingleType);

export const CompanyContactType = PropTypes.arrayOf(PropTypes.shape({
    contact_title: PropTypes.string,
    contact_name: PropTypes.string,
    contact_email: PropTypes.string
}));

export const CompanyLegalAddressType = PropTypes.shape({
    street: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    city: PropTypes.string,
    postcode: PropTypes.string,
    region: PropTypes.string,
    region_id: PropTypes.string,
    country_code: PropTypes.string,
    telephone: PropTypes.string
});

export const b2bComapnyProfileDataType = PropTypes.shape({
    company_name: PropTypes.string,
    company_legal_name: PropTypes.string,
    company_email: PropTypes.string,
    vat_id: PropTypes.string,
    contacts: PropTypes.arrayOf(CompanyContactType),
    legal_address: CompanyLegalAddressType
});

export const b2bCompanySingleRoleType = PropTypes.shape({
    role_id: PropTypes.number,
    role_name: PropTypes.string
});

export const b2bCompanyRoleType = PropTypes.arrayOf(b2bCompanySingleRoleType);

export const b2bCompanySingleUserType = PropTypes.shape({
    entity_id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    job_title: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    role_name: PropTypes.string,
    role_id: PropTypes.number,
    is_owner: PropTypes.bool,
    status: PropTypes.string
});

export const b2bCompanyUserGridType = PropTypes.arrayOf(b2bCompanySingleUserType);

export const CompanyProfileType = PropTypes.shape({
    company_name: PropTypes.string,
    company_legal_name: PropTypes.string,
    company_email: PropTypes.string,
    company_contact_name: PropTypes.string,
    company_contact_email: PropTypes.string,
    company_salesrep_name: PropTypes.string,
    company_salesrep_email: PropTypes.string,
    vat_id: PropTypes.string,
    legal_address: CompanyLegalAddressType

});

export const CompanyType = PropTypes.oneOfType([CompanyProfileType, PropTypes.bool]);

export const CompanyUserGridUserType = PropTypes.shape({
    entity_id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role_name: PropTypes.string,
    status: PropTypes.string
});

// export const ComapnyUserGridType = PropTypes.arrayOf(CompanyUserGridUserType);

export const CompanyRoleGridEntryType = PropTypes.shape({
    role_id: PropTypes.number,
    role_name: PropTypes.string,
    permissions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.objectOf({ permission: PropTypes.bool })
    ])
});

// export const ComapnyRoleGridType = PropTypes.arrayOf(CompanyRoleGridEntryType);
