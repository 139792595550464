import {
    DISPLAY_MODE_BOTH as SOURCE_DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK as SOURCE_DISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_PRODUCTS as SOURCE_DISPLAY_MODE_PRODUCTS,
    GRID_LAYOUT as SOURCE_GRID_LAYOUT,
    LAYOUT_KEY as SOURCE_LAYOUT_KEY,
    LIST_LAYOUT as SOURCE_LIST_LAYOUT,
    LOADING_TIME as SOURCE_LOADING_TIME
} from 'SourceRoute/CategoryPage/CategoryPage.config';

// TODO: implement LOADING_TIME
export const LOADING_TIME = SOURCE_LOADING_TIME;

// TODO: implement DISPLAY_MODE_PRODUCTS
export const DISPLAY_MODE_PRODUCTS = SOURCE_DISPLAY_MODE_PRODUCTS;

// TODO: implement DISPLAY_MODE_CMS_BLOCK
export const DISPLAY_MODE_CMS_BLOCK = SOURCE_DISPLAY_MODE_CMS_BLOCK;

// TODO: implement DISPLAY_MODE_BOTH
export const DISPLAY_MODE_BOTH = SOURCE_DISPLAY_MODE_BOTH;

// TODO: implement LAYOUT_KEY
export const LAYOUT_KEY = SOURCE_LAYOUT_KEY;

// TODO: implement GRID_LAYOUT
export const GRID_LAYOUT = SOURCE_GRID_LAYOUT;

// TODO: implement LIST_LAYOUT
export const LIST_LAYOUT = SOURCE_LIST_LAYOUT;
