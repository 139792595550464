import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { debounce, fetchMutation } from 'Util/Request';

import B2bCompanyProfieQuery from '../../query/B2bCompanyProfie.query';
import { b2bCompanyRoleType, b2bCompanySingleUserType } from '../../type/B2bCompany';
import { B2B_DEBOUNCE_TIME } from '../CompanyOverview/CompanyOverview.config';
import CompanyUserEditForm from './CompanyUserEditForm.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    b2bCompanyRolesData: _state.B2bCompanyReducer.b2bCompanyRolesData
});

/** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showNotify: (type, msg) => _dispatch(showNotification(type, msg))
});

/** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/CompanyUserEditFormContainer */
export class CompanyUserEditFormContainer extends PureComponent {
    static propTypes = {
        b2bCompanyRolesData: b2bCompanyRoleType,
        showNotify: PropTypes.func.isRequired,
        handleCloseAndReload: PropTypes.func.isRequired,
        userData: b2bCompanySingleUserType
    };

    static defaultProps = {
        b2bCompanyRolesData: {},
        userData: {}
    };

    state = {
        isFormSubmitting: false
    };

    containerFunctions = {
        handleSubmit: this._handleSubmit.bind(this),
        handleSubmitSuccess: this._handlSubmitSuccess.bind(this),
        handleSubmitError: this._handleSubmitError.bind(this),
        handleDeleteUser: this._handleDeleteUser.bind(this)
    };

    _handleCheckEmail = debounce((email) => {
        const { showNotify } = this.props;
        // const { current: inputNode } = refMap || {};
        showNotify('warning', `Check Email ${ email}`);
    }, B2B_DEBOUNCE_TIME);

    containerProps = () => {
        const { isFormSubmitting } = this.state;
        const { userData, b2bCompanyRolesData } = this.props;
        const userRoles = b2bCompanyRolesData.map(({ role_id, role_name }) => ({
            id: role_id, label: role_name, value: role_id
        }));
        const userStatusFields = [];
        userStatusFields.push({ id: 1, label: (__('Active')), value: '1' });
        userStatusFields.push({ id: 2, label: (__('Disabled')), value: '0' });

        return {
            isFormSubmitting,
            userData,
            userRoles,
            userStatusFields
        };
    };

    _handleSubmit() {
        // this.setState({ isFormSubmitting: true });
    }

    _handlSubmitSuccess(fields) {
        const { handleCloseAndReload, showNotify } = this.props;
        const { userData: { entity_id } = 0 } = this.props;
        if (fields) {
            this.setState({ isFormSubmitting: true });
            fetchMutation(B2bCompanyProfieQuery.setUpdateCompanyUser(entity_id, fields)).then(
                /** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/fetchMutation/then */
                ({ b2bSaveCompanyCustomer: { status, message } }) => {
                    this.setState({ isFormSubmitting: false });
                    if (status) {
                        showNotify('success', __('User has been saved'));
                        handleCloseAndReload();
                        return;
                    }
                    showNotify('error', message);
                },
                /** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/fetchMutation/then */
                ([{ message }]) => {
                    this.setState({ isFormSubmitting: false });
                    showNotify('error', message);
                }
            );
        }
    }

    _handleSubmitError() {
        const { showNotify } = this.props;
        // TODO handle errors
        showNotify('error', 'we got an error');
        this.setState({ isFormSubmitting: false });
    }

    _handleDeleteUser() {
        const { handleCloseAndReload, showNotify, userData: { entity_id } = 0 } = this.props;
        if (entity_id > 0) {
            fetchMutation(B2bCompanyProfieQuery.setDeleteCompanyUser(entity_id)).then(
                /** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/fetchMutation/then */
                ({ b2bDeleteCompanyCustomer: { status, messsgae } }) => {
                    if (status) {
                        showNotify('success', __('The user has been deleted'));
                        handleCloseAndReload();
                        return;
                    }
                    showNotify('error', messsgae);
                },
                /** @namespace Screenpages/b2bcompany/Component/CompanyUserEditForm/Container/fetchMutation/then */
                ([{ message }]) => {
                    this.setState({ isFormSubmitting: false });
                    showNotify('error', message);
                }
            );
        }
    }

    render() {
        return (
            <CompanyUserEditForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserEditFormContainer);
