import * as FileSaver from 'file-saver';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { utils as XLSXUtils, write as xLSXWrite } from 'xlsx';

import { fetchQuery } from 'Util/Request';

import B2bPriceList from '../../query/B2bPriceList.query';
import { formatDate } from '../../util/formatDate';
import PriceList from './PriceList.component';

/** @namespace Screenpages/b2bpricelist/Component/PriceList/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Screenpages/b2bpricelist/Component/PriceList/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Screenpages/b2bpricelist/Component/PriceList/Container/PriceListContainer */
export class PriceListContainer extends PureComponent {
    state = {
        isLoading: false,
        isSuccess: false,
        error: '',
        userId: 0,
        product_list: []
    };

    containerFunctions = {
        downloadPriceList: this._downloadPriceList.bind(this)
    };

    componentDidMount() {
        this._loadProductListData();
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            error: '',
            userId: 0,
            sap_customer_id: '',
            product_list: []
        };
    }

    containerProps = () => ({
        ...this.state
    });

    _loadProductListData() {
        this.setState({ isLoading: true });
        fetchQuery(B2bPriceList.getPriceListData()).then(
            /** @namespace Screenpages/b2bpricelist/Component/PriceList/Container/fetchQuery/then */
            ({
                getProductListInfo: {
                    user_id, sap_customer_id, product_list
                }
            }) => {
                this.setState({
                    isSuccess: true,
                    isLoading: false,
                    userId: user_id,
                    sap_customer_id,
                    product_list
                });
            },
            /** @namespace Screenpages/b2bpricelist/Component/PriceList/Container/fetchQuery/then */
            ([{ message }]) => {
                this.setState({
                    error: message,
                    isLoading: false
                });
            }
        );
    }

    _downloadPriceList() {
        const { product_list } = this.state;
        const currentDate = new Date(Date.now()).toISOString();
        const fileName = `my-pricelist-${formatDate(currentDate)}`;
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        // Column header is defined by the order the of the fields in the query B2BPriceList.query
        const ws = XLSXUtils.json_to_sheet(product_list, { skipHeader: true });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = xLSXWrite(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    render() {
        return (
            <PriceList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceListContainer);
