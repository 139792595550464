import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';

/** @namespace Rapelli/Component/Form/Component/FormComponent */
export class FormComponent extends SourceForm {
    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields
        } = SourceForm.cloneAndValidateChildren(propsChildren, refMap);

        this.setState({ children, fieldsAreValid });

        const inputValues = Object.values(refMap).reduce((inputValues, input) => {
            const { current } = input;
            // Allow for company field to be deleted
            if (current && current.id === 'company') {
                const { name, value } = current;
                return { ...inputValues, [name]: value };
            }
            if (current && current.id && current.value) {
                const { name, value, checked } = current;
                if (current.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (current.type === 'checkbox') {
                    const boolValue = checked;

                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        if (invalidFields.length) {
            const { current } = refMap[invalidFields[0]];

            current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }

        return {
            inputValues,
            invalidFields
        };
    };
}

export default FormComponent;
