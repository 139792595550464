import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import Adyen from './AdyenCC.component';
import { CREDIT_CARD_TYPE } from './AdyenCC.config';

/** @namespace AdyenPayment/Component/AdyenCC/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace AdyenPayment/Component/AdyenCC/Container/AdyenCCContainer */
export class AdyenCCContainer extends PureComponent {
    static propTypes = {
        setPaymentMethodData: PropTypes.func
    };

    static defaultProps = {
        setPaymentMethodData: () => {}
    };

    fieldRef = createRef();

    componentDidMount() {
        const { adyen } = window;
        const { current } = this.fieldRef;

        adyen.create(CREDIT_CARD_TYPE, {
            openFirstPaymentMethod: false
        }).mount(current);
    }

    containerProps = () => ({
        fieldRef: this.fieldRef
    });

    render() {
        return (
            <Adyen
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps)(AdyenCCContainer);
