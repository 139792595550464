export const B2bCompanyDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/B2bQuote/B2bQuote.dispatcher'
);

export class HandleQuoteLogoutPlugin {
    addClearQuoteDispatchToProps(args, callback) {
        const [dispatch] = args;
        return {
            ...callback(...args),
            clearB2bQuotes: () => B2bCompanyDispatcher.then(
                ({ default: dispatcher }) => dispatcher.clearB2bQuotes(dispatch)
            )
        };
    }

    handleLogoutForQuote(args, callback, obj) {
        const { props } = obj;
        const { clearB2bQuotes } = props;
        clearB2bQuotes();
        return {
            ...callback(args)
        };
        // callback(...args);
        //
    }
}

const { addClearQuoteDispatchToProps, handleLogoutForQuote } = new HandleQuoteLogoutPlugin();

export default {
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: addClearQuoteDispatchToProps
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            handleLogout: handleLogoutForQuote
        }
    }
};
