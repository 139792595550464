import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Rapelli/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {
    // TODO implement logic
    renderOptions() {
        const {
            selectOptions,
            isSelectExpanded: isExpanded
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded } }
            >
            <div>
                { selectOptions.map(this.renderOption) }
            </div>
            </ul>
        );
    }
}

export default FieldSelectComponent;
