import { BILLING_STEP } from 'Route/Checkout/Checkout.config';

import { CHECKOUT_EVENT_DELAY } from '../../component/FacebookConversionApi/events/CheckoutInit.event';
import Event, {
    EVENT_FB_CHECKOUT
} from '../../component/FacebookConversionApi/util/Event';

export const FB_CART_PAGE_STEP = 1;
export const FB_SHIPPING_STEP = 2;
export const FB_BILLING_STEP = 3;

export const componentDidMount = (args, callback, instance) => {
    const { totals = {} } = instance.props;
    const { checkoutStep } = instance.state || {};

    setTimeout(
        () => Event.dispatch(EVENT_FB_CHECKOUT, {
            totals,
            step: checkoutStep ? FB_SHIPPING_STEP : FB_CART_PAGE_STEP
        }),
        CHECKOUT_EVENT_DELAY
    );

    return callback(...args);
};

export const componentDidUpdate = (args, callback, instance) => {
    const [prevState] = args;

    const { checkoutStep, isLoading } = instance.state;
    const { checkoutStep: prevCheckoutStep } = prevState;

    if (
        !isLoading
        && checkoutStep === BILLING_STEP
        && checkoutStep !== prevCheckoutStep
    ) {
        const { totals } = instance.props;
        Event.dispatch(EVENT_FB_CHECKOUT, {
            totals,
            step: FB_BILLING_STEP
        });
    }

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
