export const B2bCompanyDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/B2bCompany/B2bCompany.dispatcher'
);

export class HandleCompanyLogoutPlugin {
    addClearCompanyDispatchToProps(args, callback) {
        const [dispatch] = args;
        return {
            ...callback(...args),
            clearCompanyData: () => B2bCompanyDispatcher.then(
                ({ default: dispatcher }) => dispatcher.clearCompanyData(dispatch)
            )
        };
    }

    handleLogoutForCompany(args, callback, obj) {
        const { props } = obj;
        const { clearCompanyData } = props;
        clearCompanyData();
        return {
            ...callback(args)
        };
        // callback(...args);
        //
    }
}

const { addClearCompanyDispatchToProps, handleLogoutForCompany } = new HandleCompanyLogoutPlugin();

export default {
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: addClearCompanyDispatchToProps
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            handleLogout: handleLogoutForCompany
        }
    }
};
