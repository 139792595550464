import {
    calculateFinalPrice,
    formatCurrency,
    getLowestPriceTiersPrice,
    getPriceLabel,
    roundPrice
} from 'SourceUtil/Price/Price';

export {
    calculateFinalPrice,
    formatCurrency,
    getLowestPriceTiersPrice,
    getPriceLabel,
    roundPrice
};

/** @namespace Rapelli/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'CHF') => {
    const language = 'en-GB';

    return new Intl.NumberFormat(language, { style: 'currency', currency }).format(price);
};
