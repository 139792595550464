export const SET_B2B_QUOTE_LIST = 'SET_B2BQUOTE_LIST';
export const SET_B2B_SINGLE_QUOTE = 'SET_B2B_SINGLE_QUOTE';
export const CLEAR_B2B_SINGLE_QUOTE = 'CLEAR_B2B_SINGLE_QUOTE';
export const CLEAR_B2B_QUOTES = 'CLEAR_B2B_QUOTES';
export const B2BQUOTE_UPDATE_SHIPPING_PRICE = 'B2BQUOTE_UPDATE_SHIPPING_PRICE';

/** @namespace Screenpages/b2bquote/Store/B2bQuote/Action/setGridData */
export const setGridData = (b2bQuoteList, gridLoaded) => ({
    type: SET_B2B_QUOTE_LIST,
    b2bQuoteList,
    gridLoaded
});

/** @namespace Screenpages/b2bquote/Store/B2bQuote/Action/setSingleQuote */
export const setSingleQuote = (singleQuoteData) => ({
    type: SET_B2B_SINGLE_QUOTE,
    singleQuoteData
});
/** @namespace Screenpages/b2bquote/Store/B2bQuote/Action/clearSingleB2bQuote */
export const clearSingleB2bQuote = () => ({
    type: CLEAR_B2B_SINGLE_QUOTE
});

/** @namespace Screenpages/b2bquote/Store/B2bQuote/Action/clearB2bQuotes */
export const clearB2bQuotes = () => ({
    type: CLEAR_B2B_QUOTES
});
/** @namespace Screenpages/b2bquote/Store/B2bQuote/Action/updateShippingPrice */
export const updateShippingPrice = (data) => ({
    type: B2BQUOTE_UPDATE_SHIPPING_PRICE,
    data
});
