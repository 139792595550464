/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const addTokenQueryToCustomerFields = (args, callback) => ([
    ...callback(...args),
    'login_token',
]);

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: addTokenQueryToCustomerFields,
        },
    },
};
