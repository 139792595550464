/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    RECAPTCHA_HTTP_HEADER,
    RECAPTCHA_LOCATION,
    RECAPTCHA_TOKENS,
} from '../config/ReCaptcha.config';

export const appendReCaptchaTokenToHeaders = (args, callback) => {
    const tokens = JSON.parse(window.sessionStorage.getItem(RECAPTCHA_TOKENS)) || {};
    const currentLocation = window.sessionStorage.getItem(RECAPTCHA_LOCATION);

    return {
        ...callback(...args),
        [RECAPTCHA_HTTP_HEADER]: tokens[currentLocation],
    };
};

export default {
    'Util/Request/appendTokenToHeaders': {
        function: appendReCaptchaTokenToHeaders,
    },
};
