import {
    ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

/** @namespace Rapelli/Component/ProductConfigurableAttributes/Container/ProductConfigurableAttributesContainer */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    containerProps() {
        const {
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            variants
        } = this.props;

        return {
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            variants
        };
    }
}

export default ProductConfigurableAttributesContainer;
