import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import { formatPrice } from 'Util/Price';

import { QuoteRestrictionsType } from '../../type/B2bQuote';

import './QuoteViewActions.style';

/** @namespace Screenpages/b2bquote/Component/QuoteViewActions/Component/QuoteViewActionsComponent */
export class QuoteViewActionsComponent extends PureComponent {
    static propTypes = {
        handleCheckoutWithQuote: PropTypes.func.isRequired,
        handleCloseQuote: PropTypes.func.isRequired,
        handleDeleteQuote: PropTypes.func.isRequired,
        Restrictions: QuoteRestrictionsType.isRequired,
        CurrencyCode: PropTypes.string.isRequired,
        original_total_price: PropTypes.string.isRequired,
        subtotal: PropTypes.number.isRequired,
        grand_total: PropTypes.number.isRequired,
        shipping_incl_tax: PropTypes.number.isRequired,
        tax_amount: PropTypes.number.isRequired
    };

    renderTotals() {
        const {
            CurrencyCode,
            original_total_price,
            subtotal,
            grand_total,
            shipping_incl_tax,
            tax_amount
        } = this.props;

        return (
            <dl>
                <dt>{ __('Catalog Total Price (Exl Vat)') }</dt>
                <dd>{ formatPrice(original_total_price, CurrencyCode) }</dd>
                <dt>{ __('Quoted Total Price (Ex Vat)') }</dt>
                <dd>{ formatPrice(subtotal, CurrencyCode) }</dd>
                <dt>{ __('Estimated Tax') }</dt>
                <dd>{ formatPrice(tax_amount, CurrencyCode) }</dd>
                <dt>{ __('Shipping') }</dt>
                <dd>{ formatPrice(shipping_incl_tax, CurrencyCode) }</dd>
                <dt>{ __('Total to be charged') }</dt>
                <dd>{ formatPrice(grand_total, CurrencyCode) }</dd>
            </dl>
        );
    }

    renderProcedCheckout() {
        const { handleCheckoutWithQuote, Restrictions: { can_proceed_to_checkout } = {} } = this.props;

        if (can_proceed_to_checkout) {
            return (
                <div block="QuoteViewActions" elem="ProceedCheckoutWrapper">
                    <button
                      block="Button"
                      onClick={ handleCheckoutWithQuote }
                    >
                        { __('Proceed to Checkout') }
                    </button>
                </div>
            );
        }

        return null;
    }

    renderCloseQuoteBtn() {
        const { handleCloseQuote, Restrictions: { can_close } = {} } = this.props;
        if (can_close) {
            return (
                <button
                  block="Button"
                  onClick={ handleCloseQuote }
                >
                  { __('Close Quote') }
                </button>
            );
        }

        return null;
    }

    renderDeleteQuoteBtn() {
        const { handleDeleteQuote, Restrictions: { can_delete } = {} } = this.props;
        if (can_delete) {
            return (
                <button
                  block="Button"
                  onClick={ handleDeleteQuote }
                >
                    { __('Delete Quote') }
                </button>
            );
        }

        return null;
    }

    renderAdditinalActions() {
        const { Restrictions: { can_delete, can_close } = {} } = this.props;
        if (can_close || can_delete) {
            return (
                <ExpandableContent
                  key="quoteadditionalactions"
                  heading={ __('Additional Actions') }
                  mix={ { block: 'QuoteViewActions', elem: 'AdditionalActions' } }
                  isContendExpaned={ false }
                >
                    { this.renderCloseQuoteBtn() }
                    { this.renderDeleteQuoteBtn() }
                </ExpandableContent>
            );
        }

        return null;
    }

    render() {
        return (
            <div block="QuoteViewActions">
                <h3 block="QuoteViewActions" elem="Heading">
                    { __('Totals') }
                </h3>
                <div block="QuoteViewActions" elem="TotalsWrapper">
                    { this.renderTotals() }
                </div>
                { this.renderProcedCheckout() }
                { this.renderAdditinalActions() }
            </div>
        );
    }
}

export default QuoteViewActionsComponent;
