import { Field } from 'Util/Query';

/** @namespace Screenpages/FacebookConversionApi/Query/FacebookConversionApiConfig/Query/FacebookConversionApiConfigQuery */
export class FacebookConversionApiConfigQuery {
    _getConfig = () => ([
        'enabled',
        'debug_mode',
        this.getCredentialsField()
    ]);

    getCredentialsField = () => new Field('credentials').addFieldList(this.getCredentials());

    getCredentials = () => ([
        'access_token',
        'pixel_id'
    ]);

    getFacebookConversionApiConfig = () => new Field('getFacebookConversionApiConfig')
        .setAlias('fbconfig')
        .addFieldList(this._getConfig());
}

export default new FacebookConversionApiConfigQuery();
