import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

/** @namespace Rapelli/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    priceperunit: state.ConfigReducer.sp_price_tweaks_fe_pricepertext,
    gridProductIconsEnabled: state.ConfigReducer.grid_product_icons_enabled,
    gridProductIcons: state.ConfigReducer.grid_product_icons,
    grid_product_icons_groupa_limit: state.ConfigReducer.grid_product_icons_groupa_limit,
    sp_b2b_pricelist_graphql: state.ConfigReducer.sp_b2b_pricelist_graphql
});

/** @namespace Rapelli/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        priceperunit: PropTypes.string
    };

    containerProps() {
        const {
            product: { customer_trade_unit },
            priceperunit,
            gridProductIconsEnabled,
            sp_b2b_pricelist_graphql
        } = this.props;

        const unitMeasure = customer_trade_unit || priceperunit;
        return {
            ...super.containerProps(),
            unitMeasure,
            gridProductIcons: this.filterProductIcons(),
            gridProductIconsEnabled,
            sp_b2b_pricelist_graphql
        };
    }

    filterProductIcons() {
        const {
            gridProductIcons,
            grid_product_icons_groupa_limit,
            product: {
                attributes: { product_icons: { attribute_options: productIcons } = {} } = {}
            },
            product
        } = this.props;

        if (Object.keys(product).length === 0) {
            return gridProductIcons;
        }

        const productIconsKey = Object.keys(productIcons);

        if (!gridProductIcons) {
            return gridProductIcons;
        }

        if (!grid_product_icons_groupa_limit) {
            return gridProductIcons;
        }

        // eslint-disable-next-line fp/no-let
        let groupACnt = 1;
        const newGridProductIcons = gridProductIcons.filter((element) => {
            if (element.group === 'a') {
                const isPresent = productIconsKey.find((icon) => productIcons[icon].label === element.label) ? 1 : null;
                if (!isPresent) {
                    return false;
                }
                if (groupACnt > grid_product_icons_groupa_limit) {
                    return false;
                }
                groupACnt++;
            }

            return true;
        });

        return newGridProductIcons;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
