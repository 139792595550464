/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    MyAccountAddressTable as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.override.style';

/** @namespace Rapelli/Component/MyAccountAddressTable/Component/MyAccountAddressTableComponent */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
    // TODO implement logic
    get dataPairArray() {
        const { address, getFormatedRegion, showAdditionalFields } = this.props;
        const regionData = getFormatedRegion(address);

        const additionalFields = [
            // {
            //     key: 'city',
            //     label: __('City'),
            //     source: address
            // },
            {
                key: 'country',
                label: __('County'),
                source: regionData
            },
            {
                key: 'region',
                label: __('State/Province'),
                source: regionData
            }
            // Will be back with B2B update
            // {
            //     key: 'company',
            //     label: __('Company'),
            //     source: address
            // }
        ];

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: address
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address
            },
            {
                key: 'company',
                label: __('Company'),
                source: address
            },
            {
                key: 'street',
                label: __('Street'),
                source: address
            },
            {
                key: 'city',
                label: __('City'),
                source: address
            },
            {
                key: 'postcode',
                label: __('Postal code'),
                source: address
            },
            ...(showAdditionalFields ? additionalFields : []),
            {
                key: 'telephone',
                label: __('Phone number'),
                source: address
            }
        ];
    }
}

export default MyAccountAddressTableComponent;
