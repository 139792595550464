/* eslint-disable max-len */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import CustomerDataQuery from '../query/CustomerData.query';
import FacebookConversionApiConfigQuery from '../query/FacebookConversionApiConfig.query';

export const addFbConversionApiConfigQuery = (args, callback) => {
    const original = callback(...args);

    return [
        ...(Array.isArray(original) ? original : [original]),
        FacebookConversionApiConfigQuery.getFacebookConversionApiConfig(),
        CustomerDataQuery.getCustomerData()
    ];
};

export const addFbConversionApiToConfigReducerInitialState = (args, callback) => {
    const currentConfig = BrowserDatabase.getItem('config');
    const { fbconfig } = currentConfig || { fbconfig: {} };
    const { fbCustomerData } = currentConfig || { customerIpAddress: {} };

    return {
        ...callback(...args),
        fbconfig,
        fbCustomerData
    };
};

export const addFbConversionApiToConfigUpdate = (args, callback, context) => {
    const [action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { fbconfig, fbCustomerData } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        fbconfig,
        fbCustomerData
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addFbConversionApiConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addFbConversionApiToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        function: addFbConversionApiToConfigUpdate
    }
};
