import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { AdyenHPPComponent } from './AdyenHPP.component';

/** @namespace AdyenPayment/Component/AdyenHPP/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace AdyenPayment/Component/AdyenHPP/Container/AdyenHPPContainer */
export class AdyenHPPContainer extends PureComponent {
    static propTypes = {
        adyenMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
        setPaymentMethodData: PropTypes.func.isRequired,
        method: PropTypes.shape().isRequired
    };

    fieldRef = createRef();

    componentDidMount() {
        const { adyen } = window;
        const { current } = this.fieldRef;
        const { method: { type, configuration = {}, config: { configuration: conf = {} } = {} } = {} } = this.props;

        const component = adyen.create(type, {
            ...configuration, ...conf
        }).mount(current);

        // this hack is needed for klarna as it is valid from the mount moment and now events get fired
        if (component.isValid && type.includes('klarna')) {
            component.onChange(component.data, component);
        }
    }

    componentDidUpdate(prevProps, _) {
        const { method: { type: prevType } } = prevProps;
        const { method: { type } } = this.props;

        if (type !== prevType) {
            this.init();
        }
    }

    init() {
        const { adyen } = window;
        const { current } = this.fieldRef;
        const { method: { type, configuration = {} } } = this.props;

        const component = adyen.create(type, {
            ...configuration
        }).mount(current);

        // this hack is needed for klarna as it is valid from the mount moment and now events get fired
        window.document.getElementsByClassName('CheckoutBilling-Button')[0].setAttribute('disabled', true);
        if (component.isValid && type.includes('klarna')) {
            component.onChange(component.data, component);
        }
    }

    containerProps = () => ({
        fieldRef: this.fieldRef
    });

    render() {
        return (
            <AdyenHPPComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps)(AdyenHPPContainer);
