/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getStore } from 'Util/Store';

import {
    CHECKOUT,
    CUSTOMER_LOGIN,
    EDIT_CUSTOMER_ACCOUNT,
    FORGOT_PASSWORD,
    NEW_CUSTOMER_ACCOUNT,
    NEWSLETTER,
    PRODUCT_REVIEW
} from '../config/ReCaptcha.config';
import { getTypeForLocation, isCheckbox, setTokens } from '../util/ReCaptcha.util';

export const addReCaptchaCallback = ({ id: location }) => async (args, callback) => {
    try {
        const store = getStore();
        const { ConfigReducer: { googleReCaptcha: { locations } } } = store.getState();

        if (!locations.length) {
            callback(...args);

            return;
        }

        const currentType = getTypeForLocation(locations, location);
        const widgetId = window.sessionStorage.getItem(`${location}_sessionid`);

        if (widgetId) {
            if (isCheckbox(currentType)) {
                callback(...args);
                window.grecaptcha.reset(widgetId);

                return;
            }

            window.gRecaptchaCallback = (token) => {
                setTokens(token, location);

                callback(...args);
                window.grecaptcha.reset(widgetId);
            };

            await window.grecaptcha.execute(widgetId);
        } else {
            // continue with event
            callback(...args);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export default {
    'Component/MyAccountSignIn/Container': {
        'member-function': {
            onSignInSuccess: addReCaptchaCallback(CUSTOMER_LOGIN)
        }
    },
    'Route/PasswordChangePage/Container': {
        'member-function': {
            onPasswordSuccess: addReCaptchaCallback(FORGOT_PASSWORD)
        }
    },
    'Component/MyAccountForgotPassword/Container': {
        'member-function': {
            onForgotPasswordSuccess: addReCaptchaCallback(FORGOT_PASSWORD)
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onCreateAccountSuccess: addReCaptchaCallback(NEW_CUSTOMER_ACCOUNT)
        }
    },
    'Component/MyAccountInformation/Container': {
        'member-function': {
            onCustomerSave: addReCaptchaCallback(EDIT_CUSTOMER_ACCOUNT)
        }
    },
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit: addReCaptchaCallback(NEWSLETTER)
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addReCaptchaCallback(CHECKOUT)
        }
    },
    'Component/ProductReviewForm/Container': {
        'member-function': {
            _onReviewSubmitSuccess: addReCaptchaCallback(PRODUCT_REVIEW)
        }
    }
};
