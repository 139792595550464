import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { customerType } from 'Type/Account';

import { quoteRowGrid } from '../../type/B2bQuote';
import QuoteGrid from './QuoteGrid.component';

export const B2BDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/B2bQuote/B2bQuote.dispatcher'
);

/** @namespace Screenpages/b2bquote/Component/QuoteGrid/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    b2bQuoteList: _state.B2bQuoteReducer.b2bQuoteList,
    isGridLoading: _state.B2bQuoteReducer.isGridLoading,
    customer: _state.MyAccountReducer.customer
});

/** @namespace Screenpages/b2bquote/Component/QuoteGrid/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    getB2bQuoteList: () => B2BDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestB2bQuotesGrid(_dispatch)
    )
});

/** @namespace Screenpages/b2bquote/Component/QuoteGrid/Container/QuoteGridContainer */
export class QuoteGridContainer extends PureComponent {
    static propTypes = {
        getB2bQuoteList: PropTypes.func.isRequired,
        b2bQuoteList: quoteRowGrid.isRequired,
        isGridLoading: PropTypes.bool.isRequired,
        customer: customerType.isRequired
    };

    containerFunctions = {
        reloadQuotesGrid: this._getCustomersB2bQuotes.bind(this)
    };

    componentDidMount() {
        this._getCustomersB2bQuotes();
    }

    componentDidUpdate(prevProps) {
        const { customer: { isB2bQuoteAllowedView } = {} } = this.props;
        const { customer: { isB2bQuoteAllowedView: PrevB2bQuoteEnabled } = {} } = prevProps;
        if (PrevB2bQuoteEnabled !== isB2bQuoteAllowedView && isB2bQuoteAllowedView) {
            this._getCustomersB2bQuotes();
        }
    }

    containerProps = () => {
        const {
            b2bQuoteList, isGridLoading,
            customer: { isB2bQuoteAllowedView }
        } = this.props;

        return {
            QuoteList: b2bQuoteList,
            isLoading: isGridLoading,
            isB2bQuoteEnabled: !!isB2bQuoteAllowedView
        };
    };

    _getCustomersB2bQuotes() {
        const { getB2bQuoteList, customer: { isB2bQuoteAllowedView } = {} } = this.props;
        if (isB2bQuoteAllowedView) {
            getB2bQuoteList();
        }
    }

    render() {
        return (
            <QuoteGrid
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteGridContainer);
