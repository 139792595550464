import {
    ContentWrapper as SourceContentWrapper
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

/** @namespace Rapelli/Component/ContentWrapper/Component/ContentWrapperComponent */
export class ContentWrapperComponent extends SourceContentWrapper {
    // TODO implement logic
}

export default ContentWrapperComponent;
