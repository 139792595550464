import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { b2bCompanyUserGridType } from '../../type/B2bCompany';
import CompanyUserManagement from './CompanyUserManagement.component';

/** @namespace Screenpages/b2bcompany/Component/CompanyUserManagement/Container/CompanyUserManagementContainer */
export class CompanyUserManagementContainer extends PureComponent {
    static propTypes = {
        CompanyUsers: b2bCompanyUserGridType,
        showEditCompanyUserFormPopup: PropTypes.func.isRequired,
        isAllowedToEdit: PropTypes.bool
    };

    static defaultProps = {
        isAllowedToEdit: false,
        CompanyUsers: []
    };

    state = {
        showInactiveUsers: false
    };

    containerFunctions = {
        switchShowInactiveUsers: this._switchShowInactiveUsers.bind(this)
    };

    containerProps = () => {
        const { showInactiveUsers } = this.state;
        const { isAllowedToEdit, showEditCompanyUserFormPopup } = this.props;
        return {
            isAllowedToEdit,
            showEditCompanyUserFormPopup,
            isShowInactiveUsers: showInactiveUsers,
            CompanyUsers: this._getCompanyUsers()
        };
    };

    _switchShowInactiveUsers() {
        const { showInactiveUsers } = this.state;
        this.setState({
            showInactiveUsers: !showInactiveUsers
        });
    }

    _getCompanyUsers() {
        const { CompanyUsers } = this.props;
        const { showInactiveUsers } = this.state;
        return CompanyUsers.filter((user) => {
            const { status } = user;
            if (showInactiveUsers && status === '0') {
                return true;
            }
            if (!showInactiveUsers && status === '1') {
                return true;
            }

            return false;
        });
    }

    render() {
        return (
            <CompanyUserManagement
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default CompanyUserManagementContainer;
